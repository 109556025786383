import React, { useState,useEffect } from 'react'
import firebase from '../../../firebase'
import { Link } from 'react-router-dom'
import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'

const Item = (props) => {
    const {data} = props
    return (
        <tr>
            <td>{data.externalId}</td>
            <td>{data.customerName}</td>
            <td>{moment(data.date).format('DD-MM-YYYY')}</td>
            <td>{numeral(data.total).format('$0,0.00')}</td>
            <td><select value={data.status} onChange={e => firebase.update('orders',data.id,'status',e.target.value)}>
                <option value="1">Pendiente</option>
                <option value="2">Prosesada</option>
                <option value="3">Enviada</option>
                <option value="4">Entregada</option>
                <option value="0">Cancelada</option>
            </select></td>
            <td><Link to={`/ventas/pedidos/${data.id}`}><i className="material-icons">chevron_right</i></Link></td>
        </tr>
    )
}

const SalesOrdersContainer = (props) => {

    const [orders,setOrders] = useState([])

    useEffect(() => {
        const initialMonth = moment(props.month,'MM-YYYY').startOf('month').valueOf()
        const endMonth = moment(props.month,'MM-YYYY').endOf('month').valueOf()
        if (initialMonth && endMonth) {
            firebase.getCollectionRealTime('orders').where('date','<=',endMonth).where('date','>=',initialMonth).onSnapshot(snap => {
                const orders = snap.docs.map(doc => doc.data())
                setOrders(_.orderBy(orders, ['date'],['asc']))
            })
        }
    }, [props.month])

    return (
        <tbody>{
            Object.keys(orders).map(key => <Item key={key} data={orders[key]} />)
        }</tbody>
    )

}

const SalesOrders = () => {

    const [month,setMonth] = useState(undefined)

    useEffect(() => {
        const current = moment().format('MM-YYYY')
        setMonth(current)
    }, [])

    return (
        <div className="sales-orders">
            <table>
                <thead>
                    <tr>
                        <th colSpan="6">
                            <div className="date-picker">
                                <button onClick={prevMonth}>
                                    <i className="material-icons">arrow_left</i>
                                </button>
                                <span>{month}</span>
                                <button onClick={nextMonth}>
                                    <i className="material-icons">arrow_right</i>
                                </button>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th>ID</th>
                        <th>Cliente</th>
                        <th>Fecha</th>
                        <th>Total</th>
                        <th>Estatus</th>
                        <th>Ver</th>
                    </tr>
                </thead>
                <SalesOrdersContainer month={month} />
            </table>
        </div>
    )

    async function nextMonth() {
        try {
            const newMonth = moment(month, 'MM-YYYY').add(1,'month').format('MM-YYYY')
            setMonth(newMonth)
        } catch (error) {
            alert(error.message)
        }
    }
    async function prevMonth() {
        try {
            const newMonth = moment(month, 'MM-YYYY').subtract(1,'month').format('MM-YYYY')
            setMonth(newMonth)
        } catch (error) {
            alert(error.message)
        }
    }
}

export default SalesOrders