import React from 'react'
import { Link, Route, Switch } from 'react-router-dom';

import SalesCustomers from './sales-customers/SalesCustomers';
import SalesCustomersForm from './sales-customers-form/SalesCustomersForm';
import SalesSingleCustomer from './sales-single-customer/SalesSingleCustomer';
import SalesPriceList from './sales-price-list/SalesPriceList';
import SalesMemo from './sales-memo/SalesMemo';
import SalesOrders from './sales-orders/SalesOrders';
import SalesOrdersForm from './sales-orders-form/SalesOrdersForm';
import SalesInvoicing from './sales-invoicing/SalesInvoicing';
import SalesInvoicingForm from './sales-invoicing-form/SalesInvoicingForm';
import Warehouse from '../warehouse/Warehouse';
import SalesSingleOrder from './sales-single-order/SalesSingleOrder';

const Sales = (props) => {
    const loc = props.location.pathname.split('/')[2];
    return (
        <div className="sales">
            <div className="in-nav">
                <ul>
                    <Link className={loc === undefined || loc === 'clientes' ? 'active' : ''} to="/ventas">Clientes</Link>
                    <Link className={loc === 'agregar-cliente' ? 'active' : ''} to="/ventas/agregar-cliente">Agregar Cliente</Link>
                    <Link className={loc === 'minuta' ? 'active' : ''} to="/ventas/minuta">Minuta</Link>
                    <Link className={loc === 'pedidos' ? 'active' : ''} to="/ventas/pedidos">Pedidos</Link>
                    <Link className={loc === 'agregar-pedido' ? 'active' : ''} to="/ventas/agregar-pedido">Agregar Pedido</Link>
                    <Link className={loc === 'facturacion' ? 'active' : ''} to="/ventas/facturacion">Facturación</Link>
                    <Link className={loc === 'agregar-factura' ? 'active' : ''} to="/ventas/agregar-factura">Agregar Factura</Link>
                    <Link className={loc === 'terminado' ? 'active' : ''} to="/ventas/terminado">Producto Terminado</Link>
                </ul>
            </div>
            <Switch>
                <Route path="/ventas" 
                    render={() => 
                        <SalesCustomers
                            userId={props.userId}
                            isSeller={props.isSeller}
                        />
                    } 
                    exact={true}
                />
                <Route path="/ventas/clientes/:id" component={SalesSingleCustomer} exact={true} />
                <Route path="/ventas/clientes/:customerId/:priceListId" component={SalesPriceList} exact={true} />
                <Route path="/ventas/agregar-cliente" render={props => <SalesCustomersForm {...props} userId={props.userId} />} exact={true} />
                <Route path="/ventas/minuta" component={SalesMemo} exact={true} />
                <Route path="/ventas/pedidos" component={SalesOrders} exact={true} />
                <Route path="/ventas/pedidos/:id" component={SalesSingleOrder} exact={true} />
                <Route path="/ventas/agregar-pedido" component={SalesOrdersForm} exact={true} />
                <Route path="/ventas/facturacion" component={SalesInvoicing} exact={true} />
                <Route path="/ventas/agregar-factura" component={SalesInvoicingForm} exact={true} />
                <Route path="/ventas/terminado" component={Warehouse} exact={true} />
            </Switch>
        </div>
    )
}

export default Sales