import React from 'react'
import BigCalendar from 'react-big-calendar'
import moment from 'moment'

import 'react-big-calendar/lib/css/react-big-calendar.css'

const localizer = BigCalendar.momentLocalizer(moment)

const MyCalendar = (props) => (
    <div style={{ height:'70vh',marginTop:'2.5rem' }}>
        <BigCalendar
            localizer={localizer}
            events={props.list}
            startAccessor="start"
            endAccessor="end"
        />
    </div>
)

const Calendar = () => {
    return (
        <div className="calendar">
            <form>
                <div className="input-container">
                    <label>Título de Evento</label>
                    <input placeholder="Título de Evento" type="text"/>
                </div>
                <div className="input-container">
                    <label>Descripción de Evento</label>
                    <input placeholder="Descripción de Evento" type="text"/>
                </div>
                <div className="input-container">
                    <label>Inicio de Evento</label>
                    <input placeholder="Inicio de Evento" type="date"/>
                </div>
                <div className="input-container">
                    <label>Fin de Evento</label>
                    <input placeholder="Fin de Evento" type="date"/>
                </div>
                <div className="input-container">
                    <button type="submit">Agregar</button>
                </div>
            </form>
            <MyCalendar list={[]} />
        </div>
    )
}

export default Calendar