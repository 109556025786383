import React from 'react'
import { Link, Route, Switch } from 'react-router-dom';
import AccountingPurchases from './accounting-purchases/AccountingPurchases';
import AccountingIncomes from './accounting-incomes/AccountingIncomes';
import AccountingIncomesForm from './accounting-incomes-form/AccountingIncomesForm';
import AccountingDebts from './accounting-debts/AccountingDebts';
import AccountingReceivable from './accounting-receivable/AccountingReceivable';
import SalesInvoicing from '../sales/sales-invoicing/SalesInvoicing';
import SalesInvoicingForm from '../sales/sales-invoicing-form/SalesInvoicingForm';
import AccountingBalance from './accounting-balance/AccountingBalance';
import AccountingResults from './accounting-results/AccountingResults';
import AccountingExpenses from './accounting-expenses/AccountingExpenses';

const Accounting = (props) => {
    const loc = props.location.pathname.split('/')[2];
    return (
        <div className="accounting">
            <div className="in-nav">
                <ul>
                    <Link className={loc === undefined ? 'active' : 0} to="/contabilidad">Compras</Link>
                    <Link className={loc === 'ingresos' ? 'active' : 0} to="/contabilidad/ingresos">Ingresos</Link>
                    <Link className={loc === 'agregar-ingreso' ? 'active' : 0} to="/contabilidad/agregar-ingreso">Agregar Ingreso</Link>
                    <Link className={loc === 'egresos' ? 'active' : 0} to="/contabilidad/egresos">Egresos</Link>
                    <Link className={loc === 'cuentas-pagar' ? 'active' : 0} to="/contabilidad/cuentas-pagar">Cuentas por Pagar</Link>
                    <Link className={loc === 'cuentas-cobrar' ? 'active' : 0} to="/contabilidad/cuentas-cobrar">Cuentas Por Cobrar</Link>
                    <Link className={loc === 'facturacion' ? 'active' : 0} to="/contabilidad/facturacion">Facturacion</Link>
                    <Link className={loc === 'agregar-factura' ? 'active' : 0} to="/contabilidad/agregar-factura">Agregar Factura</Link>
                    <Link className={loc === 'estado-cuenta' ? 'active' : 0} to="/contabilidad/estado-cuenta">Estado de Cuenta</Link>
                    <Link className={loc === 'estado-resultados' ? 'active' : 0} to="/contabilidad/estado-resultados">Estado de Resultados</Link>
                </ul>
            </div>
            <Switch>
                <Route path="/contabilidad" component={AccountingPurchases} exact={true} />
                <Route path="/contabilidad/ingresos" component={AccountingIncomes} exact={true} />
                <Route path="/contabilidad/egresos" component={AccountingExpenses} exact={true} />
                <Route path="/contabilidad/agregar-ingreso" component={AccountingIncomesForm} exact={true} />
                <Route path="/contabilidad/cuentas-pagar" component={AccountingDebts} exact={true} />
                <Route path="/contabilidad/cuentas-cobrar" component={AccountingReceivable} exact={true} />
                <Route path="/contabilidad/facturacion" component={SalesInvoicing} exact={true} />
                <Route path="/contabilidad/agregar-factura" component={SalesInvoicingForm} exact={true} />
                <Route path="/contabilidad/estado-cuenta" component={AccountingBalance} exact={true} />
                <Route path="/contabilidad/estado-resultados" component={AccountingResults} exact={true} />
            </Switch>
        </div>
    )
}

export default Accounting