import React, { useEffect,useState } from 'react'
import firebase from '../../../firebase'
import numeral from 'numeral'
import moment from 'moment'

const Item = (props) => {
    const {data} = props

    return (
        <tr>
            <td>{data.materialName} {data.name}</td>
            <td>{numeral(data.price).format('$0,0.00')}</td>
            <td>{data.quantity}</td>
            <td>{data.iva}</td>
            <td>{numeral(data.total).format('$0,0.00')}</td>
        </tr>
    )
}


const PurchasesSinglePurchase = (props) => {
    
    const [quote,setquote] = useState([])

    useEffect(() => {
        firebase.getDocumentRealTime('purchases',props.match.params.id).onSnapshot(snap => {
            setquote(snap.data())
        })
    })

    return (
        <div className="purchases-single-quote">
            <table>
                <tbody>
                    <tr>
                        <th>Marca: </th>
                        <td>{quote.brandName}</td>
                    </tr>
                    <tr>
                        <th>Proveedor: </th>
                        <td>{quote.providerName}</td>
                    </tr>
                    <tr>
                        <th>Fecha: </th>
                        <td>{moment(quote.date).format('DD-MM-YYYY')}</td>
                    </tr>
                    <tr>
                        <th>Comentarios: </th>
                        <td>{quote.comments}</td>
                    </tr>
                    <tr>
                        <th>Total: </th>
                        <td>{numeral(quote.total).format('$0,0.00')}</td>
                    </tr>
                    <tr>
                        <th>Fecha de Pago: </th>
                        <td><input value={moment(quote.payDate).format('YYYY-MM-DD')} onChange={e => firebase.update('purchases',props.match.params.id,'payDate',moment(e.target.value,'YYYY-MM-DD').valueOf())} type="date"/></td>
                    </tr>
                    <tr>
                        <th>Estatus de Pago</th>
                        <td>
                            <select onChange={e => firebase.update('purchases',props.match.params.id,'paymentStatus',e.target.value)} value={quote.paymentStatus}>
                                <option value="pending">Pendiente</option>
                                <option value="accepted">Aceptado por área contable</option>
                                <option value="parcial">Pago parcial</option>
                                <option value="complete">Pagado</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th>Estatus de Proceso</th>
                        <td>
                            <select onChange={e => firebase.update('purchases',props.match.params.id,'processStatus',e.target.value)} value={quote.processStatus}>
                                <option value="pending">Pendiente</option>
                                <option value="sent">Pedido enviado</option>
                                <option value="confirmed">Confirmado por proveedor</option>
                                <option value="complete">Ingresado a inventario</option>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table>
                <thead>
                    <tr>
                        <th>Producto</th>
                        <th>Precio Unitario</th>
                        <th>Cantidad</th>
                        <th>Iva</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>{quote.materials ? 
                    Object.keys(quote.materials).map(key => <Item key={key} data={quote.materials[key]} />) : <tr></tr>
                }</tbody>
            </table>
        </div>
    )
}

export default PurchasesSinglePurchase