import React, {useState,useEffect} from 'react'
import firebase from '../../../firebase'
import Select from 'react-select'
import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'

const Item = (props) => {
    const {data} = props

    const newReceivable = data.invoiceAmount - (data.payment + data.covered ? data.covered : 0)

    return (
        <tr>
            <td>{data.invoice}</td>
            <td>{moment(data.date).format('DD-MM-YYYY')}</td>
            <td>{numeral(data.invoiceAmount).format('$0,0.00')}</td>
            <td>{numeral(data.payment).format('$0,0.00')}</td>
            <td><input onChange={e => props.update(props.index,'covered',Number(e.target.value))} type="number"/></td>

            <td>{numeral(newReceivable).format('$0,0.00')}</td>
            <td><select value={data.status} onChange={e => props.update(props.index,'status',e.target.value)}>
                <option value="Pendiente">Pendiente</option>
                <option value="Aclaración">Aclaración</option>
                <option value="Pagado">Pagado</option>
            </select></td>
        </tr>
    )
}

const AccountingIncomesForm = (props) => {

    const [customers,setcustomers] = useState([])
    const [brands,setbrands] = useState([])

    const [customer,setCustomer] = useState(undefined)
    const [brand,setBrand] = useState(undefined)

    const [amount,setamount] = useState('')
    const [payway,setpayway] = useState('false')
    const [reference,setreference] = useState('')
    const [date,setdate] = useState('')
    const [comments,setcomments] = useState('')

    const [billing,setBilling] = useState([])

    useEffect(() => {
        firebase.getCollectionOnce('customers').then(snap => {
            var data = []
            snap.docs.forEach(doc => {
                const item = doc.data()
                data.push({ label: item.name, value: item.id })
            })
            setcustomers(data)
        })
        firebase.getCollectionOnce('brands').then(snap => {
            var data = []
            snap.docs.forEach(doc => {
                const item = doc.data()
                data.push({ label: item.name, value: item.id })
            })
            setbrands(data)
        })
    },[])
    useEffect(() => {
        if (customer) {
            firebase.getCollectionRealTime('billing').orderBy('date').where('customerId','==',customer.value).get().then(snap => {
                const billing = _.orderBy(snap.docs.map(doc => doc.data()), ['invoice'],['asc'])
                setBilling(_.filter(billing, o => {
                    return o.status !== 'Pagado' && o.status !== 'Cancelado'
                }))
            })
        }
    }, [customer])

    const total = _.reduce(billing,(sum,n) => {
        return sum + (n.invoiceAmount - n.payment)
    },0)

    return (
        <div className="accounting-incomes-form">
            <div className="container">
                <div className="details">
                    <div className="input-container">
                        <label>Cliente</label>
                        <Select
                            options={customers}
                            value={customer}
                            onChange={setCustomer}
                        />
                    </div>
                    <div className="input-container">
                        <label>Marca</label>
                        <Select
                            options={brands}
                            value={brand}
                            onChange={setBrand}
                        />
                    </div>
                    <div className="input-container">
                        <label>Monto</label>
                        <input value={amount} onChange={e => setamount(e.target.value)} placeholder="Monto" type="number"/>
                    </div>
                    <div className="input-container">
                        <label>Forma de pago</label>
                        <select value={payway} onChange={e => setpayway(e.target.value)}>
                            <option value="false">** Seleccionar opción **</option>
                            <option value="Efectivo">Efectivo</option>
                            <option value="Transferencia">Transferencia</option>
                            <option value="Cheque">Cheque</option>
                            <option value="Tarjeta">Tarjeta</option>
                            <option value="Otro">Otro</option>
                        </select>
                    </div>
                    <div className="input-container">
                        <label>Referencia</label>
                        <input value={reference} onChange={e => setreference(e.target.value)} placeholder="Referencia" type="text"/>
                    </div>
                    <div className="input-container">
                        <label>Fecha de Pago</label>
                        <input value={moment(date).format('YYYY-MM-DD')} onChange={e => setdate(moment(e.target.value,'YYYY-MM-DD').valueOf())} type="date"/>
                    </div>
                    <div className="input-container">
                        <label>Comentarios</label>
                        <input value={comments} onChange={e => setcomments(e.target.value)} placeholder="Comentarios" type="text"/>
                    </div>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th colSpan="7">Facturas pendientes de pago: {numeral(total).format('$0,0.00')}</th>
                        </tr>
                        <tr>
                            <th>Folio</th>
                            <th>Fecha</th>
                            <th>Monto de Factura</th>
                            <th>Cobrado</th>
                            <th>Abono</th>
                            <th>Por Cobrar</th>
                            <th>Estatus</th>
                        </tr>
                    </thead>
                    <tbody>{
                        Object.keys(billing).map(key => <Item update={update} key={key} index={key} data={billing[key]} />)
                    }</tbody>
                </table>
            </div>
            <div className="input-container">
                <button onClick={add} type="submit">Agregar</button>
            </div>
        </div>
    )

    async function update (index,label,value) {
        var newBilling = [...billing]
        newBilling[index][label] = value
        newBilling[index].haveChange = true
        setBilling(newBilling)
    }

    async function add () {
        try {
            const concept = _.reduce(billing, (str,n) => {
                if (n.haveChange === true) {
                    return `${str}${n.invoice}, `
                } else {
                    return str
                }
            },'')
            const item = {
                amount: Number(amount),
                brandId: brand.value,
                brandName: brand.label,
                comments,
                concept: `Aplicado a facturas ${concept}`,
                customerId: customer.value,
                customerName: customer.label,
                holderId: customer.value,
                holderName: customer.label,
                date,
                payway,
                reference,
            }
            firebase.simpleAdd(item,'incomes').then(() => {
                billing.forEach(bill => {
                    if (bill.haveChange === true) {
                        firebase.update('billing',bill.id,'status',bill.status)
                        firebase.update('billing',bill.id,'receibable',bill.invoiceAmount - (bill.payment + bill.covered ? bill.covered : 0))
                        firebase.update('billing',bill.id,'payment',bill.payment+(bill.covered ? bill.covered : 0))
                    }
                })
                alert('Pago agregado')
                props.history.push('/contabilidad/agregar-ingreso')
            })
        } catch (error) {
            alert(error.message)
        }
    }
}

export default AccountingIncomesForm