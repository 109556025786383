import React, {useState,useEffect} from 'react'
import firebase from '../../../firebase'
import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'

const Item = (props) => {
    const {data} = props

    return (
        <tr>
            <td>{moment(data.date).format('DD-MM-YYYY')}</td>
            <td><select value={data.payway} onChange={e => firebase.update('expenses',data.id,'payway',e.target.value)}>
                <option value="Transferencia">Transferencia</option>
                <option value="Efectivo">Efectivo</option>
                <option value="Cheque">Cheque</option>
                <option value="Otro">Otro</option>
            </select></td>
            <td><select onChange={e => firebase.update('expenses',data.id,'brandName',e.target.value)} value={data.brandName}>
                {
                    Object.keys(props.brands).map(key => <option key={key} value={props.brands[key].name}>{props.brands[key].name}</option>)
                }
            </select></td>
            <td><select onChange={e => firebase.update('expenses',data.id,'category',e.target.value)} value={data.category}>
                <option value="false">**  Seleccionar **</option>
                {Object.keys(props.categories).map(key => <option key={key} value={props.categories[key].name}>{props.categories[key].name}</option>)}
            </select></td>
            <td>{data.holderName}</td>
            <td>{data.concept}</td>
            <td>{numeral(data.amount).format('$0,0.00')}</td>
        </tr>
    )
}

const AccountingExpensesContainer = (props) => {
    
    const [moves,setmoves] = useState([])
    const [brands,setBrands] = useState([])
    const [categories,setCategories] = useState([])

    useEffect(() => {
        firebase.getCollectionOnce('brands').then(snap => {
            const brands = snap.docs.map(doc => doc.data())
            setBrands(brands)
        })
        firebase.getCollectionOnce('categories').then(snap => {
            const categories = snap.docs.map(doc => doc.data())
            setCategories(categories)
        })
        const initialMonth = moment(props.month).startOf('month').valueOf()
        const endMonth = moment(props.month).endOf('month').valueOf()

        if (initialMonth && endMonth) {
            const getExpenses = new Promise((resolve) => {
                firebase.getCollectionRealTime('expenses').where('date','<=',endMonth).where('date','>=',initialMonth).onSnapshot(snap => {
                    var data = []
                    snap.docs.forEach(doc => {
                        var item = doc.data()
                        item.type = 'Egreso'
                        data.push(item)
                    })
                    resolve(data)
                })
            })
            getExpenses.then(expenses => {
                setmoves(_.orderBy(expenses, ['date'],['asc']))
            })
        }
    }, [props.month])

    const seeker = props.seeker ? _.filter(moves, item => {
        return _.lowerCase(item.payway + ' ' + item.brandName + ' ' + item.holderName + ' ' + item.concept + ' ' + item.amount + ' ' + item.category).match(_.lowerCase(props.seeker))
    }) : moves

    const totalExpenses = _.reduce(seeker, (sum,n) => {
        if (n.type === 'Egreso') {
            return sum + n.amount
        }
        return sum
    },0)

    return (
        <tbody>
            {
                Object.keys(seeker).map(key => <Item categories={categories} brands={brands} key={key} data={seeker[key]} />)
            }
            <tr>
                <th colSpan="5"></th>
                <th>Total</th>
                <th>{numeral(totalExpenses).format('$0,0.00')}</th>
            </tr>
        </tbody>
    )

}

const AccountingExpenses = () => {

    const [month,setMonth] = useState(undefined)
    const [seeker,setSekker] = useState('')

    useEffect(() => {
        const current = moment().format('MM-YYYY')
        setMonth(current)
    }, [])

    return (
        <div className="accounting-incomes">
            <div className="seeker">
                <span><i className="material-icons">search</i></span>
                <input placeholder="Buscar ..." value={seeker} onChange={e => setSekker(e.target.value)} type="text"/>
            </div>
            <table>
                <thead>
                    <tr>
                        <th colSpan="7">
                            <div className="date-picker">
                                <button onClick={prevMonth}>
                                    <i className="material-icons">arrow_left</i>
                                </button>
                                <span>{month}</span>
                                <button onClick={nextMonth}>
                                    <i className="material-icons">arrow_right</i>
                                </button>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th>Fecha</th>
                        <th>Forma de Pago</th>
                        <th>Marca</th>
                        <th>Categoría</th>
                        <th>Proveedor</th>
                        <th>Concepto</th>
                        <th>Monto</th>
                    </tr>
                </thead>
                <AccountingExpensesContainer seeker={seeker} month={moment(month,'MM-YYYY').valueOf()} />
            </table>
        </div>
    )

    async function nextMonth() {
        try {
            const newMonth = moment(month, 'MM-YYYY').add(1,'month').format('MM-YYYY')
            setMonth(newMonth)
        } catch (error) {
            alert(error.message)
        }
    }
    async function prevMonth() {
        try {
            const newMonth = moment(month, 'MM-YYYY').subtract(1,'month').format('MM-YYYY')
            setMonth(newMonth)
        } catch (error) {
            alert(error.message)
        }
    }
}

export default AccountingExpenses