import React from 'react'
import { Link, Route, Switch } from 'react-router-dom'

import ConfigurationCfdis from './configuration-cfdis/ConfigurationCfdis';
import ConfigurationSatPayway from './configuration-sat-payway/ConfigurationSatPayway';
import ConfigurationBrand from './configuration-brands/ConfigurationBrands';
import ConfigurationUsers from './configuration-users/ConfigurationUsers';
import ConfigurationProducts from './configuration-products/ConfigurationProducts';
import ConfigurationSingleProduct from './configuration-single-product/ConfigurationSingleProduct';
import ConfigurationExpenses from './configuration-expenses/ConfigurationExpenses';
import ConfigurationPasswords from './configuration-passwords/ConfigurationPasswords';
import ConfigurationReceipes from './configuration-receipes/ConfigurationReceipes';
import ConfigurationReceipesForm from './configuration-receipes/ConfigurationReceipesForm';
import ConfigurationSingleUser from './configuration-single-user/ConfigurationSingleUser';

const Configuration = (props) => {
    const loc = props.location.pathname.split('/')[2];
    return (
        <div className="configuration">
            <div className="in-nav">
                <ul>
                    <Link className={loc === undefined || loc === 'usuarios' ? 'active' : 0}  to="/configuracion">Usuarios</Link>
                    <Link className={loc === 'marcas' ? 'active' : 0}  to="/configuracion/marcas">Marcas</Link>
                    <Link className={loc === 'productos' ? 'active' : 0}  to="/configuracion/productos">Productos</Link>
                    <Link className={loc === 'recetas' ? 'active' : 0}  to="/configuracion/recetas">Recetas</Link>
                    <Link className={loc === 'agregar-receta' ? 'active' : 0}  to="/configuracion/agregar-receta">Agregar Receta</Link>                    <Link className={loc === 'categorias-egresos' ? 'active' : 0}  to="/configuracion/categorias-egresos">Categorías de Egresos</Link>
                    <Link className={loc === 'cfdis' ? 'active' : 0}  to="/configuracion/cfdis">CFDI'S</Link>
                    <Link className={loc === 'contraseñas' ? 'active' : 0}  to="/configuracion/contraseñas">Contraseñas</Link>
                    <Link className={loc === 'formas-pago-sat' ? 'active' : 0}  to="/configuracion/formas-pago-sat">Formas de Pago SAT</Link>
                </ul>
            </div>
            <Switch>
                <Route path="/configuracion" component={ConfigurationUsers} exact={true} />
                <Route path="/configuracion/usuarios/:id" component={ConfigurationSingleUser} exact={true} />
                <Route path="/configuracion/marcas" component={ConfigurationBrand} exact={true} />
                <Route path="/configuracion/productos" component={ConfigurationProducts} exact={true} />
                <Route path="/configuracion/productos/:id" component={ConfigurationSingleProduct} exact={true} />
                <Route path="/configuracion/recetas" component={ConfigurationReceipes} exact={true} />
                <Route path="/configuracion/agregar-receta" component={ConfigurationReceipesForm} exact={true} />
                <Route path="/configuracion/categorias-egresos" component={ConfigurationExpenses} exact={true} />
                <Route path="/configuracion/cfdis" component={ConfigurationCfdis} exact={true} />
                <Route path="/configuracion/contraseñas" component={ConfigurationPasswords} exact={true} />
                <Route path="/configuracion/formas-pago-sat" component={ConfigurationSatPayway} exact={true} />
            </Switch>
        </div>
    )
}

export default Configuration