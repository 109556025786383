import React, {useState,useEffect} from 'react'
import firebase from '../../../firebase'
import Select from 'react-select'
import moment from 'moment'

const SalesInvoicingForm = () => {

    const [customers,setcustomers] = useState([])
    const [brands,setbrands] = useState([])

    const [customer,setCustomer] = useState(undefined)
    const [brand,setBrand] = useState(undefined)

    const [date,setdate] = useState('')
    const [order,setorder] = useState('')
    const [invoice,setinvoice] = useState('')
    const [invoiceAmount,setinvoiceAmount] = useState('')
    const [payment,setpayment] = useState('')
    const [receibable,setreceibable] = useState('')
    const [paymentDate,setpaymentDate] = useState('')
    const [status,setstatus] = useState('Pendiente')

    useEffect(() => {
        firebase.getCollectionOnce('customers').then(snap => {
            var data = []
            snap.docs.forEach(doc => {
                const item = doc.data()
                data.push({ label: item.name, value: item.id })
            })
            setcustomers(data)
        })
        firebase.getCollectionOnce('brands').then(snap => {
            var data = []
            snap.docs.forEach(doc => {
                const item = doc.data()
                data.push({ label: item.name, value: item.id })
            })
            setbrands(data)
        })
    },[])
    return (
        <div className="sales-invoicing-form">
            <form onSubmit={e => e.preventDefault() && false}>
                <div className="input-container">
                    <label>Fecha</label>
                    <input value={moment(date).format('YYYY-MM-DD')} onChange={e => setdate(moment(e.target.value,'YYYY-MM-DD').valueOf())} type="date" />
                </div>
                <div className="input-container">
                    <label>Cliente</label>
                    <Select
                        options={customers}
                        value={customer}
                        onChange={setCustomer}
                    />
                </div>
                <div className="input-container">
                    <label>Marca</label>
                    <Select
                        options={brands}
                        value={brand}
                        onChange={setBrand}
                    />
                </div>
                <div className="input-container">
                    <label>No. de Pedido</label>
                    <input value={order} onChange={e => setorder(e.target.value)} type="text" />
                </div>
                <div className="input-container">
                    <label>No. de Factura</label>
                    <input value={invoice} onChange={e => setinvoice(e.target.value)} type="text" />
                </div>
                <div className="input-container">
                    <label>Total Factura</label>
                    <input value={invoiceAmount} onChange={e => setinvoiceAmount(e.target.value)} type="number" />
                </div>
                <div className="input-container">
                    <label>Total de Pago</label>
                    <input value={payment} onChange={e => setpayment(e.target.value)} type="number" />
                </div>
                <div className="input-container">
                    <label>Pendiente de Pago</label>
                    <input value={receibable} onChange={e => setreceibable(e.target.value)} type="number" />
                </div>
                <div className="input-container">
                    <label>Fecha de Pago</label>
                    <input value={moment(paymentDate).format('YYYY-MM-DD')} onChange={e => setpaymentDate(moment(e.target.value, 'YYYY-MM-DD').valueOf())} type="date" />
                </div>
                <div className="input-container">
                    <label>Estatus</label>
                    <select value={status} onChange={e => setstatus(e.target.value)}>
                        <option value="Pendiente">Pendiente</option>
                        <option value="Pagado">Pagado</option>
                        <option value="Aclaración">Aclaración</option>
                    </select>
                </div>
                <div className="input-container">
                    <button onClick={add} type="submit">Agregar</button>
                </div>
            </form>
        </div>
    )

    async function add () {
        try {
            const item = {
                brandId: brand.value,
                brandName: brand.label,
                customerId: customer.value,
                customerName: customer.label,
                date,
                invoice,
                invoiceAmount: Number(invoiceAmount),
                order,
                payment: Number(payment),
                paymentDate,
                receibable: Number(receibable),
                status,
            }
            firebase.simpleAdd(item,'billing').then(() => {
                alert('Factura agregada')
                setdate('')
                setorder('')
                setinvoice('')
                setinvoiceAmount('')
                setpayment('')
                setreceibable('')
                setpaymentDate('')
                setstatus('Pendiente')
            })
        } catch (error) {
            alert(error.message)
        }
    }
}

export default SalesInvoicingForm