import React, { useState,useEffect } from 'react'
import firebase from '../../firebase'
import moment from 'moment'

const PromoList = () => {

    const [reports,setReports] = useState([])

    useEffect(() => {
        firebase.getCollectionRealTime('promo-reports').orderBy('timestamp','desc').onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setReports(data)
        })
    },[])

    return (
        <div className="promo-list">
            <table>
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Nombre de Reporte</th>
                        <th>Link</th>
                    </tr>
                </thead>
                <tbody>{
                    Object.keys(reports).map(key => <tr key={key}>
                        <td>{moment(reports[key].timestamp).format('DD-MM-YYYY hh:mm a')}</td>
                        <td>{reports[key].name}</td>
                        <td><a target="_blank" rel="noopener noreferrer" href={`https://casalohr.com/reportes-promotoria/${reports[key].id}`}>{`https://casalohr.com/reportes-promotoria/${reports[key].id}`}</a></td>
                    </tr>)
                }</tbody>
            </table>
        </div>
    )
}

export default PromoList