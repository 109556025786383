import React, { useState,useEffect } from 'react'
import firebase from '../../../firebase'
import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'

const Item = (props) => {
    const {data} = props

    return (
        <tr>
            <td>{moment(data.dateStatrt).format('DD-MM-YYYY')} - {moment(data.dateEnd).format('DD-MM-YYYY')}</td>
            <td>{data.payway}</td>
            <td>{data.brand}</td>
            <td>{data.name}</td>
            <td>{numeral(data.amount).format('$0,0.00')}</td>
        </tr>
    )
}

const HumanResourcesRosterContainer = (props) => {

    const [roster,setRoster] = useState([])

    useEffect(() => {
        const initialMonth = moment(props.month).startOf('month').valueOf()
        const endMonth = moment(props.month).endOf('month').valueOf()

        if (initialMonth && endMonth) {
            const getRoster = new Promise((resolve) => {
                firebase.getCollectionRealTime('roster').where('dateEnd','<=',endMonth).where('dateEnd','>=',initialMonth).onSnapshot(snap => {
                    var data = []
                    snap.docs.forEach(doc => {
                        var item = doc.data()
                        item.type = 'Ingreso'
                        data.push(item)
                    })
                    resolve(data)
                })
            })
            getRoster.then(roster => {
                setRoster(_.orderBy(roster, ['date'],['asc']))
            })
        }
    },[props.month])

    return (
        <tbody>
            {
                Object.keys(roster).map(key => <Item key={key} data={roster[key]} />)
            }
        </tbody>
    )
}

const Employee = (props) => {
    const {data} = props

    return (
        <tr>
            <td>{data.name}</td>
            <td className="center">{numeral(data.subTotal).format('$0,0.00')}</td>
            <td className="center">{numeral(data.assistanceDiscounts).format('$0,0.00')}</td>
            <td><input value={data.discomp} onChange={e => props.updateEmp(props.index,'discomp',Number(e.target.value))} type="number" /></td>
            <td className="center">{numeral(data.subTotal - (data.assistanceDiscounts ? data.assistanceDiscounts : 0) + (data.discomp ? data.discomp : 0)).format('$0,0.00')}</td>
            <td>{data.uploadStatus === false ? <i className="material-icons">cloud_upload</i> : <i className="material-icons">done</i>}</td>
        </tr> 
    )
}

const HumanResourcesRoster = () => {

    const [month,setMonth] = useState([])
    const [dateStart,setdateStart] = useState(undefined)
    const [dateEnd,setdateEnd] = useState(undefined)
    const [employees,setEmployees] = useState([])
    const [isOpen,setIsOpen] = useState(false)
    const [newRost,setNewRost] = useState([])

    useEffect(() => {
        firebase.getCollectionOnce('employees').then(snap => {
            const data = snap.docs.map(doc => doc.data())
            setEmployees(data)
        })
        const current = moment().format('MM-YYYY')
        setMonth(current)
    }, [])

    return (
        <div className="human-resources-roster">
            <h1>Crear Nómina</h1>
            <div className="human-resources-roster__form">
                <div className="input-container">
                    <label>Fecha de Inicio</label>
                    <input value={dateStart} onChange={e => setdateStart(e.target.value)} type="date" />
                </div>
                <div className="input-container">
                    <label>Fecha de Fin</label>
                    <input value={dateEnd} onChange={e => setdateEnd(e.target.value)} type="date" />
                </div>
                <div className="input-container">
                    <button onClick={cont}>Siguiente</button>
                </div>
            </div>
            {isOpen ? <div className="human-resources-roster__creator">
                <table>
                    <thead>
                        <tr>
                            <th>Nombre de Empleado</th>
                            <th>Monto</th>
                            <th>Descuentos por faltas / retardos</th>
                            <th>Descuentos / Compensaciones</th>
                            <th>Total</th>
                            <th>Estatus</th>
                        </tr>
                    </thead>
                    <tbody>{
                        Object.keys(employees).map(key => <Employee updateEmp={updateEmp} index={key} key={key} data={employees[key]} />)
                    }</tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="3"></td>
                            <td colSpan="2"><button onClick={handleSubmit}>Agregar Nómina</button></td>
                        </tr>
                    </tfoot>
                </table>
            </div> : ''}
            <table>
                <thead>
                    <tr>
                        <th colSpan="6">
                            <div className="date-picker">
                                <button onClick={prevMonth}>
                                    <i className="material-icons">arrow_left</i>
                                </button>
                                <span>{month}</span>
                                <button onClick={nextMonth}>
                                    <i className="material-icons">arrow_right</i>
                                </button>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th>Fecha</th>
                        <th>Forma de Pago</th>
                        <th>Marca</th>
                        <th>Empleado</th>
                        <th>Monto</th>
                    </tr>
                </thead>
                <HumanResourcesRosterContainer month={moment(month,'MM-YYYY').valueOf()} />
            </table>
        </div>
    )

    async function handleSubmit () {
        try {
            newRost.forEach((item,key) => {
                item.dateStatrt = moment(dateStart,'YYYY-MM-DD').valueOf()
                item.dateEnd = moment(dateEnd,'YYYY-MM-DD').valueOf()
                item.amount = item.subTotal - (item.assistanceDiscounts ? item.assistanceDiscounts : 0) + (item.discomp ? item.discomp : 0)

                firebase.simpleAdd(item,'roster').then(() => {
                    const cpp = {
                        brandName: 'EL MAYOR',
                        brandId: '9Kzs8p50WilD5fjxRtNg',
                        date: dateEnd,
                        concept: `Nómina de ${item.name} del ${dateStart} al ${dateEnd}`,
                        total: Number(item.amount),
                        providerName: 'Nómina Casa Lohr',
                        type: 'fast-purchase',
                        v2: true,
                        paymentStatus: 'pending',
                        processStatus: 'pending'
                    }
                    firebase.simpleAdd(cpp,'purchases').then(() => {
                        var newList = [...newRost]
                        newList[key].uploadStatus = true
                        setNewRost(newList)
                    })
                })
            })
        } catch (error) {
            alert(error.message)
        }
    }

    async function updateEmp (key,index,value) {
        try {
            var newList = [...newRost]
            newList[key][index] = value
            setNewRost(newList)
        } catch (error) {
            alert(error.message)
        }
    }

    async function cont () {
        try {
            const initial = moment(dateStart, 'YYYY-MM-DD').valueOf()
            const final = moment(dateEnd, 'YYYY-MM-DD').valueOf()
            if (initial && final) {
                firebase.getCollectionRealTime('assistance').where('date','<=',final).where('date','>=',initial).get().then(snap => {
                    const assistance = snap.docs.map(doc => doc.data())
                    var list = []
                    employees.forEach(emp => {
                        const difference = moment(final).diff(initial, 'days');

                        const retardos = _.size(_.filter(assistance, o => {
                            return o.employeeId === emp.id && o.type === 'Retardo'
                        }));
                        const faltas = _.size(_.filter(assistance, o => {
                            return o.employeeId === emp.id && o.type === 'Falta'
                        }));
                        const perDay = emp.salary / 30;
                        const subTotal = emp.salary / 30 * difference;
                        console.log(difference)
                        const discounts = (Math.floor(retardos / 3 )* perDay) + (faltas * perDay);

                        emp.faults = Number(faltas)
                        emp.delays = Number(retardos)
                        emp.subTotal = Number(subTotal)
                        emp.assistanceDiscounts = Number(discounts)
                        emp.paymentStatus = 'pending'
                        emp.uploadStatus = false
                        list.push(emp)
                    })
                    setNewRost(list)
                    setIsOpen(true)
                })
            } else {
                alert('Agrega una fecha de inicio y una de fin')
            }
        } catch (error) {
            alert(error.message)
        }
    }

    async function nextMonth() {
        try {
            const newMonth = moment(month, 'MM-YYYY').add(1,'month').format('MM-YYYY')
            setMonth(newMonth)
        } catch (error) {
            alert(error.message)
        }
    }
    async function prevMonth() {
        try {
            const newMonth = moment(month, 'MM-YYYY').subtract(1,'month').format('MM-YYYY')
            setMonth(newMonth)
        } catch (error) {
            alert(error.message)
        }
    }
}

export default HumanResourcesRoster