import React, {useState, useEffect} from 'react'
import firebase from '../../firebase'
import Select from 'react-select'
import numeral from 'numeral'
import _ from 'lodash'

const Item = (props) => {
    const {data} = props

    return (
        <tr>
            <td>{data.productCode}</td>
            <td>{data.brand}</td>
            <td>{data.productName} {data.name} {data.description}</td>
            <td className="center">{numeral(data.quantity).format('0.00')}</td>
            {/* <td><input value={data.quantity} onChange={e => firebase.update('product-presentations',data.id,'quantity',Number(e.target.value))} type="number"/></td> */}
            <td className="center">{numeral(data.um).format('0.00')}</td>
            {/* <td><input value={data.um} onChange={e => firebase.update('product-presentations',data.id,'um',Number(e.target.value))} type="number"/></td> */}
        </tr>
    )
}

const Warehouse = () => {

    const [presentations,setPresentations] = useState([])
    const [type,setType] = useState('false')
    const [products,setProducts] = useState([])
    const [selected,setSelected] = useState(undefined)
    const [seeker,setSekker] = useState('')

    const totalQuantity = _.reduce(presentations, (sum,n) => {
        return sum + (n.quantity ? n.quantity : 0)
    },0)
    const totalUm = _.reduce(presentations, (sum,n) => {
        return sum + (n.um ? n.um : 0)
    },0)

    useEffect(() => {
        firebase.getCollectionRealTime('product-presentations').get().then(snap => {
            const data = snap.docs.map(doc => doc.data())
            setPresentations(data)
        })
    },[])

    const search = seeker ? _.filter(presentations, item => {
        return _.lowerCase(item.productName + ' ' + item.name + ' ' + item.description + ' ' + item.brand + ' ' + item.productCode).match(_.lowerCase(seeker))
    }) : presentations

    return (
        <div className="warehouse">
            {/* <form onSubmit={e => e.preventDefault() && false}>
                <div className="input-container">
                    <label>Tipo</label>
                    <select onChange={e => handleSelect(e.target.value)} value={type}>
                        <option value="false">** Selecciona una opción **</option>
                        <option value="Consomés">Consomés</option>
                        <option value="Moles">Moles</option>
                        <option value="Adobos">Adobos</option>
                        <option value="Molidos">Molidos</option>
                        <option value="Otros">Otros</option>
                    </select>
                </div>
                <div className="input-container">
                    <label>Productos</label>
                    <Select
                        options={products}
                        value={selected}
                        onChange={setSelected}
                    />
                </div>
                <div className="input-container">
                    <button onClick={handleSubmit} type="submit">Agregar</button>
                </div>
            </form> */}
            <div className="seeker">
                <span><i className="material-icons">search</i></span>
                <input placeholder="Buscar ..." value={seeker} onChange={e => setSekker(e.target.value)} type="text"/>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Código</th>
                        <th>Marca</th>
                        <th>Producto</th>
                        <th>Cantidad</th>
                        <th>Kilogramos / Litros</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Object.keys(search).map(key => <Item key={key} data={search[key]} />)
                    }
                </tbody>
                {/* <tfoot>
                    <tr>
                        <td colSpan="2"></td>
                        <th>Totales</th>
                        <th>{totalQuantity}</th>
                        <th>{totalUm}</th>
                    </tr>
                </tfoot> */}
            </table>
        </div>
    )

    // async function handleSubmit() {
    //     try {
    //         firebase.getCollectionRealTime('product-presentations').where('productId','==',selected.value).get().then(snap => {
    //             const data = snap.docs.map(doc => doc.data())
    //             setPresentations(data)
    //         })
    //     } catch (error) {
    //         alert(error.message)
    //     }
    // }

    // async function handleSelect(value) {
    //     firebase.getCollectionRealTime('products').where('type','==',value).onSnapshot(snap => {
    //         var data = []
    //         snap.docs.forEach(doc => {
    //             const item = doc.data()
    //             data.push({ label: `${item.code} - ${item.name}`, value: item.id })
    //         })
    //         setProducts(data)
    //         setType(value)
    //     })
    // }
}

export default Warehouse