import React, { useState,useEffect } from 'react'
import firebase from '../../../firebase'

const ConfigurationSingleProduct = (props) => {

    const id = props.match.params.id
    const [product,setProduct] = useState({})
    const [brands,setBrands] = useState([])

    useEffect(() => {
        firebase.getDocumentRealTime('products',id).onSnapshot(snap => {
            setProduct(snap.data())
        })
        firebase.getCollectionOnce('brands').then(snap => {
            const brands = snap.docs.map(doc => doc.data())
            setBrands(brands)
        })
    }, [id])

    return (
        <div className="configuration-single-product">
            <div className="details">
                <table>
                    <thead>
                        <tr>
                            <th colSpan="2">Detalle de Producto</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>ID</th>
                            <td>{product.id}</td>
                        </tr>
                        <tr>
                            <th>Código</th>
                            <td><input defaultValue={product.code} onChange={e => firebase.update('products',id,'code',e.target.value)} type="text"/></td>
                        </tr>
                        <tr>
                            <th>Nombre</th>
                            <td><input defaultValue={product.name} onChange={e => firebase.update('products',id,'name',e.target.value)} type="text"/></td>
                        </tr>
                        <tr>
                            <th>Marca</th>
                            <td>
                                <select onChange={e => firebase.update('products',id,'brand',e.target.value)} value={product.brand}>
                                    {
                                        Object.keys(brands).map(key => <option key={key} value={brands[key].name}>{brands[key].name}</option>)
                                    }
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>Tipo</th>
                            <td>
                                <select onChange={e => firebase.update('products',id,'type',e.target.value)} value={product.type}>
                                    <option value="false">Seleccionar</option>
                                    <option value="Consomés">Consomés</option>
                                    <option value="Moles">Moles</option>
                                    <option value="Adobos">Adobos</option>
                                    <option value="Molidos">Molidos</option>
                                    <option value="Otros">Otros</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>Valor</th>
                            <td><input defaultValue={product.value} onChange={e => firebase.update('products',id,'value',e.target.value)} type="number"/></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ConfigurationSingleProduct