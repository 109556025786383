import React, { useState, useEffect } from 'react'
import firebase from '../../../firebase'
import { Link } from 'react-router-dom'
import _ from 'lodash'

const Item = (props) => {

    const {data} = props;

    const [sellerName,setSellerName] = useState((''))

    useEffect(() => {
        if (data.sellerId) {
            firebase.getDocumentRealTime('users',data.sellerId).onSnapshot(user => {
                setSellerName(user.data().name)
            })
        }
    },[data.sellerId])
    return (
        <tr>
            <td>{data.name}</td>
            <td>
                <select value={data.status} onChange={e => update(e)}>
                    <option>** Selecciona **</option>
                    <option value="Pendiente">Pendiente</option>
                    <option value="En Proceso">En Proceso</option>
                    <option value="Completado">Completado</option>
                </select>
            </td>
            <td>{sellerName}</td>
            <td>{data.payway}</td>
            <td>{data.payTerm}</td>
            <td>
                <Link to={`/ventas/clientes/${data.id}`}><i className="material-icons">keyboard_arrow_right</i></Link>
            </td>
        </tr>
    )
    
    async function update(e) {
        try {
            await firebase.update('customers',data.id,'status',e.target.value)
        } catch (error) {
            alert(error.message)
        }
    }
}

const SalesCustomers = (props) => {

    const [customers,setCustomers] = useState([])
    const [filter,setFilter] = useState(undefined)
    const [seeker,setSekker] = useState('')
    const [customersFromJunior,setCustomersFromJunior] = useState([])

    useEffect(() => {
        const sellerValidator = props.isSeller === true ? firebase.getCollectionRealTime('customers').where('sellerId','==',props.userId) : firebase.getCollectionRealTime('customers')
        sellerValidator.onSnapshot(snap => {
            setCustomers(snap.docs.map(doc => doc.data()))
        })
        if (props.userId) {
            firebase.getCollectionRealTime('customers').where('sellerJuniorId','==',props.userId).onSnapshot(snap => {
                setCustomersFromJunior(snap.docs.map(doc => doc.data()))
            })
        }
    }, [props.isSeller, props.userId])

    const ordered = _.orderBy(_.concat(customers,customersFromJunior), ['name'], ['asc'])

    const data = filter ? _.filter(ordered, { status: filter }) : ordered

    const search = seeker ? _.filter(data, item => {
        return _.lowerCase(item.name + ' ' + item.sellerName + ' ' + item.status + ' ' + item.payway + ' ' + item.payTerm).match(_.lowerCase(seeker))
    }) : data

    return (
        <div className="sales-customers">
            <div className="seeker">
                <span><i className="material-icons">search</i></span>
                <input placeholder="Buscar ..." value={seeker} onChange={e => setSekker(e.target.value)} type="text"/>
            </div>
            <div className="filter">
                <button className={filter === undefined ? 'active' : ''} onClick={() => setFilter(undefined)}>Ver Todos</button>
                <button className={filter === 'Pendiente' ? 'active' : ''} onClick={() => setFilter('Pendiente')}>Pendiente</button>
                <button className={filter === 'En Proceso' ? 'active' : ''} onClick={() => setFilter('En Proceso')}>En Proceso</button>
                <button className={filter === 'Completado' ? 'active' : ''} onClick={() => setFilter('Completado')}>Completado</button>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Nombre / Razón Social</th>
                        <th>Estatus</th>
                        <th>Vendedor</th>
                        <th>Forma de Pago</th>
                        <th>Término de Pago</th>
                        <th>Ver</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Object.keys(search).map(key =>
                            <Item
                                key={key}
                                data={search[key]}
                            />
                        )
                    }
                </tbody>
            </table>
        </div>
    )
}

export default SalesCustomers;