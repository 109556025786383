import React, { useEffect, useState } from 'react'
import firebase from '../../../firebase'
import Select from 'react-select'
import moment from 'moment'
import _ from 'lodash'

const Item = (props) => {
    
    const {data} = props

    return (
        <tr>
            <td>{moment(data.date).format('DD-MM-YYYY hh:mm a')}</td>
            <td>{data.customerName}</td>
            <td>{data.contactName}</td>
            <td>{data.contactPhone}</td>
            <td>{data.contactEmail}</td>
            <td>{data.details}</td>
        </tr>
    )
}

const SalesMemoContainer = (props) => {
    const [memo,setMemo] = useState([])

    useEffect(() => {
        const initialMonth = moment(props.month,'MM-YYYY').startOf('month').valueOf()
        const endMonth = moment(props.month,'MM-YYYY').endOf('month').valueOf()
        if (initialMonth && endMonth) {
            firebase.getCollectionRealTime('memo').where('date','<=',endMonth).where('date','>=',initialMonth).onSnapshot(snap => {
                const memo = snap.docs.map(doc => doc.data())
                setMemo(memo)
            })
        }
    }, [props.month])

    const ordered = _.orderBy(memo, ['date'],['desc'])

    return (
        <tbody>{
            Object.keys(ordered).map(key => <Item key={key} data={ordered[key]} />)
        }</tbody>
    )
}

const SalesMemo = () => {

    const [customers,setCustomers] = useState([])
    const [directory,setDirectory] = useState([])
    const [details,setdetails] = useState('')
    const [month,setMonth] = useState(undefined)

    const [selectedCustomer,setSelectedCustomer] = useState(undefined)
    const [selectedContact,setSelectedContact] = useState(undefined)

    useEffect(() => {
        const current = moment().format('MM-YYYY')
        setMonth(current)
        firebase.getCollectionOnce('customers').then(snap => {
            var data = []
            snap.docs.forEach(doc => {
                const customer = doc.data();
                const item = { label: customer.name, value: customer.id }
                data.push(item)
            })
            setCustomers(data)
        })
    }, [])

    return (
        <div className="sales-memo">
            <form onSubmit={e => e.preventDefault() && false}>
                <div className="input-container">
                    <label>Cliente</label>
                    <Select
                        options={customers}
                        value={selectedCustomer}
                        onChange={selectCustomer}
                    />
                </div>
                <div className="input-container">
                    <label>Contacto</label>
                    <Select
                        options={directory}
                        value={selectedContact}
                        onChange={setSelectedContact}
                    />
                </div>
                <div className="input-container">
                    <label>Detalle</label>
                    <textarea value={details} onChange={e => setdetails(e.target.value)} placeholder="Detalle"></textarea>
                </div>
                <div className="input-container">
                    <button onClick={add} type="submit">Agregar</button>
                </div>
            </form>
            <table>
                <thead>
                    <tr>
                        <th colSpan="6">
                            <div className="date-picker">
                                <button onClick={prevMonth}>
                                    <i className="material-icons">arrow_left</i>
                                </button>
                                <span>{month}</span>
                                <button onClick={nextMonth}>
                                    <i className="material-icons">arrow_right</i>
                                </button>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th>Fecha</th>
                        <th>Cliente</th>
                        <th>Nombre</th>
                        <th>Teléfono</th>
                        <th>Email</th>
                        <th>Detalle</th>
                    </tr>
                </thead>
                <SalesMemoContainer month={month} />
            </table>
        </div>
    )

    async function add() {
        try {
            const item = {
                customerId: selectedCustomer.value,
                customerName: selectedCustomer.label,
                contactId: selectedContact.value,
                contactName: selectedContact.data.name,
                contactEmail: selectedContact.data.email,
                contactPhone: selectedContact.data.phone,
                details,
                date: moment().valueOf()
            }
            firebase.simpleAdd(item,'memo').then(() => {
                setSelectedCustomer('')
                setSelectedContact('')
                setdetails('')
            })
        } catch (error) {
            alert(error.message)
        }
    }

    async function nextMonth() {
        try {
            const newMonth = moment(month, 'MM-YYYY').add(1,'month').format('MM-YYYY')
            setMonth(newMonth)
        } catch (error) {
            alert(error.message)
        }
    }
    async function prevMonth() {
        try {
            const newMonth = moment(month, 'MM-YYYY').subtract(1,'month').format('MM-YYYY')
            setMonth(newMonth)
        } catch (error) {
            alert(error.message)
        }
    }

    async function selectCustomer(data) {
        try {
            firebase.getCollectionRealTime('directory').where('customerId','==',data.value).get().then(snap => {
                setSelectedCustomer(data)
                var dat = []
                snap.docs.forEach(doc => {
                    const item = doc.data()
                    dat.push({ label: `${item.name} - ${item.email}`, value: item.id, data: item })
                })
                setDirectory(dat)
            })
        } catch (error) {
            alert(error.message)
        }
    }
}

export default SalesMemo