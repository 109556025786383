import React, { useState,useEffect } from 'react'
import firebase from '../../firebase'
import moment from 'moment'
import _ from 'lodash'

const Moves = () => {

    const [moves,setMoves] = useState([])

    useEffect(() => {
        firebase.getCollectionRealTime('usersMoves').where('col','==','incomes').onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setMoves(_.orderBy(data, ['timestamp'],['desc']))
        })
    },[])

    return (
        <div className="moves">
            <h1>Movimientos</h1>
            <table>
                <thead>
                    <th>Fecha y Hora</th>
                    <th>Columna</th>
                    <th>Usuario</th>
                    <th>Item Id</th>
                </thead>
                <tbody>{
                    Object.keys(moves).map(key => <tr>
                        <td>{moment(moves[key].timestamp).format('DD-MM-YYYY hh:mm')}</td>
                        <td>{moves[key].col}</td>
                        <td>{moves[key].userId}</td>
                        <td>{moves[key].what}</td>
                    </tr>)
                }</tbody>
            </table>
        </div>
    )
}

export default Moves