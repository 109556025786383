import React, {useState,useEffect} from 'react'
import firebase from '../../../firebase'
import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'

const Item = (props) => {
    const {data} = props

    return (
        <tr>
            <td>{moment(data.date).format('DD-MM-YYYY')}</td>
            <td>{data.payway}</td>
            <td>{data.brandName}</td>
            <td>{data.holderName}</td>
            <td>{data.concept}</td>
            <td>{numeral(data.amount).format('$0,0.00')}</td>
        </tr>
    )
}

const AccountingIncomesContainer = (props) => {
    
    const [moves,setmoves] = useState([])

    useEffect(() => {
        const initialMonth = moment(props.month).startOf('month').valueOf()
        const endMonth = moment(props.month).endOf('month').valueOf()

        if (initialMonth && endMonth) {
            const getIncomes = new Promise((resolve) => {
                firebase.getCollectionRealTime('incomes').where('date','<=',endMonth).where('date','>=',initialMonth).onSnapshot(snap => {
                    var data = []
                    snap.docs.forEach(doc => {
                        var item = doc.data()
                        item.type = 'Ingreso'
                        data.push(item)
                    })
                    resolve(data)
                })
            })
            getIncomes.then(incomes => {
                setmoves(_.orderBy(incomes, ['date'],['asc']))
            })
        }
    }, [props.month])

    const seeker = props.seeker ? _.filter(moves, item => {
        return _.lowerCase(item.payway + ' ' + item.brandName + ' ' + item.holderName + ' ' + item.concept + ' ' + item.amount).match(_.lowerCase(props.seeker))
    }) : moves

    const totalIncomes = _.reduce(seeker, (sum,n) => {
        if (n.type === 'Ingreso') {
            return sum + n.amount
        }
        return sum
    },0)

    return (
        <tbody>
            {
                Object.keys(seeker).map(key => <Item key={key} data={seeker[key]} />)
            }
            <tr>
                <th colSpan="4"></th>
                <th>Total</th>
                <th>{numeral(totalIncomes).format('$0,0.00')}</th>
            </tr>
        </tbody>
    )

}

const AccountingIncomes = () => {

    const [month,setMonth] = useState(undefined)
    const [seeker,setSekker] = useState('')

    useEffect(() => {
        const current = moment().format('MM-YYYY')
        setMonth(current)
    }, [])

    return (
        <div className="accounting-incomes">
            <div className="seeker">
                <span><i className="material-icons">search</i></span>
                <input placeholder="Buscar ..." value={seeker} onChange={e => setSekker(e.target.value)} type="text"/>
            </div>
            <table>
                <thead>
                    <tr>
                        <th colSpan="6">
                            <div className="date-picker">
                                <button onClick={prevMonth}>
                                    <i className="material-icons">arrow_left</i>
                                </button>
                                <span>{month}</span>
                                <button onClick={nextMonth}>
                                    <i className="material-icons">arrow_right</i>
                                </button>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th>Fecha</th>
                        <th>Forma de Pago</th>
                        <th>Marca</th>
                        <th>Cliente / Proveedor</th>
                        <th>Concepto</th>
                        <th>Monto</th>
                    </tr>
                </thead>
                <AccountingIncomesContainer seeker={seeker} month={moment(month,'MM-YYYY').valueOf()} />
            </table>
        </div>
    )

    async function nextMonth() {
        try {
            const newMonth = moment(month, 'MM-YYYY').add(1,'month').format('MM-YYYY')
            setMonth(newMonth)
        } catch (error) {
            alert(error.message)
        }
    }
    async function prevMonth() {
        try {
            const newMonth = moment(month, 'MM-YYYY').subtract(1,'month').format('MM-YYYY')
            setMonth(newMonth)
        } catch (error) {
            alert(error.message)
        }
    }
}

export default AccountingIncomes