import React, { useState,useEffect } from 'react'
import firebase from '../../firebase'
import moment from 'moment'
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts'
import _ from 'lodash'

const DashboardThree = (props) => {

    const [data,setData] = useState([])

    useEffect(() => {
        const getUsers = new Promise (resolve => {
            firebase.getCollectionOnce('users').then(snap => {
                resolve(snap.docs.map(doc => doc.data()))
            })
        })
        const getCustomers = new Promise (resolve => {
            firebase.getCollectionOnce('customers').then(snap => {
                resolve(snap.docs.map(doc => doc.data()))
            })
        })
        const getBilling = new Promise (resolve => {
            const initialMonth = moment(props.month,'MM-YYYY').startOf('month').valueOf()
            const endMonth = moment(props.month,'MM-YYYY').endOf('month').valueOf()
            if (initialMonth && endMonth) {
                firebase.getCollectionRealTime('billing').where('date','<=',endMonth).where('date','>=',initialMonth).onSnapshot(snap => {
                    const billing = _.filter(snap.docs.map(doc => doc.data()), o => {
                        return o.status !== 'Cancelado'
                    })
                    const customers = _.uniqBy(billing, 'customerId')
                    var data = []
                    customers.forEach(cust => {
                        const filt = _.filter(billing, { customerId: cust.customerId })
                        const total = _.reduce(filt, (sum,n) => {
                            return sum + n.invoiceAmount
                        },0)
                        data.push({ name: cust.customerName, id: cust.customerId, total })
                    })
                    resolve(data)
                })
            }
        })
        getBilling.then(billing => {
            getUsers.then(users => {
                getCustomers.then(customers => {
                    var data = []
                    billing.forEach(bill => {
                        const customer = _.find(customers, { id: bill.id })
                        if (customer) {
                            const seller = _.find(users, { id: customer.sellerId })
                            if (seller) {
                                data.push({ [customer.name]: bill.total, sellerId: seller.id, sellerName: seller.name, customerName: customer.name, customerId: customer.id })
                            }
                        }
                    })
                    setData(data)
                })
            })
        })
    },[props.month])

    const colors = ['#2196F3','#4caf50','#f44336','#7881a9','#F2B134','#ED553B','#F2B134','#068587','#112F41','#2196F3','#4caf50','#f44336','#7881a9','#F2B134','#ED553B','#F2B134','#068587','#112F41','#2196F3','#4caf50','#f44336','#7881a9','#F2B134','#ED553B','#F2B134','#068587','#112F41','#2196F3','#4caf50','#f44336','#7881a9','#F2B134','#ED553B','#F2B134','#068587','#112F41','#2196F3','#4caf50','#f44336','#7881a9','#F2B134','#ED553B','#F2B134','#068587','#112F41',]

    const customers = _.unionBy(data,'customerId')
    // const filter = _.reduce(sellers, (arr,n) => {
    //     const sales = _.filter(data, { sellerId: n.sellerId })
    //     const tot = _.reduce(sales, (sum,i) => {
    //         return sum + i.total
    //     },0)
    //     return _.concat(arr,{ sellerName: n.sellerName, total: tot })
    // },[])

    return (
        <div style={{ marginTop: '2.5rem', }} className="dashboard-three">
            <ResponsiveContainer width="100%" height={300}>
                <BarChart
                    data={customers}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="sellerName" />
                    <YAxis />
                    <Tooltip />
                    <Legend verticalAlign="top" />
                    {
                        Object.keys(customers).map(key => <Bar key={key} type="monotone" dataKey={customers[key].customerName} fill={colors[key]} />)
                    }
                </BarChart>
            </ResponsiveContainer>
        </div>
    )
}

export default DashboardThree