import React, { useState,useEffect } from 'react'
import firebase from '../../firebase'
import moment from 'moment'

export default function Media () {

    const [file,setFile] = useState(undefined)
    const [files,setFiles] = useState([])

    useEffect(() => {
        firebase.getCollectionRealTime('files').orderBy('timestamp','desc').onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setFiles(data)
        })
    }, [])

    return (
        <div style={{ padding: '2.5rem' }} className="configuration-media">
            <form style={{ display: 'flex' }} onSubmit={e => e.preventDefault() && false}>
                <div style={{ flex: 1 }} className="input-container">
                    <label>Archivo</label>
                    <input onChange={e => setFile(e.target.files[0])} type="file"/>
                </div>
                <div style={{ flex: 1 }} className="input-container">
                    <button style={{ marginTop: 'auto' }} onClick={handleSubmit} type="submit">Agregar</button>
                </div>
            </form>
            <table>
                <thead>
                    <tr>
                        <th>Fecha y Hora de Creación</th>
                        <th>URL</th>
                    </tr>
                </thead>
                <tbody>{
                    files.map(item => <tr>
                        <td>{moment(item.timestamp).format('DD-MM-YYYY hh:mm a')}</td>
                        <td>{item.url}</td>
                    </tr>)
                }</tbody>
            </table>
        </div>
    )

    async function handleSubmit () {
        try {
            const item = {}
            firebase.addFile(item,file)
        } catch (error) {
            alert(error.message)
        }
    }
}