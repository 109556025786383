import React, { useState, useEffect} from 'react'
import firebase from '../firebase'
import { Switch, Route, Link } from 'react-router-dom'

import Loader from './Loader';
import Production from './production/Production';
import Sales from './sales/Sales';
import Configuration from './configuration/Configuration';
import Importer from './importer/Importer';
import Warehouse from './warehouse/Warehouse';
import Inventory from './inventory/Inventory';
import Purchases from './purchases/Purchases';
import Accounting from './accounting/Accounting';
import HumanResources from './human-resources/HumanResources';
import Cost from './cost/Cost';
import Calendar from './calendar/Calendar';
import Analysis from './analysis/Analysis';
import Dashboard from './dashboard/Dashboard';
import ImporterJson from './importer/ImporterJson';
import Moves from './moves/Moves';
import Promo from './promo/Promo';
import Media from './media/Media';

const App = (props) => {

    const [firebaseInitialized, setFirebaseInitialized] = useState(false)
    const [isOpen, setIsOpen] = useState(true)
    const [user, setUser] = useState({})

    useEffect(() => {
        firebase.isInitialized().then(val => {
            if (val) {
                firebase.getDocumentRealTime('users',val.uid).onSnapshot(snap => {
                    setUser(snap.data())
                })
                firebase.userValidator().then(validator => {
                    if (validator === true) {
                        setFirebaseInitialized(val)
                    } else {
                        alert('Usuario no autorizado')
                        return logout()
                    }
                })
            } else {
                props.history.replace('/iniciar-sesion')
                return null
            } 
        })
    },[])

    const loc = props.location.pathname.split('/')[1];
    const perms = user.perms ? user.perms : []

    return firebaseInitialized !== false ? (
        <div className="app">
            <div className="sidenav">
                <img src="/images/ico.png" alt="Casa Lohr"/>
                {
                    user.rol === 'admin' ? <ul className={isOpen === true ? '' : 'close'}>
                        <Link className={loc === '' || loc === 'dashboard' ? 'active' : ''} to="/"><i className="material-icons">dashboard</i><span>Dashboard</span></Link>
                        <Link className={loc === 'analisis' ? 'active' : ''} to="/analisis"><i className="material-icons">timeline</i><span>Análisis</span></Link>
                        <Link className={loc === 'promotoria' ? 'active' : ''} to="/promotoria"><i className="material-icons">view_column</i><span>Promotoría</span></Link>
                        <Link className={loc === 'ventas' ? 'active' : ''} to="/ventas"><i className="material-icons">work</i><span>Ventas</span></Link>
                        <Link className={loc === 'compras' ? 'active' : ''} to="/compras"><i className="material-icons">add_shopping_cart</i><span>Compras</span></Link>
                        <Link className={loc === 'almacen' ? 'active' : ''} to="/almacen"><i className="material-icons">assignment</i><span>Almacén</span></Link>
                        <Link className={loc === 'inventario' ? 'active' : ''} to="/inventario"><i className="material-icons">list</i><span>Inventario</span></Link>
                        <Link className={loc === 'contabilidad' ? 'active' : ''} to="/contabilidad"><i className="material-icons">monetization_on</i><span>Contabilidad</span></Link>
                        <Link className={loc === 'costo' ? 'active' : ''} to="/costo"><i className="material-icons">insert_chart</i><span>Costo</span></Link>
                        <Link className={loc === 'produccion' ? 'active' : ''} to="/produccion"><i className="material-icons">build</i><span>Producción</span></Link>
                        <Link className={loc === 'marketing' ? 'active' : ''} to="/marketing"><i className="material-icons">trending_up</i><span>Marketing</span></Link>
                        <Link className={loc === 'logistica' ? 'active' : ''} to="/logistica"><i className="material-icons">local_shipping</i><span>Logística</span></Link>
                        <Link className={loc === 'recursos-humanos' ? 'active' : ''} to="/recursos-humanos"><i className="material-icons">people</i><span>Recursos Humanos</span></Link>
                        <Link className={loc === 'calendario' ? 'active' : ''} to="/calendario"><i className="material-icons">calendar_today</i><span>Calendario</span></Link>
                        <Link className={loc === 'media' ? 'active' : ''} to="/media"><i className="material-icons">photo</i><span>Media</span></Link>
                        <Link className={loc === 'configuracion' ? 'active' : ''} to="/configuracion"><i className="material-icons">apps</i><span>Configuración</span></Link>
                    </ul> : user.rol === 'vendedor' ? <ul className={isOpen === true ? '' : 'close'}>
                        <Link className={loc === '' || loc === 'dashboard' ? 'active' : ''} to="/"><i className="material-icons">dashboard</i><span>Dashboard</span></Link>
                        <Link className={loc === 'analisis' ? 'active' : ''} to="/analisis"><i className="material-icons">timeline</i><span>Análisis</span></Link>
                        <Link className={loc === 'promotoria' ? 'active' : ''} to="/promotoria"><i className="material-icons">view_column</i><span>Promotoría</span></Link>
                        <Link className={loc === 'ventas' ? 'active' : ''} to="/ventas"><i className="material-icons">work</i><span>Ventas</span></Link>
                        <Link className={loc === 'almacen' ? 'active' : ''} to="/almacen"><i className="material-icons">assignment</i><span>Almacén</span></Link>
                        <Link className={loc === 'inventario' ? 'active' : ''} to="/inventario"><i className="material-icons">list</i><span>Inventario</span></Link>
                        <Link className={loc === 'calendario' ? 'active' : ''} to="/calendario"><i className="material-icons">calendar_today</i><span>Calendario</span></Link>
                    </ul> : <ul className={isOpen === true ? '' : 'close'}>
                        {perms.dashboard === 'active' ? <Link className={loc === '' || loc === 'dashboard' ? 'active' : ''} to="/"><i className="material-icons">dashboard</i><span>Dashboard</span></Link> : ''}
                        {perms.analysis === 'active' ? <Link className={loc === 'analisis' ? 'active' : ''} to="/analisis"><i className="material-icons">timeline</i><span>Análisis</span></Link> : ''}
                        <Link className={loc === 'promotoria' ? 'active' : ''} to="/promotoria"><i className="material-icons">view_column</i><span>Promotoría</span></Link>
                        {perms.sales === 'active' ? <Link className={loc === 'ventas' ? 'active' : ''} to="/ventas"><i className="material-icons">work</i><span>Ventas</span></Link> : ''}
                        {perms.purchases === 'active' ? <Link className={loc === 'compras' ? 'active' : ''} to="/compras"><i className="material-icons">add_shopping_cart</i><span>Compras</span></Link> : ''}
                        {perms.warehouse === 'active' ? <Link className={loc === 'almacen' ? 'active' : ''} to="/almacen"><i className="material-icons">assignment</i><span>Almacén</span></Link> : ''}
                        {perms.inventory === 'active' ? <Link className={loc === 'inventario' ? 'active' : ''} to="/inventario"><i className="material-icons">list</i><span>Inventario</span></Link> : ''}
                        {perms.accounting === 'active' ? <Link className={loc === 'contabilidad' ? 'active' : ''} to="/contabilidad"><i className="material-icons">monetization_on</i><span>Contabilidad</span></Link> : ''}
                        {perms.cost === 'active' ? <Link className={loc === 'costo' ? 'active' : ''} to="/costo"><i className="material-icons">insert_chart</i><span>Costo</span></Link> : ''}
                        {perms.production === 'active' ? <Link className={loc === 'produccion' ? 'active' : ''} to="/produccion"><i className="material-icons">build</i><span>Producción</span></Link> : ''}
                        {perms.marketing === 'active' ? <Link className={loc === 'marketing' ? 'active' : ''} to="/marketing"><i className="material-icons">trending_up</i><span>Marketing</span></Link> : ''}
                        {perms.logistic === 'active' ? <Link className={loc === 'logistica' ? 'active' : ''} to="/logistica"><i className="material-icons">local_shipping</i><span>Logística</span></Link> : ''}
                        {perms['human-resources'] === 'active' ? <Link className={loc === 'recursos-humanos' ? 'active' : ''} to="/recursos-humanos"><i className="material-icons">people</i><span>Recursos Humanos</span></Link> : ''}
                        {perms.calendar === 'active' ? <Link className={loc === 'calendario' ? 'active' : ''} to="/calendario"><i className="material-icons">calendar_today</i><span>Calendario</span></Link> : ''}
                        <Link className={loc === 'media' ? 'active' : ''} to="/media"><i className="material-icons">photo</i><span>Media</span></Link>
                        {perms.configuration === 'active' ? <Link className={loc === 'configuracion' ? 'active' : ''} to="/configuracion"><i className="material-icons">apps</i><span>Configuración</span></Link> : ''}
                    </ul>
                }
            </div>
            <main>
                <header>
                    <span className="logo">Casa Lohr</span>
                    <button onClick={logout}><i className="material-icons">exit_to_app</i></button>
                </header>
                {
                    user.rol === 'admin' || user.rol === 'user' ? <div className="app__container">
                        <button onClick={() => setIsOpen(!isOpen)}>
                            <i className="material-icons">{isOpen === true ? 'close' : 'menu'}</i>
                        </button>
                        <Switch>
                            <Route path="/" component={Dashboard} exact={true} />
                            <Route path="/dashboard" component={Dashboard} />
                            <Route path="/analisis" component={Analysis} />
                            <Route path="/promotoria" component={Promo} />
                            <Route path="/ventas" render={props => <Sales {...props} userId={user.id} />} />
                            <Route path="/compras" component={Purchases} />
                            
                            <Route path="/movimientos" component={Moves} exact={true} />

                            <Route path="/almacen" component={Warehouse} exact={true} />
                            <Route path="/inventario" component={Inventory} exact={true} />
                            <Route path="/contabilidad" component={Accounting} />
                            <Route path="/costo" component={Cost} />
                            <Route path="/produccion" component={Production} />

                            <Route path="/recursos-humanos" component={HumanResources} />
                            <Route path="/calendario" component={Calendar} exact={true} />

                            <Route path="/media" component={Media} exact={true}/>
                            <Route path="/configuracion" component={Configuration} />
                            <Route path="/importer" component={Importer} />
                            <Route path="/importer-json" component={ImporterJson} />
                        </Switch>
                    </div> : <div className="app__container">
                        <button onClick={() => setIsOpen(!isOpen)}>
                            <i className="material-icons">{isOpen === true ? 'close' : 'menu'}</i>
                        </button>
                        <Switch>
                            <Route path="/" component={Dashboard} exact={true} />
                            <Route path="/dashboard" component={Dashboard} />
                            <Route path="/analisis" component={Analysis} />
                            <Route path="/promotoria" component={Promo} />
                            <Route path="/ventas" render={props => <Sales {...props} userId={user.id} isSeller={true} />} />
                            <Route path="/compras" component={Purchases} />

                            <Route path="/almacen" component={Warehouse} exact={true} />
                            <Route path="/inventario" component={Inventory} exact={true} />
                            <Route path="/contabilidad" component={Accounting} />
                            <Route path="/costo" component={Cost} />
                            <Route path="/produccion" component={Production} />

                            <Route path="/recursos-humanos" component={HumanResources} />
                            <Route path="/calendario" component={Calendar} exact={true} />

                            <Route path="/media" component={Media} exact={true}/>
                            <Route path="/configuracion" component={Configuration} />
                            <Route path="/importer" component={Importer} />
                            <Route path="/importer-json" component={ImporterJson} />
                        </Switch>
                    </div>
                }
            </main>
        </div>
    ) : <Loader/>

    async function logout() {
        await firebase.logout()
        props.history.push('/iniciar-sesion')
    }
}

export default App