import React, { useEffect, useState } from 'react'
import firebase from '../../../firebase'
import Select from 'react-select'

const Seller = (props) => {

    const [seller,setSeller] = useState(undefined)

    useEffect(() => {
        firebase.getDocumentOnce('users',props.id).then(snap => {
            setSeller(snap.data())
        })
    },[props.id])

    if (seller) {
        return (
            <span>{seller.name} - {seller.email}</span>
        )
    } else {
        return (
            <span></span>
        )
    }
}

const SalesSingleCustomerSeller = (props) => {

    const [sellers,setSellers] = useState([])
    const [selected,setSelected] = useState(undefined)
    const [selectedJunior,setSelectedJunior] = useState(undefined)

    useEffect(() => {
        firebase.getCollectionOnce('users').then(snap => {
            var data = []
            snap.docs.forEach(doc => {
                const item = doc.data()
                data.push({ label: item.name, value: item.id })
            })
            setSellers(data)
        })
    }, [])

    return (
        <div className="sales-single-customer-seller">
            <h3>Vendedor(es)</h3>
            <div className="container">
                <form onSubmit={e => e.preventDefault() && false}>
                    <ul>
                        <li>
                            <b>Vendedor Principal: </b>
                            {props.sellerId ? <Seller id={props.sellerId} /> : <span>Selecciona un vendedor</span>}
                        </li>
                    </ul>
                    <div className="input-container">
                        <label>Vendedor</label>
                        <Select
                            options={sellers}
                            value={selected}
                            onChange={setSelected}
                        />
                    </div>
                    <div className="input-container">
                        <button onClick={add} type="submit">Establecer Vendedor</button>
                    </div>
                </form>
                <form onSubmit={e => e.preventDefault() && false}>
                    <ul>
                        <li>
                            <b>Vendedor Junior: </b>
                            {props.sellerJuniorId ? <Seller id={props.sellerJuniorId} /> : <span>Selecciona un vendedor junior</span>}
                        </li>
                    </ul>
                    <div className="input-container">
                        <label>Vendedor</label>
                        <Select
                            options={sellers}
                            value={selectedJunior}
                            onChange={setSelectedJunior}
                        />
                    </div>
                    <div className="input-container">
                        <button onClick={addJunior} type="submit">Establecer Vendedor</button>
                    </div>
                </form>
            </div>
        </div>
    )

    async function addJunior () {
        try {
            console.log('Junior entró')
            firebase.update('customers',props.id,'sellerJuniorId',selectedJunior.value).then(() => {
                console.log('si')
            }).catch(error => {
                console.log(error)
            })
        } catch (error) {
            alert(error.message)
        }
    }

    async function add () {
        try {
            firebase.update('customers',props.id,'sellerId',selected.value)
        } catch (error) {
            alert(error.message)
        }
    }
}

export default SalesSingleCustomerSeller