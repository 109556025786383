import React from 'react'
import { Link, Route, Switch } from 'react-router-dom';

const Analysis = (props) => {
    const loc = props.location.pathname.split('/')[2];
    return (
        <div style={{ height:'90vh' }} className="analysis">
            <div className="in-nav">
                <ul>
                    <Link className={loc === undefined ? 'active' : ''} to="/analisis">Soriana</Link>
                    <Link className={loc === 'heb-mayor' ? 'active' : ''} to="/analisis/heb-mayor">HEB Mayor</Link>
                    <Link className={loc === 'heb-cholita' ? 'active' : ''} to="/analisis/heb-cholita">HEB Cholita</Link>
                    <Link className={loc === 'futurama' ? 'active' : ''} to="/analisis/futurama">Futurama</Link>
                </ul>
            </div>
            <Switch>
                <Route path="/analisis" render={() => <iframe title="Análisis" width="100%" height="100%" src="https://app.powerbi.com/view?r=eyJrIjoiYWZmNDM1ZmQtY2M4Zi00OWZlLTgzZjYtNmUwNDM5NTE3NTcyIiwidCI6IjM5ZjM5M2JiLTY3YjMtNDRmYy1iOGEyLTIzZjgzMmIyOTYwMyJ9" frameBorder="0" allowFullScreen={true}></iframe>} exact={true} />
                <Route path="/analisis/heb-mayor" render={() => <iframe title="Análisis" width="100%" height="100%" src="https://app.powerbi.com/view?r=eyJrIjoiMmMwNDI4Y2MtMWE4Ny00MWE3LWE1NDAtNzgxOGE3NTI2OTRjIiwidCI6IjM5ZjM5M2JiLTY3YjMtNDRmYy1iOGEyLTIzZjgzMmIyOTYwMyJ9" frameBorder="0" allowFullScreen={true}></iframe>} exact={true} />
                <Route path="/analisis/heb-cholita" render={() => <iframe title="Análisis" width="100%" height="100%" src="https://app.powerbi.com/view?r=eyJrIjoiMzExMTlmMGItMDExZi00MWY0LTgzMDEtNTlmMzNmNTFjNGU5IiwidCI6IjM5ZjM5M2JiLTY3YjMtNDRmYy1iOGEyLTIzZjgzMmIyOTYwMyJ9" frameBorder="0" allowFullScreen={true}></iframe>} exact={true} />
                <Route path="/analisis/futurama" render={() => <iframe title="Análisis" width="100%" height="100%" src="https://app.powerbi.com/view?r=eyJrIjoiYTYyM2E2MmQtMTNhMy00M2ViLWI0NDQtY2I4OGIxNmY2ZTMzIiwidCI6IjM5ZjM5M2JiLTY3YjMtNDRmYy1iOGEyLTIzZjgzMmIyOTYwMyJ9" frameBorder="0" allowFullScreen={true}></iframe>} exact={true} />
            </Switch>
        </div>
    )
}

export default Analysis