import React, { useEffect, useState } from 'react'
import firebase from '../../../firebase'

const Item = (props) => {
    const {data} = props
    return (
        <tr>
            <td>{data.id}</td>
            <td>{data.description}</td>
        </tr>
    )
}

const ConfigurationSatPayway = () => {

    const [satPayway, setSatPayway] = useState([])
    const [id,setId] = useState('')
    const [description,setDescription] = useState('')

    useEffect(() => {
        firebase.getCollectionRealTime('satPayway').onSnapshot(snap => {
            setSatPayway(snap.docs.map(doc => doc.data()))
        })
    }, [])
    return (
        <div className="configuration-sat-payway">
            <form onSubmit={e => e.preventDefault() && false}>
                <div className="input-container">
                    <label>Clave</label>
                    <input placeholder="Clave" value={id} onChange={e => setId(e.target.value)} type="text"/>
                </div>
                <div className="input-container">
                    <label>Descripción</label>
                    <input placeholder="Descripción" value={description} onChange={e => setDescription(e.target.value)} type="text"/>
                </div>
                <div className="input-container">
                    <button onClick={add} type="submit">Agregar</button>
                </div>
            </form>
            <table>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Object.keys(satPayway).map(key => <Item key={key} data={satPayway[key]} />)
                    }
                </tbody>
            </table>
        </div>
    )

    async function add() {
        try {
            await firebase.addSatPayway(id,description).then(() => {
                setId('')
                setDescription('')
            })
        } catch (error) {
            alert(error.message)
        }
    }
}

export default ConfigurationSatPayway