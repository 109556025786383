import React, { useState,useEffect } from 'react'
import firebase from '../../../firebase'
import { Link } from 'react-router-dom'

const Item = (props) => {
    const {data} = props

    return (
        <tr>
            <td>{data.name}</td>
            <td>{data.email}</td>
            <td>{data.rol}</td>
            <td><Link to={`/configuracion/usuarios/${data.id}`}><i className="material-icons">chevron_right</i></Link></td>
        </tr>
    )
}

const ConfigurationUsers = () => {

    const [users,setUsers] = useState([])

    useEffect(() => {
        firebase.getCollectionOnce('users').then(snap => {
            const users = snap.docs.map(doc => doc.data())
            setUsers(users)
        })
    }, [])

    return (
        <div className="configuration-users">
            <table>
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Correo</th>
                        <th>Rol</th>
                        <th>Ver</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Object.keys(users).map(key => <Item key={key} data={users[key]} />)
                    }
                </tbody>
            </table>
        </div>
    )
}

export default ConfigurationUsers