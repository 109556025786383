import React, {useState,useEffect} from 'react'
import moment from 'moment'

const Cost = () => {

    const [month,setMonth] = useState(undefined)

    useEffect(() => {
        const current = moment().format('MM-YYYY')
        setMonth(current)
    }, [])

    return (
        <div className="accounting-balance">
            <table>
                <thead>
                    <tr>
                        <th colSpan="7">
                            <div className="date-picker">
                                <button onClick={prevMonth}>
                                    <i className="material-icons">arrow_left</i>
                                </button>
                                <span>{month}</span>
                                <button onClick={nextMonth}>
                                    <i className="material-icons">arrow_right</i>
                                </button>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th>Código</th>
                        <th>Producto</th>
                        <th>Presentación</th>
                        <th>KG</th>
                        <th>Costo materia prima</th>
                        <th>Costo mano de obra</th>
                        <th>Empaque</th>
                    </tr>
                </thead>
            </table>
        </div>
    )

    async function nextMonth() {
        try {
            const newMonth = moment(month, 'MM-YYYY').add(1,'month').format('MM-YYYY')
            setMonth(newMonth)
        } catch (error) {
            alert(error.message)
        }
    }
    async function prevMonth() {
        try {
            const newMonth = moment(month, 'MM-YYYY').subtract(1,'month').format('MM-YYYY')
            setMonth(newMonth)
        } catch (error) {
            alert(error.message)
        }
    }
}

export default Cost