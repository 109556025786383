import React from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import ProductionForm from './production-form/ProductionForm';
import ProductionMp from './production-mp/ProductionMp';
import ProductionPp from './production-pp/ProductionPp';

const Production = (props) => {
    const loc = props.location.pathname.split('/')[2];
    return (
        <div className="production">
            <div className="in-nav">
                <ul>
                    <Link className={loc === undefined ? 'active' : 0} to="/produccion">Agregar Movimiento</Link>
                    <Link className={loc === 'materia-prima' ? 'active' : 0} to="/produccion/materia-prima">Materia Prima</Link>
                    <Link className={loc === 'producto-terminado' ? 'active' : 0} to="/produccion/producto-terminado">Producto Terminado</Link>
                </ul>
            </div>
            <Switch>
                <Route path="/produccion" component={ProductionForm} exact={true} />
                <Route path="/produccion/materia-prima" component={ProductionMp} exact={true} />
                <Route path="/produccion/producto-terminado" component={ProductionPp} exact={true} />
            </Switch>
        </div>
    )
}

export default Production