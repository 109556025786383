import React, { useState,useEffect } from 'react'
import firebase from '../../../firebase'

const Item = (props) => {
    const {data} = props

    return (
        <tr>
            <td className="center"><button><i className="material-icons">delete</i></button></td>
            <td>{data.title}</td>
            <td>{data.user}</td>
            <td>{data.password}</td>
            <td><a target="_blank" rel="noopener noreferrer" href={data.page}>{data.page}</a></td>
        </tr>
    )
}

const ConfigurationPasswords = () => {

    const [passwords,setPasswords] = useState([])

    useEffect(() => {
        firebase.getCollectionRealTime('passwords').onSnapshot(snap => {
            const passwords = snap.docs.map(doc => doc.data())
            setPasswords(passwords)
        })
    },[])

    return (
        <div className="configuration-passwords">
            <form>
                <div className="input-container">
                    <label>Título</label>
                    <input placeholder="Título" type="text"/>
                </div>
                <div className="input-container">
                    <label>Usuario</label>
                    <input placeholder="Usuario" type="text"/>
                </div>
                <div className="input-container">
                    <label>Contraseña</label>
                    <input placeholder="Contraseña" type="text"/>
                </div>
                <div className="input-container">
                    <label>Página web</label>
                    <input placeholder="Página web" type="text"/>
                </div>
                <div className="input-container">
                    <button type="submit">Agregar</button>
                </div>
            </form>
            <table>
                <thead>
                    <tr>
                        <th>Eliminar</th>
                        <th>Título</th>
                        <th>Usuario</th>
                        <th>Contraseña</th>
                        <th>Página web</th>
                    </tr>
                </thead>
                <tbody>{
                    Object.keys(passwords).map(key => <Item key={key} data={passwords[key]} />)
                }</tbody>
            </table>
        </div>
    )
}

export default ConfigurationPasswords