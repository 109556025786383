import React, { useState, useEffect } from 'react'
import firebase from '../../firebase'
import moment from 'moment'
import _ from 'lodash'

const ImporterJson = () => {

    const [val,setval] = useState('')
    const [products,setproducts] = useState([])
    const [customers,setcustomers] = useState([])
    const [newArray,setArray] = useState(undefined)

    useEffect(() => {
        firebase.getCollectionOnce('product-presentations').then(snap => {
            const data = snap.docs.map(doc => doc.data())
            setproducts(data)
        })
        firebase.getCollectionOnce('customers').then(snap => {
            const data = snap.docs.map(doc => doc.data())
            setcustomers(data)
        })
        // firebase.getCollectionOnce('providers').then(snap => {
        //     const data = snap.docs.map(doc => doc.data())
        //     setproviders(data)
        // })
    },[])

    return (
        <div className="importer-json">
            <form onSubmit={e => e.preventDefault() && false}>
                <div className="input-container">
                    <label>Json</label>
                    <textarea value={val} onChange={e => setval(e.target.value)} rows="20"></textarea>
                </div>
                <div className="input-container">
                    <button onClick={process} type="submit">Procesar</button>
                </div>
            </form>
            {
                newArray ? <div className="container">
                    <button onClick={submit}>Subir información</button>
                    <p style={{ whiteSpace:'pre' }}>{JSON.stringify(newArray,null,4)}</p>
                </div> : ''
            }
        </div>
    )

    async function submit () {
        try {
            newArray.forEach(item => {
                firebase.simpleAdd(item,'priceLists')
                // if (item.type === 'Ingreso') {
                //     firebase.simpleAdd(item,'incomes')
                // } else {
                //     firebase.simpleAdd(item,'expenses')
                // }
            })
        } catch (error) {
            alert(error.message)
        }
    }

    async function process () {
        try {
            const data = JSON.parse(val)
            var dat = []
            data.forEach(item => {
                const customer = _.find(customers, { name: item.customerName })
                item.customerId = customer ? customer.id : item.customerId
                var newProducts = []
                item.products.forEach(prod => {
                    var fullName = prod.name.split(' - ');
                    var selectedProduct = _.find(products, { productName: fullName[0], name: fullName[1] })
                    if (selectedProduct) {
                        selectedProduct.fixedCostDescription = prod.fixedCostDescription
                        selectedProduct.fixedCostValue = prod.fixedCostValue
                        selectedProduct.fullName = prod.name
                        selectedProduct.price = prod.price
                        newProducts.push(selectedProduct)
                    } else {
                        console.log('no')
                        newProducts.push(prod)
                    }
                })
                item.products = newProducts
                dat.push(item)
                // item.brandName = item.brandName ? _.upperCase(item.brandName) : 'EL MAYOR'
                // if (item.custProv === 'Nómina Casa Lohr') {
                //     dat.push(item)
                // } else {
                //     if (item.type === 'Ingreso') {
                //         const customer = _.find(customers, { name: item.custProv})
                //         if (customer) {
                //             item.customerId = customer.id
                //             item.customerName = customer.name
                //             dat.push(item)
                //         } else {
                //             dat.push(item)
                //         }
                //     } else {
                //         const provider = _.find(providers, { name: item.custProv })
                //         if (provider) {
                //             item.providerId = provider.id
                //             item.providerName = provider.name
                //             dat.push(item)
                //         } else {
                //             dat.push(item)
                //         }
                //     }
                // }
            })
            setArray(dat)
        } catch (error) {
            alert(error.message)
        }
    }
}

export default ImporterJson