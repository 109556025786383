import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import firebase from '../firebase';

const Registry = (props) => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [name, setName] = useState('')

    return (
        <div className="log-in">
            <form onSubmit={e => e.preventDefault() && false}>
                <h1>Registro</h1>
                <div className="input-container">
                    <label>Nombre</label>
                    <input value={name} placeholder="Nombre" autoComplete="off" type="text" onChange={e => setName(e.target.value)} />
                </div>
                <div className="input-container">
                    <label>Email</label>
                    <input value={email} placeholder="Email" autoComplete="off" type="email" onChange={e => setEmail(e.target.value)} />
                </div>
                <div className="input-container">
                    <label>Contraseña</label>
                    <input value={password} placeholder="Contraseña" autoComplete="off" type="password" onChange={e => setPassword(e.target.value)} />
                </div>
                <div className="input-container">
                    <button onClick={reg} type="submit">Entrar</button>
                </div>
                <Link to="/iniciar-sesion">Iniciar Sesión</Link>
            </form>
        </div>
    )

    async function reg() {
        try {
            await firebase.register(name,email,password)
            props.history.replace('/')
        } catch (error) {
            alert(error.message)
        }
    }
}

export default Registry;