import React from 'react'
import { Link, Route, Switch } from 'react-router-dom';

import HumanResourcesEmployees from './human-resources-employees/HumanResourcesEmployees';
import HumanResourcesAsistance from './human-resources-asistance/HumanResourcesAsistance';
import HumanResourcesRoster from './human-resources-roster/HumanResourcesRoster';

const HumanResources = (props) => {
    const loc = props.location.pathname.split('/')[2];
    return (
        <div className="human-resources">
            <div className="in-nav">
                <ul>
                    <Link className={loc === undefined || loc === 'empleados' ? 'active' : 0} to="/recursos-humanos">Empleados</Link>
                    <Link className={loc === 'asistencia' ? 'active' : 0} to="/recursos-humanos/asistencia">Retardos e inasistencias</Link>
                    <Link className={loc === 'nomina' ? 'active' : 0} to="/recursos-humanos/nomina">Nómina</Link>
                </ul>
            </div>
            <Switch>
                <Route path="/recursos-humanos" component={HumanResourcesEmployees} exact={true} />
                <Route path="/recursos-humanos/asistencia" component={HumanResourcesAsistance} exact={true} />
                <Route path="/recursos-humanos/nomina" component={HumanResourcesRoster} exact={true} />
            </Switch>
        </div>
    )
}

export default HumanResources