import React, {useState,useEffect} from 'react'
import firebase from '../../../firebase'
import Select from 'react-select'
import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'

const Item = props => {
    const {data} = props

    return (
        <tr>
            <td>{data.materialCode}</td>
            <td>{data.materialName} {data.name}</td>
            <td>{numeral(data.price).format('$0,0.00')}</td>
            <td>{data.quantity}</td>
            <td>{data.iva}</td>
            <td>{numeral(data.total).format('$0,0.00')}</td>
        </tr>
    )
}

const PurchasesQuoteForm = () => {

    const [brands,setbrands] = useState([])
    const [providers,setproviders] = useState([])
    const [materials,setmaterials] = useState([])
    const [list,setlist] = useState([])

    const [brand,setbrand] = useState(undefined)
    const [provider,setprovider] = useState(undefined)
    const [material,setmaterial] = useState(undefined)

    const [date,setdate] = useState('')
    const [comments,setcomments] = useState('')
    const [price,setprice] = useState('')
    const [quantity,setquantity] = useState('')
    const [iva,setiva] = useState('Si')

    useEffect(() => {
        firebase.getCollectionOnce('brands').then(snap => {
            var data = []
            snap.docs.forEach(doc => {
                const item = doc.data()
                data.push({ label: item.name, value: item.id })
            })
            setbrands(data)
        })
        firebase.getCollectionOnce('providers').then(snap => {
            var data = []
            snap.docs.forEach(doc => {
                const item = doc.data()
                data.push({ label: item.name, value: item.id, materials: item.materials })
            })
            setproviders(data)
        })
    },[])

    const total = _.reduce(list,(sum,n) => {
        return sum + n.total
    },0)

    return (
        <div className="purchases-quote-form">
            <div className="container">
                <div className="input-container">
                    <label>Fecha</label>
                    <input onChange={e => setdate(e.target.value)} value={date} type="date"/>
                </div>
                <div className="input-container">
                    <label>Marca</label>
                    <Select
                        options={brands}
                        value={brand}
                        onChange={setbrand}
                    />
                </div>
                <div className="input-container">
                    <label>Proveedor</label>
                    <Select
                        options={providers}
                        value={provider}
                        onChange={handleProvider}
                    />
                </div>
                <div className="input-container">
                    <label>Comentarios</label>
                    <input onChange={e => setcomments(e.target.value)} value={comments} placeholder="Comentarios" type="text"/>
                </div>
                <div className="content">
                    <form onSubmit={e => e.preventDefault() && false}>
                        <div className="input-container">
                            <label>Materiales / Servicios</label>
                            <Select
                                options={materials}
                                value={material}
                                onChange={setmaterial}
                            />
                        </div>
                        <div className="input-container">
                            <label>Precio</label>
                            <input onChange={e => setprice(e.target.value)} value={price} placeholder="Precio" type="number"/>
                        </div>
                        <div className="input-container">
                            <label>Cantidad</label>
                            <input onChange={e => setquantity(e.target.value)} value={quantity} placeholder="Cantidad" type="number"/>
                        </div>
                        <div className="input-container">
                            <label>¿Agregar IVA?</label>
                            <select onChange={e => setiva(e.target.value)} value={iva}>
                                <option value="Si">Si</option>
                                <option value="No">No</option>
                            </select>
                        </div>
                        <div className="input-container">
                            <button onClick={addMaterial} type="submit">Agregar</button>
                        </div>
                    </form>
                    <table>
                        <thead>
                            <tr>
                                <th>Código</th>
                                <th>Nombre</th>
                                <th>Precio</th>
                                <th>Cantidad</th>
                                <th>Iva</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>{
                            Object.keys(list).map(key => <Item key={key} data={list[key]} />)
                        }</tbody>
                        <tfoot>
                            <tr>
                                <th colSpan="4"></th>
                                <th>Total</th>
                                <th>{numeral(total).format('$0,0.00')}</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <button className="btn" onClick={add}>Agregar Cotización</button>
            </div>
        </div>
    )

    async function add () {
        try {
            const item = {
                brandId: brand.value,
                brandName: brand.label,
                providerId: provider.value,
                providerName: provider.label,
                materials: list,
                date: moment(date, 'YYYY-MM-DD').valueOf(),
                comments,
                v2: true,
                total,
                status: 'Pendiente'
            }
            firebase.simpleAdd(item,'quotations').then(() => {
                alert('Cotización agregada')
                setmaterials([])
                setlist([])
                setbrand(undefined)
                setprovider(undefined)
                setmaterial(undefined)
                setdate('')
                setcomments('')
                setprice('')
                setquantity('')
                setiva('Si')
            })
        } catch (error) {
            alert(error.message)
        }
    }

    async function addMaterial () {
        try {
            var item = material.data
            item.price = Number(price)
            item.quantity = Number(quantity)
            item.iva = iva
            item.total = (Number(price)*Number(quantity))*(iva === 'Si' ? 1.16 : 1)
            var newlist = [...list]
            newlist.push(item)
            setlist(newlist)
        } catch (error) {
            alert(error.message)
        }
    }

    async function handleProvider (e) {
        try {
            setprovider(e)
            var data = []
            e.materials.forEach(mat => {
                data.push({ label: `${mat.materialName} - ${mat.name}`, value: mat.id, data: mat })
            })
            setmaterials(data)
        } catch (error) {
            alert(error.message)
        }
    }
}

export default PurchasesQuoteForm