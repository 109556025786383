import React from 'react'
import { Switch, Route, Link } from 'react-router-dom'
import PromoMain from './PromoMain';
import PromoList from './PromoList';


const Promo = (props) => {

    const loc = props.location.pathname.split('/')[2];

    return (
        <div className="promo">
            <div className="in-nav">
                <ul>
                    <Link className={loc === undefined ? 'active' : 0} to="/promotoria">Crear Reporte</Link>
                    <Link className={loc === 'lista' ? 'active' : 0} to="/promotoria/lista">Lista de Reportes</Link>
                </ul>
            </div>
            <Switch>
                <Route path="/promotoria" component={PromoMain} exact={true} />
                <Route path="/promotoria/lista" component={PromoList} exact={true} />
            </Switch>
        </div>
    )
}

export default Promo