import React, { useState,useEffect } from 'react'
import firebase from '../../firebase'
import numeral from 'numeral'
import _ from 'lodash'

const Item = (props) => {
    const {data} = props
    return (
        <tr>
            <td>{data.materialCode}</td>
            <td>{data.materialProduct} {data.materialName} {data.materialDescription}</td>
            <td>{numeral(data.quantity).format('0.00')}</td>
            {/* <td><input value={data.quantity} onChange={e => firebase.update('material-presentations',data.id,'quantity',Number(e.target.value))} type="number"/></td> */}
            <td>{numeral(data.um).format('0.00')}</td>
            {/* <td><input value={data.um} onChange={e => firebase.update('material-presentations',data.id,'um',Number(e.target.value))} type="number"/></td> */}
        </tr>
    )
}

const Inventory = () => {

    const [presentations,setPresentations] = useState([])
    const [seeker,setSekker] = useState('')

    useEffect(() => {
        firebase.getCollectionOnce('material-presentations').then(snap => {
            setPresentations(snap.docs.map(doc => doc.data()))
        })
    }, [])

    const search = seeker ? _.filter(presentations, item => {
        return _.lowerCase(item.materialName + ' ' + item.materialDescription + ' ' + item.name + ' ' + item.materialProduct + ' ' + item.brand + ' ' + item.productCode).match(_.lowerCase(seeker))
    }) : presentations

    return (
        <div className="warehouse">
            <div className="seeker">
                <span><i className="material-icons">search</i></span>
                <input placeholder="Buscar ..." value={seeker} onChange={e => setSekker(e.target.value)} type="text"/>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Código</th>
                        <th>Producto</th>
                        <th>Cantidad</th>
                        <th>Kilogramos / Litros</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Object.keys(search).map(key => <Item key={key} data={search[key]} />)
                    }
                </tbody>
            </table>
        </div>
    )
}

export default Inventory