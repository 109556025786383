import React, { useState,useEffect } from 'react'
import firebase from '../../../firebase'
import { Link } from 'react-router-dom'
import { async } from '@firebase/util';

const Item = (props) => {
    const {data} = props

    return (
        <tr>
            <td className="center"><button onClick={() => firebase.delete('employees',data.id,data) }><i className="material-icons">delete</i></button></td>
            <td>{data.name}</td>
            <td>{data.job}</td>
            <td>{data.salary}</td>
            <td className="center"><Link to={`/recursos-humanos/empleados/${data.id}`}><i className="material-icons">chevron_right</i></Link></td>
        </tr>
    )
}

const HumanResourcesEmployees = () => {

    const [employees,setEmployees] = useState([])
    const [name,setname] = useState('')
    const [salary,setsalary] = useState('')
    const [job,setjob] = useState('')

    useEffect(() => {
        firebase.getCollectionOnce('employees').then(snap => {
            const employees = snap.docs.map(doc => doc.data())
            setEmployees(employees)
        })
    },[])
    return (
        <div className="human-resources-employees">
            <form onSubmit={e => e.preventDefault() && false}>
                <div className="input-container">
                    <label>Nombre</label>
                    <input onChange={e => setname(e.target.value)} value={name} placeholder="Nombre" type="text"/>
                </div>
                <div className="input-container">
                    <label>Sueldo por mes</label>
                    <input onChange={e => setsalary(e.target.value)} value={salary} placeholder="Sueldo por mes" type="number"/>
                </div>
                <div className="input-container">
                    <label>Puesto</label>
                    <input onChange={e => setjob(e.target.value)} value={job} placeholder="Puesto" type="text"/>
                </div>
                <div className="input-container">
                    <button onClick={add} type="submit">Agregar</button>
                </div>
            </form>

            <table>
                <thead>
                    <tr>
                        <th>Eliminar</th>
                        <th>Nombre</th>
                        <th>Puesto</th>
                        <th>Sueldo por mes</th>
                        <th>Ver</th>
                    </tr>
                </thead>
                <tbody>{
                    Object.keys(employees).map(key => <Item key={key} data={employees[key]} />)
                }</tbody>
            </table>
        </div>
    )
    
    async function add () {
        try {
            const item = {
                name,
                salary: Number(salary),
                job,
            }
            firebase.simpleAdd(item,'employees').then(() => {
                setname('')
                setsalary('')
                setjob('')
            })
        } catch (error) {
            alert(error.message)
        }
    }
}

export default HumanResourcesEmployees