import React, { useState, useEffect } from 'react'
import firebase from '../../../firebase'

const Item = (props) => {
    const {data} = props
    return (
        <tr>
            <td className="center">
                <button onClick={() => firebase.delete('directory',data.id,data)}>
                    <i className="material-icons">delete</i>
                </button>
            </td>
            <td>{data.name}</td>
            <td>{data.phone}</td>
            <td>{data.email}</td>
            <td>{data.comments}</td>
        </tr>
    )
}

const SalesSingleCustomerDirectory = (props) => {

    const [name,setname] = useState('')
    const [phone,setphone] = useState('')
    const [email,setemail] = useState('')
    const [comments,setcomments] = useState('')
    const [directory,setdirectory] = useState([])

    useEffect(() => {
        firebase.getDirectory(props.id).onSnapshot(snap => {
            setdirectory(snap.docs.map(doc => doc.data()))
        })
    }, [props.id])

    return (
        <div className="sales-single-customer-directory">
            <form onSubmit={e => e.preventDefault() && false}>
                <div className="input-container">
                    <label>Nombre</label>
                    <input onChange={e => setname(e.target.value)} value={name} type="text"/>
                </div>
                <div className="input-container">
                    <label>Teléfono</label>
                    <input onChange={e => setphone(e.target.value)} value={phone} type="text"/>
                </div>
                <div className="input-container">
                    <label>Email</label>
                    <input onChange={e => setemail(e.target.value)} value={email} type="text"/>
                </div>
                <div className="input-container">
                    <label>Comentarios</label>
                    <input onChange={e => setcomments(e.target.value)} value={comments} type="text"/>
                </div>
                <div className="input-container">
                    <button onClick={add} type="submit">Agregar</button>
                </div>
            </form>
            <table>
                <thead>
                    <tr>
                        <th>Eliminar</th>
                        <th>Nombre</th>
                        <th>Teléfono</th>
                        <th>Email</th>
                        <th>Comentarios</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Object.keys(directory).map(key => <Item key={key} data={directory[key]} />)
                    }
                </tbody>
            </table>
        </div>
    )
    async function add() {
        try {
            const item = {
                name,
                phone,
                email,
                comments,
                customerId: props.id
            }
            firebase.simpleAdd(item,'directory').then(() => {
                setname('')
                setphone('')
                setemail('')
                setcomments('')
            })
        } catch (error) {
            alert(error.message)
        }
    }
}

export default SalesSingleCustomerDirectory