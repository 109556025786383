import React, { useState,useEffect } from 'react'
import firebase from '../../../firebase'
import numeral from 'numeral'
import moment from 'moment'

const SalesSingleOrder = (props) => {

    const [order,setOrder] = useState({})

    useEffect(() => {
        firebase.getDocumentRealTime('orders',props.match.params.id).onSnapshot(snap => {
            const order = snap.data()
            setOrder(order)
        })
    },[])

    console.log(order.products)

    return (
        <div className="sales-single-order">
            <h1>Id Externo: {order.externalId}</h1>
            <section>
                <h2>Datos Generales</h2>
                <ul>
                    <li><b>Marca: </b>{order.brandName}</li>
                    <li><b>Lista de Precios: </b>{order.priceListName}</li>
                    <li><b>ID Interno: </b>{order.id}</li>
                    <li><b>Fecha Inicio EMB: </b>{moment(order.dateStartEmb).format('DD-MM-YYYY')}</li>
                    <li><b>Fecha Fin EMB: </b>{moment(order.dateEndEmb).format('DD-MM-YYYY')}</li>
                    <li><b>Fecha de Pedido: </b>{moment(order.date).format('DD-MM-YYYY')}</li>
                    <li><b>Departamento: </b>{order.department}</li>
                    <li><b>Tipo de Surtido: </b>{order.assortmentType}</li>
                    <li><b>Comentarios: </b>{order.comments}</li>
                </ul>
            </section>
            <section>
                <h2>Datos del Cliente</h2>
                <ul>
                    <li><b>Nombre: </b>{order.customerName}</li>
                    <li><b>Email: </b>{order.customerEmail}</li>
                    <li><b>Teléfono: </b>{order.customerPhone}</li>
                    <li><b>Dirección: </b>{order.customerAddress ? `${order.customerAddress.street} ${order.customerAddress.outNumber} - ${order.customerAddress.inNumber}, ${order.customerAddress.colony}, ${order.customerAddress.town}. ${order.customerAddress.estate}. CP ${order.customerAddress.postalCode}` : ''}</li>
                    <li><b>Tipo: </b>{order.customerType === 'modal-person' ? 'Persona Moral' : 'Persona Física'}</li>
                    <li><b>RFC: </b>{order.customerRfc}</li>
                    <li><b>Uso de CFDI: </b>{order.customerCfdiUse}</li>
                    <li><b>Termino de Pago: </b>{order.customerPayTerm === 'credit' ? 'Crédito' : 'Contado'}</li>
                    <li><b>Días de Crédito: </b>{order.customerCreditDays}</li>
                    <li><b>Metodo de Pago SAT: </b>{order.customerSatPayMethod}</li>
                    <li><b>Forma de Pago SAT: </b>{order.customerSatPayway}</li>
                </ul>
            </section>
            <section>
                <h2>Pedido</h2>
                <table>
                    <thead>
                        <tr>
                            <th>Código</th>
                            <th>Nombre</th>
                            <th>Precio</th>
                            <th>Cantidad</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {order.products ? 
                            Object.keys(order.products).map(key => <tr key={key}>
                                <td>{order.products[key].code ? order.products[key].code : order.products[key].productCode}</td>
                                <td>{order.products[key].productName} {order.products[key].name}</td>
                                <td>{numeral(order.products[key].price).format('$0,0.00')}</td>
                                <td>{order.products[key].quantity}</td>
                                <td>{numeral(order.products[key].total ? order.products[key].total : order.products[key].price * order.products[key].quantity).format('$0,0.00')}</td>
                            </tr>) : <tr></tr>
                        }
                    </tbody>
                </table>
            </section>
            <section>
                <table>
                    <tbody>
                        <tr>
                            <th><b>Total</b></th>
                            <th>{numeral(order.total).format('$0,0.00')}</th>
                        </tr>
                    </tbody>
                </table>
                <div className="input-container">
                    <label>Estatus de Pedido</label>
                    <select value={order.status} onChange={e => firebase.update('orders',props.match.params.id,'status',Number(e.target.value))}>
                        <option value="1">Pendiente</option>
                        <option value="2">Prosesada</option>
                        <option value="3">Enviada</option>
                        <option value="4">Entregada</option>
                        <option value="0">Cancelada</option>
                    </select>
                </div>
            </section>
        </div>
    )
}

export default SalesSingleOrder