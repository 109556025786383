import React, { useState,useEffect } from 'react'
import firebase from '../../firebase'
import Select from 'react-select'
import numeral from 'numeral'
import * as XLSX from 'xlsx'
import moment from 'moment'
import _ from 'lodash'

const Product = (props) => {

    const {details} = props
    const {data} = props

    return (
        <tr>
            <td>{moment(data.date).format('DD-MM-YYYY')}</td>
            <td>{data.customer}</td>
            <td>{data.store}</td>
            <td>{details.name}</td>
            <td>{numeral(details.price).format('$0,0.00')}</td>
            <td>{details.status}</td>
            <td>{details.isTimedOut}</td>
            <td>{details.fronts}</td>
            {Number(props.index) === 0 ? <td rowSpan="21">{data.comments}</td> : ''}
            {Number(props.index) === 0 && data.img1 ? <td rowSpan="21"><img style={{ width:'10rem' }} src={`https://drive.google.com/uc?export=view&id=` + (data.img1.split('=').pop())} alt={data.store}/></td> : ''}
            {Number(props.index) === 0 && data.img2 ? <td rowSpan="21"><img style={{ width:'10rem' }} src={`https://drive.google.com/uc?export=view&id=` + (data.img2.split('=').pop())} alt={data.store}/></td> : ''}
            {Number(props.index) === 0 && data.img3 ? <td rowSpan="21"><img style={{ width:'10rem' }} src={`https://drive.google.com/uc?export=view&id=` + (data.img3.split('=').pop())} alt={data.store}/></td> : ''}
            {Number(props.index) === 0 && data.img4 ? <td rowSpan="21"><img style={{ width:'10rem' }} src={`https://drive.google.com/uc?export=view&id=` + (data.img4.split('=').pop())} alt={data.store}/></td> : ''}
        </tr>
    )
}

const Item = (props) => {

    const {data} = props
    const lookup = _.keyBy(props.selectedProduct, o => {
        return o.value
    })

    const products = !_.isEmpty(props.selectedProduct) ? _.filter(data.products, o => {
        return lookup[o.name] !== undefined
    }) : data.products

    return (
        <tbody>
            {
                Object.keys(products).map(key => <Product index={key} key={key} details={products[key]} data={data} />)
            }
        </tbody>
    )
}

const PromoMain = () => {

    const [file,setFile] = useState(undefined)
    const [data,setData] = useState([])
    const [selectedDate,setSelectedDate] = useState('false')
    const [selectedCustomer,setSelectedCustomer] = useState('false')
    const [selectedStore,setSelectedStore] = useState('false')
    const [selectedProduct,setSelectedProduct] = useState([])
    const [reportName,setReportName] = useState('')

    const uniqDates = _.uniqBy(data, 'date')
    const uniqCustomer = _.unionBy(data, 'customer')
    const uniqStore = _.uniqBy(data, 'store')
    const uniqProduct = _.uniqBy(data[0] ? data[0].products : [], 'name').map(item => {
        var newItem = {}
        newItem.label = item.name
        newItem.value = item.name
        return newItem
    })

    const filteredDate = selectedDate !== 'false' ? _.filter(data, o => {
        return Number(o.date) === Number(selectedDate)
    }) : data
    const filteredCustomer = selectedCustomer !== 'false' ? _.filter(filteredDate, { customer: selectedCustomer }) : filteredDate
    const filteredStore = selectedStore !== 'false' ? _.filter(filteredCustomer, { store: selectedStore }) : filteredCustomer

    return (
        <div className="promo-main">
            <form onSubmit={e => e.preventDefault() && false}>
                <div className="input-container">
                    <label>Excel</label>
                    <input onChange={e => setFile(e.target.files[0])} type="file" />
                </div>
                <div className="input-container">
                    <button onClick={readXlsx}>Procesar</button>
                </div>
            </form>
            <div className="filter">
                <ul>
                    <li>
                        <div className="input-container">
                            <label>Fecha</label>
                            <select value={selectedDate} onChange={e => setSelectedDate(e.target.value)}>
                                <option value="false">** Selecciona una opción **</option>
                                {
                                    Object.keys(uniqDates).map(key => <option key={key} value={uniqDates[key].date}>{moment(uniqDates[key].date).format('DD-MM-YYYY')}</option>)
                                }
                            </select>
                        </div>
                    </li>
                    <li>
                        <div className="input-container">
                            <label>Cliente</label>
                            <select value={selectedCustomer} onChange={e => setSelectedCustomer(e.target.value)}>
                                <option value="false">** Selecciona una opción **</option>
                                {
                                    Object.keys(uniqCustomer).map(key => <option key={key} value={uniqCustomer[key].customer}>{uniqCustomer[key].customer}</option>)
                                }
                            </select>
                        </div>
                    </li>
                    <li>
                        <div className="input-container">
                            <label>Tienda</label>
                            <select value={selectedStore} onChange={e => setSelectedStore(e.target.value)}>
                                <option value="false">** Selecciona una opción **</option>
                                {
                                    Object.keys(uniqStore).map(key => <option key={key} value={uniqStore[key].store}>{uniqStore[key].store}</option>)
                                }
                            </select>
                        </div>
                    </li>
                    <li>
                        <div className="input-container">
                            <label>Producto</label>
                            <Select
                                value={selectedProduct}
                                onChange={e => setSelectedProduct(e)}
                                options={uniqProduct}
                                isMulti
                            />
                        </div>
                    </li>
                </ul>
            </div>
            <table>
                <thead>
                    <tr>
                        <td colSpan="13">
                            <form onSubmit={e => e.preventDefault() && false}>
                                <div className="input-container">
                                    <label>Nombre de Reporte</label>
                                    <input value={reportName} onChange={e => setReportName(e.target.value)} type="text"/>
                                </div>
                                <div className="input-container">
                                    <button onClick={add}>Crear Reporte</button>
                                </div>
                            </form>
                        </td>
                    </tr>
                    <tr>
                        <th>Fecha</th>
                        <th>Cliente</th>
                        <th>Tienda</th>
                        <th>Producto</th>
                        <th>Precio</th>
                        <th>Estatus</th>
                        <th>¿Hay caducados?</th>
                        <th>Frentes</th>
                        <th>Comentarios</th>
                        <th>Imagen 1</th>
                        <th>Imagen 2</th>
                        <th>Imagen 3</th>
                        <th>Imagen 4</th>
                    </tr>
                </thead>
                {
                    Object.keys(filteredStore).map(key => <Item key={key} selectedProduct={selectedProduct} data={filteredStore[key]} />)
                }
            </table>
        </div>
    )

    async function add() {
        try {
            var item = {
                data: JSON.stringify(filteredStore),
                selectedProduct,
                name: reportName
            }
            firebase.simpleAdd(item,'promo-reports').then(() => {
                alert('Reporte creado')
            })
        } catch (error) {
            alert(error.message)
        }
    }

    async function readXlsx() {
        try {
            const reader = new FileReader();
            const firstProm = (evt) => {
                return new Promise(resolve => {
                    reader.onload = (evt) => {
                        const bstr = evt.target.result;
                        const wb = XLSX.read(bstr, { type: 'binary' });
                        const wsname = wb.SheetNames[0];
                        const ws = wb.Sheets[wsname];
            
                        const data = XLSX.utils.sheet_to_json(ws, { header: 1 })
                        var array = []
                        data.forEach(item => {
                            const space = {
                                store: item[0],
                                customer: item[1],
                                region: item[2],
                                date: Math.round((item[3] - 25568)*86400*1000),
                                products: [
                                    {
                                        name: 'MOLE CON AJONJOLI GRANEL',
                                        price: (item[4]) ? (item[4]) : '',
                                        status: item[25] ? item[25] : '',
                                        isTimedOut: item[46] ? item[46] : ''
                                    },
                                    {
                                        name: 'MOLE ALMENDRADO GRANEL',
                                        price: (item[5]) ? (item[5]) : '',
                                        status: item[26] ? item[26] : '',
                                        isTimedOut: item[47] ? item[47] : ''
                                    },
                                    {
                                        name: 'MOLE POBLANO GRANEL',
                                        price: (item[6]) ? (item[6]) : '',
                                        status: item[27] ? item[27] : '',
                                        isTimedOut: item[48] ? item[48] : ''
                                    },
                                    {
                                        name: 'MOLE DULCE GRANEL',
                                        price: (item[7]) ? (item[7]) : '',
                                        status: item[28] ? item[28] : '',
                                        isTimedOut: item[49] ? item[49] : ''
                                    },
                                    {
                                        name: 'MOLE OAXACA GRANEL',
                                        price: (item[8]) ? (item[8]) : '',
                                        status: item[29] ? item[29] : '',
                                        isTimedOut: item[50] ? item[50] : ''
                                    },
                                    {
                                        name: 'MOLE CON CAMARON GRANEL',
                                        price: (item[9]) ? (item[9]) : '',
                                        status: item[30] ? item[30] : '',
                                        isTimedOut: item[51] ? item[51] : ''
                                    },
                                    {
                                        name: 'MOLE CON AJONJOLI 500 GRAMOS',
                                        price: (item[10]) ? (item[10]) : '',
                                        status: item[31] ? item[31] : '',
                                        isTimedOut: item[52] ? item[52] : '',
                                        fronts: item[67] ? item[67] : '',
                                    },
                                    {
                                        name: 'MOLE ALMENDRADO 500 GRAMOS',
                                        price: (item[11]) ? (item[11]) : '',
                                        status: item[32] ? item[32] : '',
                                        isTimedOut: item[53] ? item[53] : '',
                                        fronts: item[68] ? item[68] : ''
                                    },
                                    {
                                        name: 'MOLE POBLANO 500 GRAMOS',
                                        price: (item[12]) ? (item[12]) : '',
                                        status: item[33] ? item[33] : '',
                                        isTimedOut: item[54] ? item[54] : '',
                                        fronts: item[69] ? item[69] : ''
                                    },
                                    {
                                        name: 'MOLE DULCE 500 GRAMOS',
                                        price: (item[13]) ? (item[13]) : '',
                                        status: item[34] ? item[34] : '',
                                        isTimedOut: item[55] ? item[55] : '',
                                        fronts: item[70] ? item[70] : ''
                                    },
                                    {
                                        name: 'MOLE OAXACA 500 GRAMOS',
                                        price: (item[14]) ? (item[14]) : '',
                                        status: item[35] ? item[35] : '',
                                        isTimedOut: item[56] ? item[56] : '',
                                        fronts: item[71] ? item[71] : ''
                                    },
                                    {
                                        name: 'MOLE PICOSO 500 GRAMOS',
                                        price: (item[15]) ? (item[15]) : '',
                                        status: item[36] ? item[36] : '',
                                        isTimedOut: item[57] ? item[57] : '',
                                        fronts: item[72] ? item[72] : ''
                                    },
                                    {
                                        name: 'MOLE CON CAMARON 500 GRAMOS',
                                        price: (item[16]) ? (item[16]) : '',
                                        status: item[37] ? item[37] : '',
                                        isTimedOut: item[58] ? item[58] : ''
                                    },
                                    {
                                        name: 'PIPIAN ROJO 500 GRAMOS',
                                        price: (item[17]) ? (item[17]) : '',
                                        status: item[38] ? item[38] : '',
                                        isTimedOut: item[59] ? item[59] : '',
                                        fronts: item[73] ? item[73] : ''
                                    },
                                    {
                                        name: 'CONSOME DE POLLO GRANEL',
                                        price: (item[18]) ? (item[18]) : '',
                                        status: item[39] ? item[39] : '',
                                        isTimedOut: item[60] ? item[60] : ''
                                    },
                                    {
                                        name: 'CONSOME DE POLLO CON TOMATE GRANEL',
                                        price: (item[19]) ? (item[19]) : '',
                                        status: item[40] ? item[40] : '',
                                        isTimedOut: item[61] ? item[61] : ''
                                    },
                                    {
                                        name: 'CONSOME DE POLLO 500 GRAMOS',
                                        price: (item[20]) ? (item[20]) : '',
                                        status: item[41] ? item[41] : '',
                                        isTimedOut: item[62] ? item[62] : '',
                                        fronts: item[74] ? item[74] : ''
                                    },
                                    {
                                        name: 'MOLE CON AJONJOLI 250 GRAMOS',
                                        price: (item[21]) ? (item[21]) : '',
                                        status: item[42] ? item[42] : '',
                                        isTimedOut: item[63] ? item[63] : '',
                                        fronts: item[75] ? item[75] : ''
                                    },
                                    {
                                        name: 'MOLE ALMENDRADO 250 GRAMOS',
                                        price: (item[22]) ? (item[22]) : '',
                                        status: item[43] ? item[43] : '',
                                        isTimedOut: item[64] ? item[64] : '',
                                        fronts: item[76] ? item[76] : ''
                                    },
                                    {
                                        name: 'MOLE POBLANO 250 GRAMOS',
                                        price: (item[23]) ? (item[23]) : '',
                                        status: item[44] ? item[44] : '',
                                        isTimedOut: item[65] ? item[65] : ''
                                    },
                                    {
                                        name: 'CONSOME DE POLLO 250 GRAMOS',
                                        price: (item[24]) ? (item[24]) : '',
                                        status: item[45] ? item[45] : '',
                                        isTimedOut: item[66] ? item[66] : '',
                                        fronts: item[77] ? item[77] : ''
                                    },
                                ],
                                img1: item[78],
                                img2: item[79],
                                img3: item[80],
                                img4: item[81],
                                comments: item[82]
                            }
                            array.push(space)
                        })
                        resolve(array)
                    }
                })
            }
            firstProm(reader.readAsBinaryString(file)).then(list => {
                setData(list)
            })
        } catch (error) {
            alert(error.message)
        }
    }
}

export default PromoMain