import React, { useState,useEffect } from 'react'
import firebase from '../../../firebase'
import Select from 'react-select'
import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'

const Item = (props) => {
    const {data} = props

    return (
        <tr>
            <td><button onClick={() => props.remove(props.index)}><i className="material-icons">delete</i></button></td>
            <td>{data.productCode ? data.productCode : data.code}</td>
            <td>{data.fullName ? data.fullName : data.name}</td>
            <td>{data.quantity}</td>
            <td>{numeral(data.price).format('$0,0.00')}</td>
            <td>{numeral(data.price*data.quantity).format('$0,0.00')}</td>
        </tr>
    )
}

const SalesOrdersForm = (props) => {

    const [brands,setbrands] = useState([])
    const [customers,setcustomers] = useState([])
    const [priceList,setpriceList] = useState([])
    const [brand,setbrand] = useState(undefined)
    const [customer,setcustomer] = useState(undefined)
    const [selectedPriceList,setselectedPricelist] = useState(undefined)
    const [products,setProducts] = useState([])
    const [dateStartEmb,setdateStartEmb] = useState('')
    const [dateEndEmb,setdateEndEmb] = useState('')
    const [date,setdate] = useState('')
    const [externalId,setexternalId] = useState('')
    const [department,setdepartment] = useState('')
    const [assortmentType,setassortmentType] = useState('')
    const [comments,setcomments] = useState('')

    const [selectedProduct,setselectedProduct] = useState('false')
    const [quantity,setQuantity] = useState('')

    useEffect(() => {
        firebase.getCollectionOnce('brands').then(snap => {
            var data = []
            snap.docs.forEach(doc => {
                const item = doc.data()
                data.push({ label: item.name, value: item.id })
            })
            setbrands(data)
        })
        firebase.getCollectionOnce('customers').then(snap => {
            var data = []
            snap.docs.forEach(doc => {
                const item = doc.data()
                data.push({ label: item.name, value: item.id, data: item })
            })
            setcustomers(data)
        })
    }, [])

    return (
        <div className="sales-orders-form">
            <div className="container">
                <div className="input-container">
                    <label>Fecha Inicio Emb</label>
                    <input onChange={e => setdateStartEmb(moment(e.target.value,'YYYY-MM-DD').valueOf())} value={moment(dateStartEmb).format('YYYY-MM-DD')} type="date" />
                </div>
                <div className="input-container">
                    <label>Fecha Fin Emb</label>
                    <input onChange={e => setdateEndEmb(moment(e.target.value,'YYYY-MM-DD').valueOf())} value={moment(dateEndEmb).format('YYYY-MM-DD')} type="date" />
                </div>
                <div className="input-container">
                    <label>Fecha de Pedido</label>
                    <input onChange={e => setdate(moment(e.target.value,'YYYY-MM-DD').valueOf())} value={moment(date).format('YYYY-MM-DD')} type="date" />
                </div>
                <div className="input-container">
                    <label>ID externo</label>
                    <input onChange={e => setexternalId(e.target.value)} value={externalId} placeholder="ID externo" type="text"/>
                </div>
                <div className="input-container">
                    <label>Departamento</label>
                    <input onChange={e => setdepartment(e.target.value)} value={department} placeholder="Departamento" type="text"/>
                </div>
                <div className="input-container">
                    <label>Tipo de Surtido</label>
                    <input onChange={e => setassortmentType(e.target.value)} value={assortmentType} placeholder="Tipo de Surtido" type="text"/>
                </div>
                <div className="input-container">
                    <label>Comentarios</label>
                    <textarea onChange={e => setcomments(e.target.value)} value={comments} placeholder="Comentarios" type="text"/>
                </div>
                <div className="input-container">
                    <label>Marca / Empresa</label>
                    <Select
                        options={brands}
                        onChange={setbrand}
                        value={brand}
                    />
                </div>
                <div className="input-container">
                    <label>Cliente</label>
                    <Select
                        options={customers}
                        onChange={handleCustomer}
                        value={customer}
                    />
                </div>
                <div style={{ flex: '0 0 100%' }} className="input-container">
                    <label>Lista de Precios</label>
                    <Select
                        options={priceList}
                        onChange={setselectedPricelist}
                        value={selectedPriceList}
                    />
                </div>
                {
                    selectedPriceList ? <form onSubmit={e => e.preventDefault() && false}>
                        <div className="input-container">
                            <label>Producto</label>
                            <Select
                                options={selectedPriceList.prices}
                                value={selectedProduct}
                                onChange={setselectedProduct}
                            />
                        </div>
                        <div className="input-container">
                            <label>Cantidad</label>
                            <input value={quantity} onChange={e => setQuantity(e.target.value)} type="number"/>
                        </div>
                        <div className="input-container">
                            <button onClick={handleProduct} type="submit">Agregar</button>
                        </div>
                    </form> : ''
                }

                {
                    selectedPriceList ? <table>
                        <thead>
                            <tr>
                                <th>Borrar</th>
                                <th>Código</th>
                                <th>Producto</th>
                                <th>Cantidad</th>
                                <th>Precio</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>{
                            Object.keys(products).map(key => <Item remove={remove} index={key} key={key} data={products[key]} />)
                        }</tbody>
                    </table> : ''
                }

                <button onClick={add} className="submit">Agregar Pedido</button>
            </div>
        </div>
    )

    async function remove(index) {
        try {
            var newArr = [...products]
            delete newArr[index]
            setProducts(newArr)
        } catch (error) {
            alert(error.message)
        }
    }

    async function add () {
        try {
            const newProds = _.filter(products, o => {
                return o
            })
            const total = _.reduce(newProds, (sum,n) => {
                return sum + (n.price*n.quantity)
            },0)
            const item = {
                dateStartEmb,
                dateEndEmb,
                brandId: brand.value,
                brandName: brand.label,
                externalId: externalId,
                date: date,
                assortmentType: assortmentType,
                comments: comments,
                department: department,
                products: newProds,
                status: 1,
                priceListName: selectedPriceList.label,
                priceListId: selectedPriceList.value,
                total: total,
                customerId: customer.data.id,
                customerName: customer.data.name,
                customerEmail: customer.data.email,
                customerPhone: customer.data.phone,
                customerType: customer.data.type,
                customerRfc: customer.data.rfc,
                customerCfdiUse: customer.data.cfdiUse,
                customerPayTerm: customer.data.payTerm,
                customerCreditDays: customer.data.creditDays,
                customerSatPayMethod: customer.data.satPayMethod,
                customerSatPayway: customer.data.payway,
                paymentStatus: 'pending'
            }
            firebase.simpleAdd(item,'orders').then(() => {
                alert('Pedido agregado')
                window.location.reload(false)
            })
        } catch (error) {
            alert(error.message)
        }
    }

    async function handleProduct () {
        try {
            var item = selectedProduct.data
            item.quantity = Number(quantity)
            var newProducts = products ? products : []
            newProducts.push(item)
            setProducts(newProducts)
            setselectedProduct('')
            setQuantity('')
        } catch (error) {
            alert(error.message)
        }
    }

    async function handleCustomer (e) {
        try {
            setcustomer(e)
            firebase.getCollectionRealTime('priceLists').where('customerId','==',e.value).get().then(snap => {
                var priceLists = []
                snap.docs.forEach(doc => {
                    const item = doc.data()
                    var dat = []
                    item.products.forEach(prod => {
                        dat.push({ label: prod.fullName ? prod.fullName : prod.name, value: prod.id, data: prod })
                    })
                    item.prices = dat
                    priceLists.push({ label: item.name, value: item.id, prices: item.prices })
                })
                setpriceList(priceLists)
            })
        } catch (error) {
            alert(error.message)
        }
    }
}

export default SalesOrdersForm