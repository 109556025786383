import React, {useState,useEffect} from 'react'
import firebase from '../../../firebase'
import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'

const Item = (props) => {

    const [isOpen,setIsOpen] = useState(false)

    const totalBefore = _.reduce(props.categories, (sum,o,key) => {
        if (key < props.index) {
            const filter = _.filter(props.expenses, item => {
                return item.category == o.name
            })
            const tot = _.reduce(filter, (suma,m) => {
                return suma + m.amount
            },0)
            return sum + tot
        } else {
            return sum
        }
    },0)

    const {data} = props

    const filtered = _.filter(props.expenses, o => {
        return o.category == data.name
    })
    const total = _.reduce(filtered, (sum,n) => {
        return sum + n.amount
    },0)

    return (
        <tbody>
            <tr>
                <td><button onClick={() => setIsOpen(!isOpen)}><i className="material-icons">{isOpen === false ? 'expand_more' : 'expand_less'}</i></button></td>
                <td>{data.name}</td>
                <td></td>
                <td className="center">{numeral(total).format('$0,0.00')}</td>
                <td className="center">{numeral(props.total - total - totalBefore).format('$0,0.00')}</td>
            </tr>
            {
                isOpen === true ? Object.keys(filtered).map(key => <tr>
                    <td>{moment(filtered[key].date).format('DD-MM-YYYY')}</td>
                    <td colSpan="2">{filtered[key].concept}</td>
                    <td className="center">{numeral(filtered[key].amount).format('$0,0.00')}</td>
                </tr>) : <tr></tr>
            }
        </tbody>
    )
}

const AccountingResultsContainer = (props) => {

    const [moves,setmoves] = useState([])
    const [before,setbefore] = useState([])

    useEffect(() => {
        const initialMonth = moment(props.month).startOf('month').valueOf()
        const endMonth = moment(props.month).endOf('month').valueOf()

        if (initialMonth && endMonth) {
            const getIncomes = new Promise((resolve) => {
                firebase.getCollectionRealTime('incomes').where('date','<=',endMonth).where('date','>=',initialMonth).onSnapshot(snap => {
                    var data = []
                    snap.docs.forEach(doc => {
                        var item = doc.data()
                        item.type = 'Ingreso'
                        data.push(item)
                    })
                    resolve(data)
                })
            })
            const getExpenses = (list) => {
                return new Promise ((resolve) => {
                    firebase.getCollectionRealTime('expenses').where('date','<=',endMonth).where('date','>=',initialMonth).onSnapshot(snap => {
                        snap.docs.forEach(doc => {
                            var item = doc.data()
                            item.type = 'Egreso'
                            list.push(item)
                        })
                        resolve(list)
                    })
                })
            }
            getIncomes.then(incomes => {
                getExpenses(incomes).then(list => {
                    setmoves(_.orderBy(list, ['date'],['asc']))
                })
            })
            const beforeIncomes = new Promise((resolve) => {
                firebase.getCollectionRealTime('incomes').where('date','<=',initialMonth-1).onSnapshot(snap => {
                    var data = []
                    snap.docs.forEach(doc => {
                        var item = doc.data()
                        item.type = 'Ingreso'
                        data.push(item)
                    })
                    resolve(data)
                })
            })
            const beforeExpenses = (list) => {
                return new Promise ((resolve) => {
                    firebase.getCollectionRealTime('expenses').where('date','<=',initialMonth-1).onSnapshot(snap => {
                        snap.docs.forEach(doc => {
                            var item = doc.data()
                            item.type = 'Egreso'
                            list.push(item)
                        })
                        resolve(list)
                    })
                })
            }
            beforeIncomes.then(incomes => {
                beforeExpenses(incomes).then(list => {
                    setbefore(list)
                })
            })
        }
    }, [props.month])

    const filterBrandBefore = props.selectedBrand ? _.filter(before, o => {
        return o.brandName === props.selectedBrand
    }) : before
    const filteredBefore = props.filter ? _.filter(filterBrandBefore, o => {
        return props.filter === 'bank' ? o.payway !== 'Efectivo' : o.payway === 'Efectivo'
    }) : filterBrandBefore


    const filterBrand = props.selectedBrand ? _.filter(moves, o => {
        return o.brandName === props.selectedBrand
    }) : moves
    const filtered = props.filter ? _.filter(filterBrand, o => {
        return props.filter === 'bank' ? o.payway !== 'Efectivo' : o.payway === 'Efectivo'
    }) : filterBrand


    const totalBeforeIncomes = _.reduce(filteredBefore, (sum,n) => {
        if (n.type === 'Ingreso') {
            return sum + n.amount
        }
        return sum
    },0)
    const totalBeforeExpenses = _.reduce(filteredBefore, (sum,n) => {
        if (n.type === 'Egreso') {
            return sum + n.amount
        }
        return sum
    },0)
    const totalBefore = totalBeforeIncomes - totalBeforeExpenses

    const totalIncomes = _.reduce(filtered, (sum,n) => {
        if (n.type === 'Ingreso') {
            return sum + n.amount
        }
        return sum
    },0)
    const totalExpenses = _.reduce(filtered, (sum,n) => {
        if (n.type === 'Egreso') {
            return sum + n.amount
        }
        return sum
    },0)
    const total = totalIncomes - totalExpenses

    const expenses = _.filter(moves, { type: 'Egreso' })

    return (
        <table>
            <thead>
                <tr>
                    <th colSpan="1">Saldo Inicial: {numeral(totalBefore).format('$0,0.00')}</th>
                    <th colSpan="3"></th>
                    <th colSpan="1">Saldo Final: {numeral(totalBefore + total).format('$0,0.00')}</th>
                </tr>
                <tr>
                    <th>Ver</th>
                    <th>Concepto</th>
                    <th>Ingresos</th>
                    <th>Egresos</th>
                    <th>Saldo</th>
                </tr>
                <tr>
                    <td></td>
                    <td>Total Ingresos</td>
                    <td className="center">{numeral(totalIncomes).format('$0,0.00')}</td>
                    <td></td>
                    <td className="center">{numeral(totalBefore + totalIncomes).format('$0,0.00')}</td>
                </tr>
            </thead>
            {
                Object.keys(props.categories).map(key => <Item index={key} total={totalBefore + totalIncomes} expenses={expenses} categories={props.categories} key={key} data={props.categories[key]} />)
            }
            <tfoot>
                <tr>
                    <th></th>
                    <th>Totales</th>
                    <th>{numeral(totalIncomes).format('$0,0.00')}</th>
                    <th>{numeral(totalExpenses).format('$0,0.00')}</th>
                    <th>{numeral(totalBefore + total).format('$0,0.00')}</th>
                </tr>
            </tfoot>
        </table>
    )
}

const AccountingResults = () => {

    const [month,setMonth] = useState(undefined)
    const [categories,setCategories] = useState([])
    const [brands,setBrands] = useState([])
    const [selectedBrand,setSelectedBrand] = useState(undefined)
    const [filter,setFilter] = useState(undefined)

    useEffect(() => {
        firebase.getCollectionOnce('brands').then(snap => {
            const brands = snap.docs.map(doc => doc.data())
            setBrands(brands)
        })
        const current = moment().format('MM-YYYY')
        setMonth(current)
        firebase.getCollectionRealTime('categories').orderBy('name').get().then(snap => {
            const categories = snap.docs.map(doc => doc.data())
            setCategories(categories)
        })
    }, [])

    return (
        <div className="accounting-balance">
            <ul className="accounting-balance__filter">
                <li className={selectedBrand === undefined ? 'active': ''} onClick={() => setSelectedBrand(undefined)}>Todas las Marcas</li>
                {
                    Object.keys(brands).map(key => <li className={selectedBrand === brands[key].name ? 'active' : ''} key={key} onClick={() => setSelectedBrand(brands[key].name)}>{brands[key].name}</li>)
                }
            </ul>
            <ul className="accounting-balance__filter">
                <li className={filter === undefined ? 'active': ''} onClick={() => setFilter(undefined)}>Todos las Movimientos</li>
                <li className={filter === 'bank' ? 'active': ''} onClick={() => setFilter('bank')}>Bancos</li>
                <li className={filter === 'cash' ? 'active': ''} onClick={() => setFilter('cash')}>Efectivo</li>
            </ul>
            <table>
                <thead>
                    <tr>
                        <th colSpan="5">
                            <div className="date-picker">
                                <button onClick={prevMonth}>
                                    <i className="material-icons">arrow_left</i>
                                </button>
                                <span>{month}</span>
                                <button onClick={nextMonth}>
                                    <i className="material-icons">arrow_right</i>
                                </button>
                            </div>
                        </th>
                    </tr>
                </thead>
            </table>
            <AccountingResultsContainer filter={filter} selectedBrand={selectedBrand} categories={categories} month={moment(month,'MM-YYYY').valueOf()} />
        </div>
    )

    async function nextMonth() {
        try {
            const newMonth = moment(month, 'MM-YYYY').add(1,'month').format('MM-YYYY')
            setMonth(newMonth)
        } catch (error) {
            alert(error.message)
        }
    }
    async function prevMonth() {
        try {
            const newMonth = moment(month, 'MM-YYYY').subtract(1,'month').format('MM-YYYY')
            setMonth(newMonth)
        } catch (error) {
            alert(error.message)
        }
    }
}

export default AccountingResults