import React from 'react'
import { Link, Route, Switch } from 'react-router-dom';
import PurchasesMateria from './purchases-materia/PurchasesMateria';
import PurchasesProviders from './purchases-providers/PurchasesProviders';
import PurchasesProvidersForm from './purchases-providers-form/PurchasesProvidersForm';
import PurchasesQuote from './purchases-quote/PurchasesQuote';
import PurchasesQuoteForm from './purchases-quote-form/PurchasesQuoteForm';
import PurchasesOrders from './purchases-orders/PurchasesOrders';
import PurchasesOrdersForm from './purchases-orders-form/PurchasesOrdersForm';
import PurchasesSingleQuote from './purchases-single-quote/PurchasesSingleQuote';
import PurchasesSinglePurchase from './purchases-single-purchase/PurchasesSinglePurchase';

const Purchases = (props) => {
    const loc = props.location.pathname.split('/')[2];
    return (
        <div className="purchases">
            <div className="in-nav">
                <ul>
                    <Link className={loc === undefined || loc === 'materia-prima' ? 'active' : 0} to="/compras">Materia Prima</Link>
                    <Link className={loc === 'proveedores' ? 'active' : 0} to="/compras/proveedores">Proveedores</Link>
                    <Link className={loc === 'agregar-proveedor' ? 'active' : 0} to="/compras/agregar-proveedor">Agregar Proveedor</Link>
                    <Link className={loc === 'cotizaciones' ? 'active' : 0} to="/compras/cotizaciones">Cotizaciones</Link>
                    <Link className={loc === 'agregar-cotizacion' ? 'active' : 0} to="/compras/agregar-cotizacion">Agregar Cotización</Link>
                    <Link className={loc === 'ordenes' ? 'active' : 0} to="/compras/ordenes">Órdenes</Link>
                    <Link className={loc === 'agregar-orden' ? 'active' : 0} to="/compras/agregar-orden">Agregar Compra Rápida</Link>
                </ul>
            </div>
            <Switch>
                <Route path="/compras" component={PurchasesMateria} exact={true} />
                <Route path="/compras/proveedores" component={PurchasesProviders} exact={true} />
                <Route path="/compras/agregar-proveedor" component={PurchasesProvidersForm} exact={true} />
                <Route path="/compras/cotizaciones" component={PurchasesQuote} exact={true} />
                <Route path="/compras/cotizaciones/:id" component={PurchasesSingleQuote} exact={true} />
                <Route path="/compras/agregar-cotizacion" component={PurchasesQuoteForm} exact={true} />
                <Route path="/compras/ordenes" component={PurchasesOrders} exact={true} />
                <Route path="/compras/ordenes/:id" component={PurchasesSinglePurchase} exact={true} />
                <Route path="/compras/agregar-orden" component={PurchasesOrdersForm} exact={true} />
            </Switch>
        </div>
    )
}

export default Purchases