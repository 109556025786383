import React, { useState,useEffect } from 'react'
import firebase from '../../../firebase'

const Item = (props) => {
    const {data} = props
    return (
        <tr>
            <td><img style={{ width:'5rem' }} src={data.logo} alt={data.name}/></td>
            <td>{data.name}</td>
            <td>{data.businessName}</td>
            <td><button onDoubleClick={() => firebase.delete('brands',data.id,data)}><i className="material-icons">delete</i></button></td>
        </tr>
    )
}

const ConfigurationBrand = () => {

    const [name,setname] = useState('')
    const [businessName,setbusinessName] = useState('')
    const [logo,setlogo] = useState(undefined)
    const [brands,setbrands] = useState([])

    useEffect(() => {
        firebase.getCollectionRealTime('brands').onSnapshot(snap => {
            const brands = snap.docs.map(doc => doc.data())
            setbrands(brands)
        })
    }, [])

    return (
        <div className="configuration-brands">
            <form onSubmit={e => e.preventDefault() && false}>
                <div className="input-container">
                    <label>Nombre Comercial</label>
                    <input value={name} onChange={e => setname(e.target.value)} placeholder="Nombre Comercial" type="text"/>
                </div>
                <div className="input-container">
                    <label>Razón Social</label>
                    <input value={businessName} onChange={e => setbusinessName(e.target.value)} placeholder="Razón Social" type="text"/>
                </div>
                <div className="input-container">
                    <label>Logo de Empresa</label>
                    <input onChange={e => setlogo(e.target.files[0])} placeholder="Logo de Empresa" type="file"/>
                </div>
                <div className="input-container">
                    <button onClick={add} type="submit">Agregar</button>
                </div>
            </form>
            <div className="container">
                <table>
                    <thead>
                        <tr>
                            <th>Logo</th>
                            <th>Nombre Comercial</th>
                            <th>Razón Social</th>
                            <th>Eliminar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Object.keys(brands).map(key => <Item
                                key={key}
                                data={brands[key]}
                            />)
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )

    async function add() {
        try {
            firebase.addBrand(logo,name,businessName).then(() => {
                setname('')
                setbusinessName('')
                setlogo(undefined)
            })
        } catch (error) {
            alert(error.message)
        }
    }
}

export default ConfigurationBrand