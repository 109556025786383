import React, { useState, useEffect } from 'react'
import firebase from '../../../firebase'
import { Link } from 'react-router-dom'
import ConfigurationProductsForm from './ConfigurationProductsForm';

const Item = (props) => {
    const {data} = props
    return (
        <tr>
            <td>{data.code}</td>
            <td>{data.name}</td>
            <td>{data.brand}</td>
            <td><Link to={`/configuracion/productos/${data.id}`}><i className="material-icons">chevron_right</i></Link></td>
        </tr>
    )
}

const ConfigurationProducts = () => {

    const [products,setProducts] = useState([])

    useEffect(() => {
        firebase.getCollectionRealTime('products').onSnapshot(snap => {
            const products = snap.docs.map(doc => doc.data())
            setProducts(products)
        })
    }, [])

    return (
        <div className="configuration-products">
            <ConfigurationProductsForm/>
            <div className="container">
                <table>
                    <thead>
                        <tr>
                            <th>Código</th>
                            <th>Nombre</th>
                            <th>Marca</th>
                            <th>Ver</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Object.keys(products).map(key => <Item key={key} data={products[key]} />)
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ConfigurationProducts