import React, { useState,useEffect } from 'react'
import firebase from '../../../firebase'
import Select from 'react-select'
import moment from 'moment'
import _ from 'lodash'

const Item = (props) => {
    const {data} = props
    return (
        <tr>
            <td>{data.productCode ? data.productCode : data.materialCode} - {data.productName ? data.productName : data.materialName} {data.name}</td>
            <td><input defaultValue={data.quantIn} onChange={e => props.update(props.index,'quantIn',Number(e.target.value))} placeholder="0" type="number"/></td>
            <td><input defaultValue={data.quantOut} onChange={e => props.update(props.index,'quantOut',Number(e.target.value))} placeholder="0" type="number"/></td>
            <td><input defaultValue={data.umIn} onChange={e => props.update(props.index,'umIn',Number(e.target.value))} placeholder="0" type="number"/></td>
            <td><input defaultValue={data.umOut} onChange={e => props.update(props.index,'umOut',Number(e.target.value))} placeholder="0" type="number"/></td>
        </tr>
    )
}

const ProductionForm = () => {

    const [presentations,setPresentations] = useState([])
    const [selected,setSelected] = useState(undefined)
    const [type,setType] = useState(undefined)
    const [list,setList] = useState([])
    const [date,setDate] = useState(undefined)

    useEffect(() => {
        setDate(moment().valueOf())
        if (type && type !== 'false') {
            firebase.getCollectionOnce(type).then(snap => {
                var data = []
                snap.docs.forEach(doc => {
                    const item = doc.data();
                    const pres = {
                        label: `${item.productCode ? item.productCode : item.materialCode} - ${item.productName ? item.productName : item.materialName} - ${item.name}`,
                        value: item.id,
                        item
                    }
                    data.push(pres)
                })
                setPresentations(data)
            })
        }
    }, [type]);

    return (
        <div className="production-form">
            <form onSubmit={e => e.preventDefault() && false}>
                <div className="input-container">
                    <label>Tipo</label>
                    <select onChange={e => setType(e.target.value)}>
                        <option value="false">** Seleccionar **</option>
                        <option value="material-presentations">Materia Prima</option>
                        <option value="product-presentations">Producto Terminado</option>
                    </select>
                </div>
                <div className="input-container">
                    <label>Fecha</label>
                    <input value={moment(date).format('YYYY-MM-DD')} onChange={e => setDate(moment(e.target.value,'YYYY-MM-DD').valueOf())} type="date" />
                </div>
                <div className="input-container">
                    <label>Presentación(es)</label>
                    <Select
                        options={presentations}
                        value={selected}
                        onChange={setSelected}
                        isMulti={true}
                    />
                </div>
                <div className="input-container">
                    <button onClick={cont} type="submit">Continuar</button>
                </div>
            </form>
            {_.size(list) > 0 ? <table>
                <thead>
                    <tr>
                        <th>Presentación</th>
                        <th>Cantidad Entrada</th>
                        <th>Cantidad Salida</th>
                        <th>Kilogramos / Litros Entrada</th>
                        <th>Kilogramos / Litros Salida</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Object.keys(list).map(key => <Item key={key} index={key} update={update} data={list[key]} />)
                    }
                </tbody>
                <tfoot>
                    <tr>
                        <th colSpan="3"></th>
                        <th colSpan="2"><button onClick={submit} style={{ width:'100%' }}>Agregar Movimientos</button></th>
                    </tr>
                </tfoot>
            </table> : ''}
        </div>
    )

    async function submit() {
        try {
            list.forEach(item => {
                firebase.productionMoves(type,date,item)
            })
            alert('Elementos cargados')
        } catch (error) {
            alert(error.message)
        }
    }

    async function update(key,label,val) {
        try {
            list[key][label] = val
            setList(list)
        } catch (error) {
            alert(error.message)
        }
    }

    async function cont() {
        try {
            const prom = (list) => {
                return new Promise(resolve => {
                    var data = []
                    list.forEach(item => {
                        data.push(item.item)
                    })
                    resolve(data)
                })
            }
            prom(selected).then(data => {
                setList(data)
            })
        } catch (error) {
            alert(error.message)
        }
    }
}

export default ProductionForm