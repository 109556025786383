import React, {useState,useEffect} from 'react'
import firebase from '../../../firebase'
import _ from 'lodash'

const Item = (props) => (
    <tr>
        <td>{props.data.name}</td>
        <td>{props.data.description}</td>
    </tr>
)

const ConfigurationExpenses = () => {

    const [categories,setCategories] = useState([])
    const [name,setname] = useState('')
    const [description,setdescription] = useState('')

    useEffect(() => {
        firebase.getCollectionRealTime('categories').onSnapshot(snap => {
            const categories = snap.docs.map(doc => doc.data())
            setCategories(categories)
        })
    })

    const ordered = _.orderBy(categories, ['name'], ['asc'])

    return (
        <div className="configuration-expenses">
            <form onSubmit={e => e.preventDefault() && false}>
                <div className="input-container">
                    <label>Categoría</label>
                    <input value={name} onChange={e => setname(e.target.value)} placeholder="Categoría" type="text"/>
                </div>
                <div className="input-container">
                    <label>Descripción</label>
                    <input value={description} onChange={e => setdescription(e.target.value)} placeholder="Descripción" type="text"/>
                </div>
                <div className="input-container">
                    <button onClick={add} type="submit">Agregar</button>
                </div>
            </form>
            <table>
                <thead>
                    <tr>
                        <th>Nombre de categoría</th>
                        <th>Descripción</th>
                    </tr>
                </thead>
                <tbody>{
                    Object.keys(ordered).map(key => <Item key={key} data={ordered[key]} />)
                }</tbody>
            </table>
        </div>
    )

    async function add() {
        try {
            const item ={
                name,
                description
            }
            firebase.simpleAdd(item,'categories').then(() => {
                setname('')
                setdescription('')
            })
        } catch (error) {
            alert(error.message)
        }
    }
}

export default ConfigurationExpenses