import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import firebase from '../firebase';

const LogIn = (props) => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    
    return (
        <div className="log-in">
            <form onSubmit={e => e.preventDefault() && false}>
                <h1>Inicio de Sesión</h1>
                <div className="input-container">
                    <label>Email</label>
                    <input value={email} placeholder="Email" autoComplete="off" type="email" onChange={e => setEmail(e.target.value)} />
                </div>
                <div className="input-container">
                    <label>Contraseña</label>
                    <input value={password} placeholder="Contraseña" autoComplete="off" type="password" onChange={e => setPassword(e.target.value)} />
                </div>
                <div className="input-container">
                    <button onClick={log} type="submit">Entrar</button>
                </div>
                <Link to="/registro">Registro</Link>
            </form>
        </div>
    )
    async function log() {
        try {
            await firebase.login(email,password)
            props.history.replace('/')
        } catch (error) {
            alert(error.message)
        }
    }
}

export default LogIn;