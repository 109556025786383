import React, { useState,useEffect } from 'react'
import firebase from '../../../firebase'
import Select from 'react-select'

const ConfigurationReceipesForm  = () => {

    const [products,setProducts] = useState([])
    const [materials,setMaterials] = useState([])
    const [selectedProduct,setSelectedProduct] = useState(undefined)
    const [selectedMaterial,setSelectedMaterial] = useState(undefined)

    useEffect(() => {
        firebase.getCollectionOnce('products').then(snap => {
            var data = []
            snap.docs.forEach(doc => {
                const item = doc.data()
                data.push({ label: item.name, value: item.id })
            })
            setProducts(data)
        })
        firebase.getCollectionOnce('material-presentations').then(snap => {
            var data = []
            snap.docs.forEach(doc => {
                const item = doc.data()
                data.push({ label: `${item.materialName} - ${item.description}`,value: item.id })
            })
            setMaterials(data)
        })
    },[])

    return (
        <div className="configuration-receipes-form">
            <div className="container">
                <div className="input-container">
                    <label>Nombre de Receta</label>
                    <input placeholder="Nombre de Receta" type="text"/>
                </div>
                <div className="input-container">
                    <label>¿Qué producto se elaborará?</label>
                    <Select
                        options={products}
                        onChange={setSelectedProduct}
                        value={selectedProduct}
                    />
                </div>
                <div className="input-container">
                    <label>Kilogramos de producción</label>
                    <input placeholder="Kilogramos de producción" type="number" />
                </div>
                <div className="configuraiton-receipes-form__ingredients">
                    <form onSubmit={e => e.preventDefault() && false}>
                        <div className="input-container">
                            <label>Ingredientes</label>
                            <Select
                                options={materials}
                                onChange={setSelectedMaterial}
                                value={selectedMaterial}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ConfigurationReceipesForm