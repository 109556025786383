import React, {useState,useEffect} from 'react'
import { Link, Route, Switch } from 'react-router-dom';
import moment from 'moment'
import DashboardMain from './DashboardMain';
import DashboardTwo from './DashboardTwo';
import DashboardThree from './DashboardThree';

const Dashboard = (props) => {

    const [month,setMonth] = useState(undefined)

    useEffect(() => {
        const current = moment().format('MM-YYYY')
        setMonth(current)
    }, [])

    const loc = props.location.pathname.split('/')[2];

    return (
        <div className="dashboard">
            <div className="in-nav">
                <ul>
                    <Link className={loc === '' || loc === undefined ? 'active' : ''} to="/">Por cliente por mes</Link>
                    <Link className={loc === 'vendedor-mes' ? 'active' : ''} to="/dashboard/vendedor-mes">Por vendedor por mes</Link>
                    <Link className={loc === 'cliente-vendedor' ? 'active' : ''} to="/dashboard/cliente-vendedor">Por cliente por vendedor</Link>
                </ul>
            </div>
            <div style={{ marginTop: '2.5rem' }} className="date-picker">
                <button onClick={prevMonth}>
                    <i className="material-icons">arrow_left</i>
                </button>
                <span style={{ fontSize: '1.4rem' }}>{month}</span>
                <button onClick={nextMonth}>
                    <i className="material-icons">arrow_right</i>
                </button>
            </div>
            <div className="dashboard__container">
                <Switch>
                    <Route path="/" render={props => <DashboardMain month={month} {...props}/>} exact={true} />
                    <Route path="/dashboard/vendedor-mes" render={props => <DashboardTwo month={month} {...props}/>} exact={true} />
                    <Route path="/dashboard/cliente-vendedor" render={props => <DashboardThree month={month} {...props}/>} exact={true} />
                </Switch>
            </div>
        </div>
    )

    async function nextMonth() {
        try {
            const newMonth = moment(month, 'MM-YYYY').add(1,'month').format('MM-YYYY')
            setMonth(newMonth)
        } catch (error) {
            alert(error.message)
        }
    }
    async function prevMonth() {
        try {
            const newMonth = moment(month, 'MM-YYYY').subtract(1,'month').format('MM-YYYY')
            setMonth(newMonth)
        } catch (error) {
            alert(error.message)
        }
    }
}

export default Dashboard