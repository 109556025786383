import React, {useState,useEffect} from 'react'
import firebase from '../../../firebase'
import Select from 'react-select'
import moment from 'moment'
import _ from 'lodash'

const Item = (props) => {
    const {data} = props

    return (
        <tr>
            <td><button onClick={() => firebase.delete('assistance',data.id,data)}><i className="material-icons">delete</i></button></td>
            <td>{moment(data.date).format('DD-MM-YYYY')}</td>
            <td>{data.employeeName}</td>
            <td>{data.type}</td>
        </tr>
    )
}

const HumanResourcesAsistanceContainer = (props) => {

    const [assistance,setAssistance] = useState([])

    useEffect(() => {
        const initialMonth = moment(props.month).startOf('month').valueOf()
        const endMonth = moment(props.month).endOf('month').valueOf()

        if (initialMonth && endMonth) {
            const getAssistance = new Promise((resolve) => {
                firebase.getCollectionRealTime('assistance').orderBy('date','desc').where('date','<=',endMonth).where('date','>=',initialMonth).onSnapshot(snap => {
                    const data = snap.docs.map(doc => doc.data())
                    resolve(data)
                })
            })
            getAssistance.then(assistance => {
                setAssistance(_.orderBy(assistance, ['date'],['asc']))
            })
        }
    },[props.month])

    return (
        <tbody>{
            Object.keys(assistance).map(key => <Item key={key} data={assistance[key]}/>)
        }</tbody>
    )
}

const HumanResourcesAsistance = () => {

    const [month,setMonth] = useState(undefined)
    const [employees,setEmployees] = useState([])
    const [selected,setSelected] = useState(undefined)
    const [date,setdate] = useState('')
    const [type,settype] = useState('Falta') 

    useEffect(() => {
        firebase.getCollectionOnce('employees').then(snap => {
            var data = []
            snap.docs.forEach(doc => {
                const item = doc.data()
                data.push({ label: item.name, value: item.id })
            })
            setEmployees(data)
        })
        const current = moment().format('MM-YYYY')
        setMonth(current)
    },[])

    return (
        <div className="human-resources-employees">
            <form onSubmit={e => e.preventDefault() && false}>
                <div className="input-container">
                    <label>Empleado</label>
                    <Select
                        options={employees}
                        value={selected}
                        onChange={setSelected}
                    />
                </div>
                <div className="input-container">
                    <label>Fecha</label>
                    <input value={date} onChange={e => setdate(e.target.value)} type="date"/>
                </div>
                <div className="input-container">
                    <label>Tipo</label>
                    <select value={type} onChange={e => settype(e.target.value)}>
                        <option value="Falta">Falta</option>
                        <option value="Retardo">Retardo</option>
                    </select>
                </div>
                <div className="input-container">
                    <button onClick={add} type="submit">Agregar</button>
                </div>
            </form>
            <table>
                <thead>
                    <tr>
                        <th colSpan="4">
                            <div className="date-picker">
                                <button onClick={prevMonth}>
                                    <i className="material-icons">arrow_left</i>
                                </button>
                                <span>{month}</span>
                                <button onClick={nextMonth}>
                                    <i className="material-icons">arrow_right</i>
                                </button>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th>Eliminar</th>
                        <th>Fecha</th>
                        <th>Empleado</th>
                        <th>Tipo</th>
                    </tr>
                </thead>
                <HumanResourcesAsistanceContainer month={moment(month,'MM-YYYY').valueOf()}  />
            </table>
        </div>
    )

    async function nextMonth() {
        try {
            const newMonth = moment(month, 'MM-YYYY').add(1,'month').format('MM-YYYY')
            setMonth(newMonth)
        } catch (error) {
            alert(error.message)
        }
    }
    async function prevMonth() {
        try {
            const newMonth = moment(month, 'MM-YYYY').subtract(1,'month').format('MM-YYYY')
            setMonth(newMonth)
        } catch (error) {
            alert(error.message)
        }
    }

    async function add () {
        try {
            const item = {
                date: moment(date,'YYYY-MM-DD').valueOf(),
                type,
                employeeName: selected.label,
                employeeId: selected.value
            }
            firebase.simpleAdd(item,'assistance').then(() => {
                alert('Asistencia agregada')
            })
        } catch (error) {
            alert(error.message)
        }
    }
}

export default HumanResourcesAsistance