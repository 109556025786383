import React, {useState,useEffect} from 'react'
import firebase from '../../../firebase'
import { Link } from 'react-router-dom'

const Item = (props) => {
    const {data} = props

    return (
        <tr>
            <td>{data.name}</td>
            <td>{data.email}</td>
            <td>{data.phone}</td>
            <td>{data.webPage}</td>
            <td>{data.payTerm === 'credit' ? 'Crédito' : 'Contado' }</td>
            <td><Link to={`/compras/proveedores/${data.id}`}><i className="material-icons">chevron_right</i></Link></td>
        </tr>
    )
}

const PurchasesProviders = () => {

    const [providers,setProviders] = useState([])

    useEffect(() => {
        firebase.getCollectionOnce('providers').then(snap => {
            const providers = snap.docs.map(doc => doc.data())
            setProviders(providers)
        })
    }, [])

    return (
        <div className="purchases-providers">
            <table>
                <thead>
                    <tr>
                        <th>Empresa</th>
                        <th>Email</th>
                        <th>Teléfono</th>
                        <th>Página Web</th>
                        <th>Término de Pago</th>
                        <th>Ver</th>
                    </tr>
                </thead>
                <tbody>{
                    Object.keys(providers).map(key => <Item key={key} data={providers[key]} />)
                }</tbody>
            </table>
        </div>
    )
}

export default PurchasesProviders