import React, {useState,useEffect} from 'react'
import firebase from '../../../firebase'
import Modal from 'react-modal'
import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'

const Item = (props) => {

    const {data} = props

    const concept = data.materials ? _.reduce(data.materials, (str,n) => {
        return `${str}, ${n.materialName} ${n.name}`
    },'') : data.concept

    return (
        <tr>
            <td>{moment(data.date).format('DD-MM-YYYY')}</td>
            <td>{data.paydate ? moment(data.payDate).format('DD-MM-YYYY') : ''}</td>
            <td><select onChange={e => firebase.update('purchases',data.id,'brandName',e.target.value)} value={data.brandName}>
                {
                    Object.keys(props.brands).map(key => <option key={key} value={props.brands[key].name}>{props.brands[key].name}</option>)
                }
            </select></td>
            <td>{concept}</td>
            <td>{data.providerName}</td>
            <td>{numeral(data.total).format('$0,0.00')}</td>
            <td className="center"><button onClick={() => firebase.delete('purchases',data.id,data)}><i className="material-icons">delete</i></button></td>
            <td className="center"><button onClick={() => props.modalOpen(data)}><i className="material-icons">chevron_right</i></button></td>
        </tr>
    )
}

const MyModal = (props) => {

    const [categories,setCategories] = useState([])

    const [move,setmove] = useState('Transferencia')
    const [bill,setbill] = useState('Pendiente')
    const [checkNumber,setcheckNumber] = useState('')
    const [category,setcategory] = useState('false')

    useEffect(() => {
        firebase.getCollectionOnce('categories').then(snap => {
            const categories = snap.docs.map(doc => doc.data())
            setCategories(categories)
        })
    },[])

    const {data} = props

    const concept = data ? data.materials ? _.reduce(data.materials, (str,n) => {
        return `${str}, ${n.materialName} ${n.name}`
    },'') : data.concept : ''

    return (
        <Modal
            isOpen={props.isOpen}
            onRequestClose={props.closeModal}
            contentLabel="Marcar como pagado"
        >
            <div className="accounting-debts-modal">
                <form onSubmit={e => e.preventDefault() && false}>
                <div className="input-container">
                        <label>Movimiento</label>
                        <select onChange={e => setmove(e.target.value)} value={move}>
                            <option value="Transferencia">Transferencia</option>
                            <option value="Efectivo">Efectivo</option>
                            <option value="Cheque">Cheque</option>
                            <option value="Otro">Otro</option>
                        </select>
                    </div>
                    <div className="input-container">
                        <label>Factura</label>
                        <select onChange={e => setbill(e.target.value)} value={bill}>
                            <option value="Pendiente">Pendiente</option>
                            <option value="Recibido">Recibido</option>
                        </select>
                    </div>
                    <div className="input-container">
                        <label>Número de Cheque</label>
                        <input onChange={e => setcheckNumber(e.target.value)} placeholder="Número de Cheque" value={checkNumber} type="text"/>
                    </div>
                    <div className="input-container">
                        <label>Categoría</label>
                        <select onChange={e => setcategory(e.target.value)} value={category}>
                            <option value="false">**  Seleccionar **</option>
                            {Object.keys(categories).map(key => <option key={key} value={categories[key].name}>{categories[key].name}</option>)}
                        </select>
                    </div>
                    <div className="input-container">
                        <button onClick={add} type="submit">Marcar como pagado</button>
                    </div>
                </form>
            </div>
        </Modal>
    )

    async function add () {
        try {
            const item = {
                payway: move,
                bill,
                checkNumber,
                category,
                amount: data.total,
                brandId: data.brandId,
                brandName: data.brandName,
                concept: concept,
                holderName: data.providerName,
                holderId: data.providerId ? data.providerId : '',
                providerName: data.providerName ? data.providerName : '',
                providerId: data.providerId ? data.providerId : '',
                date: moment().valueOf()
            }
            firebase.simpleAdd(item,'expenses').then(() => {
                firebase.update('purchases',data.id,'paymentStatus','complete').then(() => {
                    props.closeModal()
                })
            })
        } catch (error) {
            alert(error.message)
        }
    }
}

const AccountingDebts = () => {

    const [purchases,setpurchases] = useState([])
    const [brands,setBrands] = useState([])
    const [selected,setSelected] = useState(undefined)
    const [isOpen,setIsOpen] = useState(false)

    useEffect(() => {
        firebase.getCollectionRealTime('purchases').where('paymentStatus','==','accepted').onSnapshot(snap => {
            const purchases = snap.docs.map(doc => doc.data())
            setpurchases(purchases)
        })
        firebase.getCollectionOnce('brands').then(snap => {
            const brands = snap.docs.map(doc => doc.data())
            setBrands(brands)
        })
    },[])

    const total = _.reduce(purchases, (sum,n) => {
        return sum + n.total
    },0)

    return (
        <div className="accounting-debts">
            <table>
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Fecha de pago</th>
                        <th>Marca</th>
                        <th>Concepto</th>
                        <th>Proveedor</th>
                        <th>Monto</th>
                        <th>Eliminar</th>
                        <th>Marcar como pagado</th>
                    </tr>
                </thead>
                <tbody>{
                    Object.keys(purchases).map(key => <Item key={key} brands={brands} modalOpen={modalOpen} data={purchases[key]} />)
                }</tbody>
                <tfoot>
                    <tr>
                        <th colSpan="4"></th>
                        <th>Total</th>
                        <th>{numeral(total).format('$0,0.00')}</th>
                    </tr>
                </tfoot>
            </table>
            <MyModal data={selected} isOpen={isOpen} closeModal={closeModal} />
        </div>
    )

    async function modalOpen (item) {
        setSelected(item)
        setIsOpen(true)
    }
    async function closeModal () {
        try {
            setIsOpen(false)
        } catch (error) {
            alert(error.message)
        }
    }
}

export default AccountingDebts