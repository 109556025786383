import React, {useState,useEffect} from 'react'
import firebase from '../../../firebase'
import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'

const Item = (props) => {
    const {data} = props

    return (
        <tr className={data.paymentDate < props.timestamp ? 'red' : ''}>
            <td className="center"><button><i className="material-icons">cancel</i></button></td>
            <td>{moment(data.date).format('DD-MM-YYYY')}</td>
            <td>{moment(data.paymentDate).format('DD-MM-YYYY')}</td>
            <td>{data.invoice}</td>
            <td>{data.brandName}</td>
            <td>{data.customerName}</td>
            <td>{numeral(data.invoiceAmount).format('$0,0.00')}</td>
            <td>{numeral(data.payment).format('$0,0.00')}</td>
            <td>{numeral(data.invoiceAmount-data.payment).format('$0,0.00')}</td>
            <td className="center"><button><i className="material-icons">payment</i></button></td>
        </tr>
    )
}

const AccountingReceivable = () => {

    const [billing,setBilling] = useState([])
    const [timestamp,setTimestamp] = useState('')

    useEffect(() => {
        const timestamp = moment().valueOf()
        setTimestamp(timestamp)
        firebase.getCollectionRealTime('billing').where('status','==','Pendiente').onSnapshot(snap => {
            const billing = snap.docs.map(doc => doc.data())
            setBilling(_.orderBy(billing, ['invoice',['asc']]))
        })
    }, [])

    const total = _.reduce(billing, (sum,n) => {
        return sum + (n.invoiceAmount-n.payment)
    },0)

    return (
        <div className="accounting-receivable">
            <table>
                <thead>
                    <tr>
                        <th>Cancelar</th>
                        <th>Fecha</th>
                        <th>Fecha de Pago</th>
                        <th>Factura</th>
                        <th>Marca</th>
                        <th>Cliente</th>
                        <th>Monto de Factura</th>
                        <th>Abonado</th>
                        <th>Monto</th>
                        <th>Marcar como cobrado</th>
                    </tr>
                </thead>
                <tbody>{
                    Object.keys(billing).map(key => <Item key={key} timestamp={timestamp} data={billing[key]} />)
                }</tbody>
                <tfoot>
                    <tr>
                        <th colSpan="7"></th>
                        <th>Total</th>
                        <th>{numeral(total).format('$0,0.00')}</th>
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}

export default AccountingReceivable