import React, { useState, useEffect } from 'react'
import firebase from '../../../firebase'
import { Link } from 'react-router-dom'
import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'

const Item = (props) => {

    const {data} = props

    return (
        <tr>
            <td>{moment(data.date).format('DD-MM-YYYY')}</td>
            <td>{data.concept}</td>
            <td>{numeral(data.total).format('$0,0.00')}</td>
            <td>{data.paymentStatus}</td>
            <td>{data.processStatus}</td>
            <td><Link to={`/compras/ordenes/${data.id}`}><i className="material-icons">chevron_right</i></Link></td>
        </tr>
    )
}

const PurchasesOrdersContainer = (props) => {
    const [purchases,setPurchases] = useState([])

    useEffect(() => {
        const initialMonth = moment(props.month,'MM-YYYY').startOf('month').valueOf()
        const endMonth = moment(props.month,'MM-YYYY').endOf('month').valueOf()
        if (initialMonth && endMonth) {
            firebase.getCollectionRealTime('purchases').where('date','<=',endMonth).where('date','>=',initialMonth).onSnapshot(snap => {
                const purchases = snap.docs.map(doc => doc.data())
                setPurchases(purchases)
            })
        }
    }, [props.month])

    const ordered = _.orderBy(purchases, ['date'],['desc'])

    return (
        <tbody>{
            Object.keys(ordered).map(key => <Item key={key} data={ordered[key]} />)
        }</tbody>
    )
}

const PurchasesOrders = () => {

    const [month,setMonth] = useState(undefined)

    useEffect(() => {
        const current = moment().format('MM-YYYY')
        setMonth(current)
    }, [])

    return (
        <div className="purchases-orders">
            <table>
                <thead>
                    <tr>
                        <th colSpan="6">
                            <div className="date-picker">
                                <button onClick={prevMonth}>
                                    <i className="material-icons">arrow_left</i>
                                </button>
                                <span>{month}</span>
                                <button onClick={nextMonth}>
                                    <i className="material-icons">arrow_right</i>
                                </button>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th>Fecha</th>
                        <th>Concepto</th>
                        <th>Total</th>
                        <th>Estatus de Pago</th>
                        <th>Estatus de Proceso</th>
                        <th>Ver</th>
                    </tr>
                </thead>
                <PurchasesOrdersContainer month={month} />
            </table>
        </div>
    )

    async function nextMonth() {
        try {
            const newMonth = moment(month, 'MM-YYYY').add(1,'month').format('MM-YYYY')
            setMonth(newMonth)
        } catch (error) {
            alert(error.message)
        }
    }
    async function prevMonth() {
        try {
            const newMonth = moment(month, 'MM-YYYY').subtract(1,'month').format('MM-YYYY')
            setMonth(newMonth)
        } catch (error) {
            alert(error.message)
        }
    }
}

export default PurchasesOrders