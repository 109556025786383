import React, { useEffect,useState } from 'react'
import firebase from '../../../firebase'
import numeral from 'numeral'
import moment from 'moment'

const Item = (props) => {
    const {data} = props

    return (
        <tr>
            <td>{data.materialName} {data.name}</td>
            <td>{numeral(data.price).format('$0,0.00')}</td>
            <td>{data.quantity}</td>
            <td>{data.iva}</td>
            <td>{numeral(data.total).format('$0,0.00')}</td>
        </tr>
    )
}

const PurchasesSingleQuote = (props) => {

    const [quote,setquote] = useState([])

    useEffect(() => {
        firebase.getDocumentRealTime('quotations',props.match.params.id).onSnapshot(snap => {
            setquote(snap.data())
        })
    })

    return (
        <div className="purchases-single-quote">
            <table>
                <tbody>
                    <tr>
                        <th>Marca: </th>
                        <td>{quote.brandName}</td>
                    </tr>
                    <tr>
                        <th>Proveedor: </th>
                        <td>{quote.providerName}</td>
                    </tr>
                    <tr>
                        <th>Fecha: </th>
                        <td>{moment(quote.date).format('DD-MM-YYYY')}</td>
                    </tr>
                    <tr>
                        <th>Comentarios: </th>
                        <td>{quote.comments}</td>
                    </tr>
                    <tr>
                        <th>Total: </th>
                        <td>{numeral(quote.total).format('$0,0.00')}</td>
                    </tr>
                    <tr>
                        <td colSpan="2" className="center">
                            <button onClick={process}>Procesar a orden de compra</button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table>
                <thead>
                    <tr>
                        <th>Producto</th>
                        <th>Precio Unitario</th>
                        <th>Cantidad</th>
                        <th>Iva</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>{quote.materials ? 
                    Object.keys(quote.materials).map(key => <Item key={key} data={quote.materials[key]} />) : <tr></tr>
                }</tbody>
            </table>
        </div>
    )
    
    async function process () {
        try {
            firebase.quotationToPurchase(quote).then(id => {
                props.history.push(`/compras/ordenes/${id}`)
            })
        } catch (error) {
            alert(error.message)
        }
    }
}

export default PurchasesSingleQuote;