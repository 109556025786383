import React, { useState,useEffect } from 'react'
import firebase from '../../../firebase'
import { Link } from 'react-router-dom'

const Item = (props) => {
    const {data} = props
    return (
        <tr>
            <td>{data.name}</td>
            <td>{data.description}</td>
            <td><Link to={`/ventas/clientes/${props.id}/${data.id}`}><i className="material-icons">chevron_right</i></Link></td>
        </tr>
    )
}

const SalesSingleCustomerPriceList = (props) => {

    const [name,setname] = useState('')
    const [description,setdescription] = useState('')
    const [priceLists,setPriceLists] = useState([])

    useEffect(() => {
        firebase.getPriceLists(props.id).onSnapshot(snap => {
            setPriceLists(snap.docs.map(doc => doc.data()))
        })
    }, [props.id])

    return (
        <div className="sales-single-customer-price-list">
            <h3>Lista de Precios</h3>
            <div className="container">
                <form onSubmit={e => e.preventDefault() && false}>
                    <div className="input-container">
                        <label>Nombre de Lista</label>
                        <input onChange={e => setname(e.target.value)} placeholder="Nombre de Lista" value={name} type="text"/>
                    </div>
                    <div className="input-container">
                        <label>Descripción de Lista</label>
                        <input onChange={e => setdescription(e.target.value)} placeholder="Descripción de Lista" value={description} type="text"/>
                    </div>
                    <div className="input-container">
                        <button onClick={add} type="submit">Agregar</button>
                    </div>
                </form>
                <table>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Descripción</th>
                            <th>Ver</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Object.keys(priceLists).map(key => <Item key={key} data={priceLists[key]} id={props.id} />)
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )

    async function add() {
        try {
            const item = {
                name,
                description,
                customerId: props.id
            }
            firebase.simpleAdd(item,'priceLists').then(() => {
                setname('')
                setdescription('')
            })
        } catch (error) {
            alert(error.message)
        }
    }
}

export default SalesSingleCustomerPriceList