import React, { useEffect,useState } from 'react'
import firebase from '../../firebase'
import moment from 'moment'
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts'
import _ from 'lodash'

const DashboardMain = (props) => {

    const [billing,setBilling] = useState([])

    useEffect(() => {
        const initialMonth = moment(props.month,'MM-YYYY').startOf('month').valueOf()
        const endMonth = moment(props.month,'MM-YYYY').endOf('month').valueOf()
        if (initialMonth && endMonth) {
            firebase.getCollectionRealTime('billing').where('date','<=',endMonth).where('date','>=',initialMonth).onSnapshot(snap => {
                const billing = _.filter(snap.docs.map(doc => doc.data()), o => {
                    return o.status !== 'Cancelado'
                })
                const customers = _.uniqBy(billing, 'customerId')
                var data = []
                customers.forEach(cust => {
                    const filt = _.filter(billing, { customerId: cust.customerId })
                    const total = _.reduce(filt, (sum,n) => {
                        return sum + n.invoiceAmount
                    },0)
                    data.push({ name: cust.customerName, id: cust.customerId, total,  })
                })
                setBilling(data)
            })
        }
    },[props.month])

    return (
        <div style={{ marginTop: '2.5rem' }} className="dashboard-main">
            <ResponsiveContainer width="100%" height={300}>
                <BarChart
                    data={billing}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="total" fill="#335AA6" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    )
}

export default DashboardMain