import React, { useEffect,useState } from 'react'
import firebase from '../../../firebase'

const ConfigurationSingleUser = (props) => {
    
    const [user,setUser] = useState({})

    useEffect(() => {
        firebase.getDocumentRealTime('users',props.match.params.id).onSnapshot(snap => {
            const user = snap.data()
            setUser(user)
        })
    },[])

    const perms = user.perms ? user.perms : {}

    const concepts = [
        {title: 'Dashboard', id: 'dashboard'},
        {title: 'Analisis', id: 'analysis'},
        {title: 'Ventas', id: 'sales'},
        {title: 'Compras', id: 'purchases'},
        {title: 'Almacén', id: 'warehouse'},
        {title: 'Inventario', id: 'inventory'},
        {title: 'Contabilidad', id: 'accounting'},
        {title: 'Costo', id: 'cost'},
        {title: 'Producción', id: 'production'},
        {title: 'Marketing', id: 'marketing'},
        {title: 'Logística', id: 'logistic'},
        {title: 'Recursos Humanos', id: 'human-resources'},
        {title: 'Calendario', id: 'calendar'},
        {title: 'Configuración', id: 'configuration'},
    ]

    return (
        <div className="configuration-single-user">
            <ul>
                <div className="input-container">
                    <label>Nombre</label>
                    <input disabled defaultValue={user.name} type="text"/>
                </div>
                <div className="input-container">
                    <label>Email</label>
                    <input disabled defaultValue={user.email} type="email"/>
                </div>
                <div className="input-container">
                    <label>¿Activo?</label>
                    <select onChange={e => firebase.update('users',props.match.params.id,'isActive',e.target.value)} value={user.isActive}>
                        <option value="true">Si</option>
                        <option value="false">No</option>
                    </select>
                </div>
                <div className="input-container">
                    <label>Permisos en Industria</label>
                    <select onChange={e => firebase.update('users',props.match.params.id,'isIndustry',e.target.value)} value={user.isIndustry}>
                        <option value="true">Si</option>
                        <option value="false">No</option>
                    </select>
                </div>
                <div className="input-container">
                    <label>Permisos en Rancho</label>
                    <select onChange={e => firebase.update('users',props.match.params.id,'isRancho',e.target.value)} value={user.isRancho}>
                        <option value="false">No</option>
                        <option value="true">Si</option>
                    </select>
                </div>
                <div className="input-container">
                    <label>Rol</label>
                    <select onChange={e => firebase.update('users',props.match.params.id,'rol',e.target.value)} value={user.rol}>
                        <option value="admin">Administrador</option>
                        <option value="user">Usuario</option>
                        <option value="vendedor">Vendedor</option>
                    </select>
                </div>
            </ul>
            {user.rol === 'user' ? <div className="perms">
                <h1>Permisos</h1>
                <div className="perms__container">
                {
                    Object.keys(concepts).map(key => <div key={key} className="input-container">
                        <label>{concepts[key].title}</label>
                        <select value={perms[concepts[key].id]} onChange={e => updater(concepts[key].id,e.target.value)}>
                            <option value="inactive">Inactivo</option>
                            <option value="active">Activo</option>
                        </select>
                    </div>)
                }
                </div>
            </div> : ''}
        </div>
    )

    async function updater (index,val) {
        var newPerms = perms
        newPerms[index] = val
        firebase.update('users',props.match.params.id,'perms',newPerms)
    }
}

export default ConfigurationSingleUser