import React, { useEffect,useState } from 'react'
import firebase from '../../../firebase'
import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'

const Item = (props) => {
    const {data} = props

    return (
        <tr>
            <td className="center"><button onClick={() => firebase.delete('billing',data.id,data)}><i className="material-icons">delete</i></button></td>
            <td>{moment(data.date).format('DD-MM-YYYY')}</td>
            <td>{data.customerName}</td>
            <td>{data.brandName}</td>
            <td>{data.order}</td>
            <td>{data.invoice}</td>
            <td>{numeral(data.invoiceAmount).format('$0,0.00')}</td>
            <td>{numeral(data.payment).format('$0,0.00')}</td>
            <td>{numeral(data.invoiceAmount-data.payment).format('$0,0.00')}</td>
            <td>{moment(data.paymentDate).format('DD-MM-YYYY')}</td>
            <td><select value={data.status} onChange={e => firebase.update('billing',data.id,'status',e.target.value)}>
                <option value="Pendiente">Pendiente</option>
                <option value="Pagado">Pagado</option>
                <option value="Aclaración">Aclaración</option>
                <option value="Cancelado">Cancelado</option>
            </select></td>
        </tr>
    )
}

const SalesInvoicingContainer = (props) => {
    const [billing,setbilling] = useState([])

    useEffect(() => {
        const initialMonth = moment(props.month,'MM-YYYY').startOf('month').valueOf()
        const endMonth = moment(props.month,'MM-YYYY').endOf('month').valueOf()
        if (initialMonth && endMonth) {
            firebase.getCollectionRealTime('billing').where('date','<=',endMonth).where('date','>=',initialMonth).onSnapshot(snap => {
                const billing = snap.docs.map(doc => doc.data())
                setbilling(billing)
            })
        }
    }, [props.month])

    const ordered = _.orderBy(billing, ['invoice','date'],['asc','desc'])

    const filtered = _.filter(billing, o => {
        return o.status !== 'Cancelado'
    })
    const total = _.reduce(filtered, (sum,n) => {
        return sum + n.invoiceAmount
    },0)

    return (
        <tbody>
        {
            Object.keys(ordered).map(key => <Item key={key} data={ordered[key]} />)
        }
            <tr>
                <th colSpan="5"></th>
                <th>Total</th>
                <th>{numeral(total).format('$0,0.00')}</th>
            </tr>
        </tbody>
    )
}

const SalesInvoicing = () => {

    const [month,setMonth] = useState(undefined)

    useEffect(() => {
        const current = moment().format('MM-YYYY')
        setMonth(current)
    }, [])

    return (
        <div className="sales-invoicing">
            <table>
                <thead>
                    <tr>
                        <th colSpan="11">
                            <div className="date-picker">
                                <button onClick={prevMonth}>
                                    <i className="material-icons">arrow_left</i>
                                </button>
                                <span>{month}</span>
                                <button onClick={nextMonth}>
                                    <i className="material-icons">arrow_right</i>
                                </button>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th>Eliminar</th>
                        <th>Fecha</th>
                        <th>Cliente</th>
                        <th>Marca</th>
                        <th>No. Pedido</th>
                        <th>Factura</th>
                        <th>Total Factura</th>
                        <th>Total de Pago</th>
                        <th>Pendiente de Pago</th>
                        <th>Fecha de Pago</th>
                        <th>Estatus</th>
                    </tr>
                </thead>
                <SalesInvoicingContainer month={month} />
            </table>
        </div>
    )

    async function nextMonth() {
        try {
            const newMonth = moment(month, 'MM-YYYY').add(1,'month').format('MM-YYYY')
            setMonth(newMonth)
        } catch (error) {
            alert(error.message)
        }
    }
    async function prevMonth() {
        try {
            const newMonth = moment(month, 'MM-YYYY').subtract(1,'month').format('MM-YYYY')
            setMonth(newMonth)
        } catch (error) {
            alert(error.message)
        }
    }
}

export default SalesInvoicing