import React, { useState, useEffect} from 'react'
import firebase from '../../firebase'
import moment from 'moment'
import * as XLSX from 'xlsx'
import _ from 'lodash'

const Item = (props) => {
    const {data} = props;
        
    return (
        <tr>
            <td>{moment(data.date).format('DD-MM-YYYY')}</td>
            <td>{data.payway}</td>
            <td>{data.brand}</td>
            <td>{data.type}</td>
            <td>{data.holderId}</td>
            <td>{data.holderName}</td>
            <td>{data.concept}</td>
            <td>{data.type === 'Ingreso' ? data.amount : ''}</td>
            <td>{data.type === 'Egreso' ? data.amount : ''}</td>
        </tr>
    )
}

const Importer = () => {

    const [f,setF] = useState(undefined)
    const [data,setData] = useState([])
    const [providers,setproviders] = useState([])
    const [customers,setCustomers] = useState([])

    useEffect(() => {
        firebase.getCollectionOnce('providers').then(snap => {
            setproviders(snap.docs.map(doc => doc.data()))
        })
        firebase.getCollectionOnce('customers').then(snap => {
            setCustomers(snap.docs.map(doc => doc.data()))
        })
    }, [])

    return (
        <div className="importer">
            <h1>Importador</h1>
            <form onSubmit={e => e.preventDefault() && false}>
                <div className="input-container">
                    <label>Archivo</label>
                    <input onChange={e => setF(e.target.files[0])} type="file"/>
                </div>
                <div className="input-container">
                    <button onClick={readXlsx} type="submit">Procesar Archivo</button>
                </div>
            </form>
            {
                _.size(data) === 0 ? '' : <table>
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Forma de Pago</th>
                            <th>Marca</th>
                            <th>Tipo</th>
                            <th>Cliente / Proveedor ID</th>
                            <th>Cliente / Proveedor</th>
                            <th>Concepto</th>
                            <th>Ingresos</th>
                            <th>Egresos</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Object.keys(data).map(key => <Item key={key} data={data[key]} />)
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="2"></td>
                            <td><button onClick={process}>Procesar</button></td>
                        </tr>
                    </tfoot>
                </table>
            }
        </div>
    )
    async function process() {
        try {
            data.forEach(item => {
                if (item.type === 'Ingreso') {
                    firebase.simpleAdd(item,'incomes')
                } else {
                    firebase.simpleAdd(item,'expenses')
                }
            })
        } catch (error) {
            alert(error.message)
        }
    }
    async function readXlsx() {
        try {
            const reader = new FileReader();
            const firstProm = (evt) => {
                return new Promise(resolve => {
                    reader.onload = (evt) => {
                        const bstr = evt.target.result;
                        const wb = XLSX.read(bstr, { type: 'binary' });
                        const wsname = wb.SheetNames[0];
                        const ws = wb.Sheets[wsname];
            
                        const data = XLSX.utils.sheet_to_json(ws, { header: 1 })
                        var array = []
                        data.forEach(item => {
                            if (item[3] === 'Ingreso') {
                                const customer = _.find(customers, { 'name': item[4] })
                                const space = {
                                    date: Math.round((item[0] - 25568)*86400*1000),
                                    payway: item[1] ? item[1] : '',
                                    brand: item[2],
                                    type: item[3],
                                    holderId: customer ? customer.id : '',
                                    holderName: customer ? customer.name : (item[4] ? item[4] : ''),
                                    concept: item[5],
                                    amount: Number(item[6]),
                                    balance: item[8],
                                }
                                array.push(space)
                            } else {
                                const provider = _.find(providers, { 'name': item[4] })
                                const space = {
                                    date: Math.round((item[0] - 25568)*86400*1000),
                                    payway: item[1] ? item[1] : '',
                                    brand: item[2],
                                    type: item[3],
                                    holderId: provider ? provider.id : '',
                                    holderName: provider ? provider.name : (item[4] ? item[4] : ''),
                                    concept: item[5],
                                    amount: Number(item[7]),
                                    balance: item[8],
                                }
                                array.push(space)
                            }
                        })
                        resolve(array)
                    }
                })
            }
            firstProm(reader.readAsBinaryString(f)).then(list => {
                setData(list)
            })
        } catch (error) {
            alert(error.message)
        }
    }
}

export default Importer