import React, { useState, useEffect} from 'react'

const PurchasesProvidersForm = () => {

    const [term,setterm] = useState('false')

    return (
        <div className="purchases-providers-form">
            <form>
                <div className="input-container">
                    <label>Nombre de empresa</label>
                    <input placeholder="Nombre de empresa" type="text" />
                </div>
                <div className="input-container">
                    <label>Email</label>
                    <input placeholder="Email" type="text" />
                </div>
                <div className="input-container">
                    <label>Teléfono</label>
                    <input placeholder="Teléfono" type="text" />
                </div>
                <div className="input-container">
                    <label>Página Web</label>
                    <input placeholder="Página Web" type="text" />
                </div>
                <div className="input-container">
                    <label>Término de Pago</label>
                    <select>
                        <option value="false">** Selecciona una opción **</option>
                        <option value="Contado">Contado</option>
                        <option value="Crédito">Crédito</option>
                    </select>
                </div>
                {
                    term === 'Crédito' ? 
                    <div className="input-container">
                        <label>Días de crédito</label>
                        <input placeholder="Días de crédito" type="number" />
                    </div> : ''
                }
                <div className="input-container">
                    <label>Detalle de Pago</label>
                    <textarea rows="5" placeholder="Detalle de Pago" />
                </div>
                <div className="input-container">
                    <label>Comentarios</label>
                    <textarea rows="5" placeholder="Comentarios" />
                </div>
                <div className="input-container">
                    <button type="submit">Agregar</button>
                </div>
            </form>
        </div>
    )
}

export default PurchasesProvidersForm