import React, { useState,useEffect } from 'react'
import firebase from '../../../firebase'
import { Link } from 'react-router-dom'
import _ from 'lodash'

const Item = (props) => {
    const {data} = props

    return (
        <tr>
            <td>{data.name}</td>
            <td>{data.productName}</td>
            <td>{data.portions}</td>
            <td><Link to={`/configuracion/recetas/${data.id}`}><i className="material-icons">chevron_right</i></Link></td>
        </tr>
    )
}

const ConfigurationReceipes = () => {

    const [receipes,setReceipes] = useState([])

    useEffect(() => {
        firebase.getCollectionRealTime('receipes').onSnapshot(snap => {
            const receipes = snap.docs.map(doc => doc.data())
            setReceipes(_.orderBy(receipes, ['name'],['asc']))
        })
    },[])

    return (
        <div className="configuration-receipes">
            <table>
                <thead>
                    <tr>
                        <th>Nombre de Receta</th>
                        <th>Producto</th>
                        <th>Porciones</th>
                        <th>Ver más</th>
                    </tr>
                </thead>
                <tbody>{
                    Object.keys(receipes).map(key => <Item key={key} data={receipes[key]} />)
                }</tbody>
            </table>
        </div>
    )
}

export default ConfigurationReceipes