import React, { useState, useEffect } from 'react'
import firebase from '../../../firebase'
import SalesSingleCustomerDirectory from './SalesSingleCustomerDirectory';
import SalesSingleCustomerSeller from './SalesSingleCustomerSeller';
import SalesSingleCustomerPriceList from './SalesSingleCustomerPriceList';

const SalesSingleCustomer = (props) => {

    const id = props.match.params.id

    const [customer,setCustomer] = useState({})
    const [cfdis, setCfdis] = useState([])
    const [satPayways,setSatPayways] = useState([])

    useEffect(() => {
        firebase.getDocumentRealTime('customers', id).onSnapshot(snap => {
            setCustomer(snap.data())
        })
        firebase.getCollectionOnce('cfdis').then(snap => {
            setCfdis(snap.docs.map(doc => doc.data()))
        })
        firebase.getCollectionOnce('satPayway').then(snap => {
            setSatPayways(snap.docs.map(doc => doc.data()))
        })
    }, [id])

    return (
        <div className="sales-single-customer">
            <section>
                <table>
                    <thead>
                        <tr>
                            <th colSpan="2">Datos del Cliente</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>ID</td>
                            <td>{customer.id}</td>
                        </tr>
                        <tr>
                            <td>Nombre de Cliente</td>
                            <td><input onChange={e => firebase.update('customers',id,'name',e.target.value)} defaultValue={customer.name} type="text"/></td>
                        </tr>
                        <tr>
                            <td>Dirección</td>
                            <td>{`${customer.street} ${customer.outNumber} - ${customer.inNumber}, ${customer.colony}, ${customer.town}. ${customer.estate}. CP ${customer.postalCode}`}</td>
                        </tr>
                        <tr>
                            <td>Correo Electrónico</td>
                            <td><input onChange={e => firebase.update('customers',id,'email',e.target.value)} defaultValue={customer.email} type="text"/></td>
                        </tr>
                        <tr>
                            <td>Teléfono</td>
                            <td><input onChange={e => firebase.update('customers',id,'phone',e.target.value)} defaultValue={customer.phone} type="text"/></td>
                        </tr>
                        <tr>
                            <td>Tipo de Persona (SAT)</td>
                            <td>
                                <select onChange={e => firebase.update('customers',id,'type',e.target.value)} value={customer.type}>
                                    <option value="Persona Moral">Persona Moral</option>
                                    <option value="Persona Física">Persona Física</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>RFC</td>
                            <td><input onChange={e => firebase.update('customers',id,'rfc',e.target.value)} defaultValue={customer.rfc} type="text"/></td>
                        </tr>
                        <tr>
                            <td>Uso de CFDI</td>
                            <td>
                                <select onChange={e => firebase.update('customers',id,'cfdiUse',e.target.value)} value={customer.cfdiUse}>
                                {
                                    Object.keys(cfdis).map(key =>
                                        <option key={key} value={`(${cfdis[key].id}) ${cfdis[key].description}`}>{`(${cfdis[key].id}) ${cfdis[key].description}`}</option>    
                                    )
                                }
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>Término de Pago</td>
                            <td>
                                <select onChange={e => firebase.update('customers',id,'payTerm',e.target.value)} value={customer.payTerm}>
                                    <option value="credit">Credito</option>
                                    <option value="cash">Contado</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>Días de Crédito</td>
                            <td><input onChange={e => firebase.update('customers',id,'creditDays',e.target.value)} defaultValue={customer.creditDays} type="number"/></td>
                        </tr>
                        <tr>
                            <td>Forma de Pago (SAT)</td>
                            <td>
                                <select onChange={e => firebase.update('customers',id,'payway',e.target.value)} value={customer.payway}>
                                {
                                    Object.keys(satPayways).map(key =>
                                        <option key={key} value={`(${satPayways[key].id}) ${satPayways[key].description}`}>{`(${satPayways[key].id}) ${satPayways[key].description}`}</option>    
                                    )
                                }
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>Metodo de Pago (SAT)</td>
                            <td>
                                <select onChange={e => firebase.update('customers',id,'satPayMethod',e.target.value)} value={customer.satPayMethod}>
                                    <option value="(PUE) Pago en una sola exhibición">(PUE) Pago en una sola exhibición</option>
                                    <option value="(PPD) Pago en parcialidades o diferido">(PPD) Pago en parcialidades o diferido</option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <SalesSingleCustomerDirectory id={id}/>
            </section>
            <section>
                <SalesSingleCustomerSeller id={id} sellerId={customer.sellerId} sellerJuniorId={customer.sellerJuniorId} />
            </section>
            <section>
                <SalesSingleCustomerPriceList id={id} />
            </section>
        </div>
    )
}

export default SalesSingleCustomer