import React, { useState, useEffect } from 'react'
import firebase from '../../../firebase'

const PurchasesMateriaForm = () => {

    const [code,setcode] = useState('')
    const [name,setname] = useState('')
    const [description,setdescription] = useState('')

    return (
        <div className="configuration-products-form">
            <form onSubmit={e => e.preventDefault() && false}>
                <div className="input-container">
                    <label>Código</label>
                    <input value={code} onChange={e => setcode(e.target.value)} placeholder="Código" type="text"/>
                </div>
                <div className="input-container">
                    <label>Producto</label>
                    <input value={name} onChange={e => setname(e.target.value)} placeholder="Producto" type="text"/>
                </div>
                <div className="input-container">
                    <label>Descripción</label>
                    <input value={description} onChange={e => setdescription(e.target.value)} placeholder="Descripción" type="text"/>
                </div>
                <div className="input-container">
                    <button onClick={add} type="submit">Agregar</button>
                </div>
            </form>
        </div>
    )

    async function add () {
        try {
            const item = {
                name,
                code,
                description
            }
            firebase.simpleAdd(item,'materials').then(() => {
                setcode('')
                setname('')
                setdescription('')
            })
        } catch (error) {
            alert(error.message)
        }
    }
}

export default PurchasesMateriaForm