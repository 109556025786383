import React, { useState, useEffect } from 'react'
import firebase from '../../../firebase'

const ConfigurationProductsForm = () => {

    const [code,setcode] = useState('')
    const [name,setname] = useState('')
    const [brand,setBrand] = useState('false')
    const [brands,setBrands] = useState([])

    useEffect(() => {
        firebase.getCollectionOnce('brands').then(snap => {
            const brands = snap.docs.map(doc => doc.data())
            setBrands(brands)
        })
    })

    return (
        <div className="configuration-products-form">
            <form onSubmit={e => e.preventDefault() && false}>
                <div className="input-container">
                    <label>Código</label>
                    <input value={code} onChange={e => setcode(e.target.value)} placeholder="Código" type="text"/>
                </div>
                <div className="input-container">
                    <label>Producto</label>
                    <input value={name} onChange={e => setname(e.target.value)} placeholder="Producto" type="text"/>
                </div>
                <div className="input-container">
                    <label>Marca</label>
                    <select value={brand} onChange={e => setBrand(e.target.value)}>
                        <option value="false">** Selecciona una marca **</option>
                        {
                            Object.keys(brands).map(key => <option key={key} value={brands[key].name}>{brands[key].name}</option>)
                        }
                    </select>
                </div>
                <div className="input-container">
                    <button onClick={add} type="submit">Agregar</button>
                </div>
            </form>
        </div>
    )

    async function add () {
        try {
            if (brand !== 'false') {
                const item = {
                    name,
                    code,
                    brand
                }
                firebase.simpleAdd(item,'products').then(() => {
                    setcode('')
                    setname('')
                    setBrand('')
                })
            } else {
                alert('Selecciona una marca')
            }
        } catch (error) {
            alert(error.message)
        }
    }
}

export default ConfigurationProductsForm