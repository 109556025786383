import React, { useState,useEffect } from 'react'
import firebase from '../../../firebase'
// import { Link } from 'react-router-dom'
import Select from 'react-select'
import numeral from 'numeral'
import _ from 'lodash'

const Item = (props) => {
    const {data} = props
    return (
        <tr>
            <td className="center"><button onClick={() => props.remove(props.index)}><i className="material-icons">delete</i></button></td>
            <td>{data.productCode ? data.productCode : data.code}</td>
            <td>{data.name}</td>
            <td>{numeral(data.price).format('$0,0.00')}</td>
            <td>{numeral(data.fixedCostValue).format('$0,0.00')}</td>
            <td>{data.comments}</td>
        </tr>
    )
}

const SalesPriceList = (props) => {

    const priceListId = props.match.params.priceListId
    // const customerId = props.match.params.customerId

    const [priceList,setpriceList] = useState({})
    const [presentations,setPresentations] = useState([])
    const [selected,setSelected] = useState(undefined)
    const [price,setprice] = useState('')
    const [costConcept,setcostConcept] = useState('')
    const [costAmount,setcostAmount] = useState('')
    const [comments,setcomments] = useState('')

    useEffect(() => {
        firebase.getDocumentRealTime('priceLists',priceListId).onSnapshot(snap => {
            setpriceList(snap.data())
        })

        firebase.getCollectionOnce('product-presentations').then(snap => {
            var data = []
            snap.docs.forEach(doc => {
                const item = doc.data()
                const pres =  { label: `${item.productCode} - ${item.productName} - ${item.name} - ${item.description}`, value:item.id, code:item.productCode, data: item }
                data.push(pres)
            })
            setPresentations(data)
        })
    }, [])

    const products = priceList.products ? priceList.products : []

    return (
        <div className="sales-price-list">
            <div className="container">
                <h1>{priceList.name}</h1>
                <p>{priceList.description}</p>
            </div>
            <form onSubmit={e => e.preventDefault() && false}>
                <div className="input-container">
                    <label>Producto</label>
                    <Select
                        options={presentations}
                        value={selected}
                        onChange={setSelected}
                    />
                </div>
                <div className="input-container">
                    <label>Precio</label>
                    <input onChange={e => setprice(e.target.value)} placeholder="Precio" value={price} type="number" />
                </div>
                <div className="input-container">
                    <label>Costo Fijo Concepto</label>
                    <input onChange={e => setcostConcept(e.target.value)} placeholder="Costo Fijo Concepto" value={costConcept} type="text" />
                </div>
                <div className="input-container">
                    <label>Costo Fijo Monto</label>
                    <input onChange={e => setcostAmount(e.target.value)} placeholder="Costo Fijo Monto" value={costAmount} type="number" />
                </div>
                <div className="input-container">
                    <label>Comments</label>
                    <input onChange={e => setcomments(e.target.value)} placeholder="Comments" value={comments} type="text" />
                </div>
                <div className="input-container">
                    <button onClick={add} type="submit">Agregar</button>
                </div>
            </form>
            <table style={{ marginTop:'2rem' }}>
                <thead>
                    <tr>
                        <th>Eliminar</th>
                        <th>Código</th>
                        <th>Producto</th>
                        <th>Precio</th>
                        <th>Costos Fijos</th>
                        <th>Comentarios</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        products ? Object.keys(products).map(key => <Item remove={remove} index={key} key={key} data={products[key]} />) : <tr></tr>
                    }
                </tbody>
            </table>
        </div>
    )

    async function remove (key) {
        try {
            var newList = [...products]
            delete newList[key]
            var listed = _.filter(newList, o => {
                return o
            })
            firebase.update('priceLists',priceListId,'products',listed)
        } catch (error) {
            alert(error.message)
        }
    }

    async function add () {
        try {
            var newList = [...products]
            const item = {
                brand: selected.data.brand,
                cost: selected.data.cost,
                description: selected.data.description,
                fixedCostDescription: costConcept,
                fixedCostValue: costAmount,
                fullName: selected.label,
                id: selected.value,
                name: selected.data.name,
                price,
                productCode: selected.code,
                productId: selected.data.productId,
                productName: selected.data.productName,
                comments
            }
            newList.push(item)
            firebase.update('priceLists',priceListId,'products',newList)
        } catch (error) {
            alert(error.message)
        }
    }
}

export default SalesPriceList