import React, { useState, useEffect } from "react";
import firebase from "../../../firebase";

const SalesCustomersForm = (props) => {
  const [cfdis, setCfdis] = useState([]);
  const [satPayways, setSatPayways] = useState([]);
  const [type, settype] = useState("");
  const [name, setname] = useState("");
  const [rfc, setrfc] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [street, setstreet] = useState("");
  const [outNumber, setoutNumber] = useState("");
  const [inNumber, setinNumber] = useState("");
  const [postalCode, setpostalCode] = useState("");
  const [colony, setcolony] = useState("");
  const [town, settown] = useState("");
  const [estate, setestate] = useState("");
  const [payTerm, setpayTerm] = useState("");
  const [creditDays, setcreditDays] = useState(0);
  const [cfdiUse, setcfdiUse] = useState("");
  const [satPayway, setsatPayway] = useState("");
  const [satPayMethod, setsatPayMethod] = useState("");

  useEffect(() => {
    firebase.getCollectionOnce("cfdis").then((snap) => {
      setCfdis(snap.docs.map((doc) => doc.data()));
    });
    firebase.getCollectionOnce("satPayway").then((snap) => {
      setSatPayways(snap.docs.map((doc) => doc.data()));
    });
  }, []);

  return (
    <div className="sales-customers-form">
      <h1>Agregar Cliente</h1>
      <form onSubmit={(e) => e.preventDefault() && false}>
        <div className="input-container">
          <label>Tipo de Cliente</label>
          <select value={type} onChange={(e) => settype(e.target.value)}>
            <option value="false">** Seleccionar **</option>
            <option value="physical-person">Persona Física</option>
            <option value="moral-person">Persona Moral</option>
          </select>
        </div>
        <div className="input-container">
          <label>Nombre de Persona o Razón Social</label>
          <input
            placeholder="Nombre o Razón Social"
            value={name}
            onChange={(e) => setname(e.target.value)}
            type="text"
          />
        </div>
        <div className="input-container">
          <label>RFC</label>
          <input
            placeholder="RFC"
            value={rfc}
            onChange={(e) => setrfc(e.target.value)}
            type="text"
          />
        </div>
        <h4>Contacto</h4>
        <div className="input-container">
          <label>Email</label>
          <input
            placeholder="Email"
            value={email}
            onChange={(e) => setemail(e.target.value)}
            type="email"
          />
        </div>
        <div className="input-container">
          <label>Teléfono</label>
          <input
            placeholder="Teléfono"
            value={phone}
            onChange={(e) => setphone(e.target.value)}
            type="text"
          />
        </div>
        <h4>Dirección</h4>
        <div className="input-container">
          <label>Calle</label>
          <input
            placeholder="Calle"
            value={street}
            onChange={(e) => setstreet(e.target.value)}
            type="text"
          />
        </div>
        <div className="input-container">
          <label>Número exterior</label>
          <input
            placeholder="Número exterior"
            value={outNumber}
            onChange={(e) => setoutNumber(e.target.value)}
            type="text"
          />
        </div>
        <div className="input-container">
          <label>Número interior</label>
          <input
            placeholder="Número interior"
            value={inNumber}
            onChange={(e) => setinNumber(e.target.value)}
            type="text"
          />
        </div>
        <div className="input-container">
          <label>Código Postal</label>
          <input
            placeholder="Código Postal"
            value={postalCode}
            onChange={(e) => setpostalCode(e.target.value)}
            type="text"
          />
        </div>
        <div className="input-container">
          <label>Colonia</label>
          <input
            placeholder="Colonia"
            value={colony}
            onChange={(e) => setcolony(e.target.value)}
            type="text"
          />
        </div>
        <div className="input-container">
          <label>Delegación o Municipio</label>
          <input
            placeholder="Delegación o Municipio"
            value={town}
            onChange={(e) => settown(e.target.value)}
            type="text"
          />
        </div>
        <div className="input-container">
          <label>Estado</label>
          <input
            placeholder="Estado"
            value={estate}
            onChange={(e) => setestate(e.target.value)}
            type="text"
          />
        </div>
        <h4>Datos de Pago</h4>
        <div className="input-container">
          <label>Termino de Pago</label>
          <select value={payTerm} onChange={(e) => setpayTerm(e.target.value)}>
            <option value="false">** Seleccionar **</option>
            <option value="credit">Credito</option>
            <option value="cash">Contado</option>
          </select>
        </div>
        {payTerm === "credit" ? (
          <div className="input-container">
            <label>Días de Crédito</label>
            <input
              placeholder="Días de Crédito"
              value={creditDays}
              onChange={(e) => setcreditDays(e.target.value)}
              type="number"
            />
          </div>
        ) : (
          ""
        )}
        <div className="input-container">
          <label>Uso CFDi</label>
          <select value={cfdiUse} onChange={(e) => setcfdiUse(e.target.value)}>
            {Object.keys(cfdis).map((key) => (
              <option
                key={key}
                value={`(${cfdis[key].id}) ${cfdis[key].description}`}
              >{`(${cfdis[key].id}) ${cfdis[key].description}`}</option>
            ))}
          </select>
        </div>
        <div className="input-container">
          <label>Forma de Pago</label>
          <select
            value={satPayway}
            onChange={(e) => setsatPayway(e.target.value)}
          >
            {Object.keys(satPayways).map((key) => (
              <option
                key={key}
                value={`(${satPayways[key].id}) ${satPayways[key].description}`}
              >{`(${satPayways[key].id}) ${satPayways[key].description}`}</option>
            ))}
          </select>
        </div>
        <div className="input-container">
          <label>Metodo de Pago</label>
          <select
            value={satPayMethod}
            onChange={(e) => setsatPayMethod(e.target.value)}
          >
            <option value="(PUE) Pago en una sola exhibición">
              (PUE) Pago en una sola exhibición
            </option>
            <option value="(PPD) Pago en parcialidades o diferido">
              (PPD) Pago en parcialidades o diferido
            </option>
          </select>
        </div>
        <div className="input-container">
          <button onClick={add} type="submit">
            Agregar
          </button>
        </div>
      </form>
    </div>
  );

  async function add() {
    try {
      const item = {
        type: type ? type : "",
        name: name ? name : "",
        rfc: rfc ? rfc : "",
        email: email ? email : "",
        phone: phone ? phone : "",
        street: street ? street : "",
        outNumber: outNumber ? outNumber : "",
        inNumber: inNumber ? inNumber : "",
        postalCode: postalCode ? postalCode : "",
        colony: colony ? colony : "",
        town: town ? town : "",
        satate: estate,
        payTerm: payTerm ? payTerm : "",
        creditDays: creditDays ? creditDays : "",
        cfdiUse: cfdiUse ? cfdiUse : "",
        payway: satPayway,
        satPayMethod: satPayMethod ? satPayMethod : "",
        status: "Pendiente",
        sellerId: props.userId,
      };
      await firebase.simpleAdd(item, "customers").then(() => {
        settype("");
        setname("");
        setrfc("");
        setemail("");
        setphone("");
        setstreet("");
        setoutNumber("");
        setinNumber("");
        setpostalCode("");
        setcolony("");
        settown("");
        setestate("");
        setpayTerm("");
        setcreditDays(0);
        setcfdiUse("");
        setsatPayway("");
        setsatPayMethod("");
      });
    } catch (error) {
      alert(error.message);
    }
  }
};

export default SalesCustomersForm;
