import React, {useState,useEffect} from 'react'
import firebase from '../../../firebase'
import Select from 'react-select'
import moment from 'moment'

const PurchasesOrdersForm = () => {

    const [brands,setbrands] = useState([])

    const [brand,setbrand] = useState(undefined)
    const [date,setdate] = useState('')
    const [concept,setconcept] = useState('')
    const [total,settotal] = useState('')
    const [providerName,setproviderName] = useState('')
    const [comments,setcomments] = useState('')

    useEffect(() => {
        firebase.getCollectionOnce('brands').then(snap => {
            var data = []
            snap.docs.forEach(doc => {
                const item = doc.data()
                data.push({ label: item.name, value: item.id })
            })
            setbrands(data)
        })
    },[])

    return (
        <div className="purchases-orders-form">
            <form onSubmit={e => e.preventDefault() && false}>
                <div className="input-container">
                    <label>Marca</label>
                    <Select
                        options={brands}
                        value={brand}
                        onChange={setbrand}
                    />
                </div>
                <div className="input-container">
                    <label>Fecha</label>
                    <input onChange={e => setdate(e.target.value)} value={date} type="date" />
                </div>
                <div className="input-container">
                    <label>Concepto</label>
                    <input onChange={e => setconcept(e.target.value)} value={concept} placeholder="Concepto" type="text" />
                </div>
                <div className="input-container">
                    <label>Monto total</label>
                    <input onChange={e => settotal(e.target.value)} value={total} placeholder="Monto total" type="number" />
                </div>
                <div className="input-container">
                    <label>Proveedor</label>
                    <input onChange={e => setproviderName(e.target.value)} value={providerName} placeholder="Proveedor" type="text"/>
                </div>
                <div className="input-container">
                    <label>Comentarios</label>
                    <input onChange={e => setcomments(e.target.value)} value={comments} placeholder="Comentarios" type="text" />
                </div>
                <div className="input-container">
                    <button onClick={add} type="submit">Agregar</button>
                </div>
            </form>
        </div>
    )

    async function add () {
        try {
            const item = {
                brandName: brand.label,
                brandId: brand.value,
                date: moment(date, 'YYYY-MM-DD').valueOf(),
                concept,
                total: Number(total),
                providerName,
                comments,
                type:'fast-purchase',
                v2: true,
                paymentStatus: 'pending',
                processStatus: 'pending'
            }
            firebase.simpleAdd(item,'purchases').then(() => {
                alert('Compra agregada')
                setdate('')
                setconcept('')
                settotal('')
                setproviderName('')
                setcomments('')
            })
        } catch (error) {
            alert(error.message)
        }
    }
}

export default PurchasesOrdersForm