import React, {useState,useEffect} from 'react'
import firebase from '../../../firebase'
import { Link } from 'react-router-dom'
import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'

const Item = (props) => {
    const {data} = props

    const concept = data.materials ? _.reduce(data.materials, (str,n) => {
        return `${str}, ${n.materialName} ${n.name}`
    },'') : data.concept
    return (
        <tr>
            <td>{moment(data.date).format('DD-MM-YYYY')}</td>
            <td>{data.providerName}</td>
            <td>{concept}</td>
            <td>{numeral(data.total).format('$0,0.00')}</td>
            <td><button onClick={() => firebase.update('purchases',data.id,'paymentStatus','accepted')}><i className="material-icons">check</i></button></td>
            <td><button onClick={() => firebase.delete('purchases',data.id,data)}><i className="material-icons">delete</i></button></td>
            <td><Link to={`/compras/ordenes/${data.id}`}><i className="material-icons">chevron_right</i></Link></td>
        </tr>
    )
}

const AccountingPurchases = () => {

    const [purchases,setpurchases] = useState([])

    useEffect(() => {
        firebase.getCollectionRealTime('purchases').where('paymentStatus','==','pending').onSnapshot(snap => {
            const purchases = snap.docs.map(doc => doc.data())
            setpurchases(purchases)
        }) 
    }, [])

    const total = _.reduce(purchases, (sum,n) => {
        return sum + n.total
    },0)

    return (
        <div className="accounting-purchases">
            <table>
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Proveedor</th>
                        <th>Concepto</th>
                        <th>Total</th>
                        <th>Aceptar</th>
                        <th>Eliminar</th>
                        <th>Ver</th>
                    </tr>
                </thead>
                <tbody>{
                    Object.keys(purchases).map(key => <Item key={key} data={purchases[key]} />)
                }</tbody>
                <tfoot>
                    <tr>
                        <th colSpan="2"></th>
                        <th>Total</th>
                        <th className="center">{numeral(total).format('$0,0.00')}</th>
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}

export default AccountingPurchases